import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom';
const ViewLedgers = () => {
  // const [formData, setData] = useState([]);
  
  // // useEffect(() => {
  // //   const fetchData = async () => {
  // //     axios
  // //       .get("https://jsonplaceholder.typicode.com/users")
  // //       .then((res) => setData(res.data))
  // //       .catch((err) => console.log(err));
  // //   };
  // //   fetchData();
  // // }, []);

  // const handleDelete = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await axios.post(
  //       API_BASE_URL + "DIAGNOSIS/deletePrescriptionHandler.php",
  //       formData
  //     );
  //     console.log("Document has been Deleted successfully", response.data);
  //     toast.success(
  //       "Document has been Deleted successfully",
  //       response.data["success"],
  //       { position: "top-center" }
  //     );
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error(
  //       error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
  //       { position: "top-center" }
  //     );
  //   }
  // };


  return (
    <></>
    // <div className="viewFormContainer row">
    //   <div className="formHeader d-flex align-items-center justify-content-between">
    //     <div className="headings">
    //       <div className="formHeading"> </div>
    //     </div>
    //     <Link to={"/Homepage/SearchLedgers"}>
    //       <button className="btnClose d-flex justify-content-center align-items-center mb-3">
    //         <FontAwesomeIcon
    //           icon={faClose}
    //           style={{ color: "#ffffff", height: "20px" }}
    //           className="mx-auto my-auto"
    //         />
    //       </button>
    //     </Link>
    //   </div>
    //   <hr />
    //   <form className="inputContent col-md-12">
    //     <div className="row ">
    //       {/* String Fields */}
    //       <div className="col-md-6  formGroup">
    //         <label htmlFor="tb_ledgerCode" className="">
    //           Ledger Code
    //         </label>
    //         <input
    //           type="text"
    //           id="tb_ledgerCode"
    //           name="tb_ledgerCode"
    //           className="inputBox form-control text-dark"
    //           readOnly
    //         />
    //       </div>
    //       <div className="col-md-6  formGroup">
    //         <label htmlFor="tb_ledgersName" className="">
    //           Ledger Name
    //         </label>
    //         <input
    //           type="text"
    //           id="tb_ledgersName"
    //           name="tb_ledgersName"
    //           className="inputBox form-control  text-dark"
    //           readOnly
    //         />
    //       </div>
    //       <div className="col-md-12 formGroup">
    //         <label htmlFor="tb_ledgerDescription" className="">
    //           Ledger Description
    //         </label>
    //         <textarea
    //           type="text"
    //           id="tb_ledgerDescription"
    //           name="tb_ledgerDescription"
    //           className="inputBox form-control text-dark tb_address"
    //           rows={5}
    //           cols="100"
    //           readOnly
    //         />
    //       </div>
    //       <div className="col-md-6  formGroup">
    //         <label htmlFor="tb_creatdBy" className="">
    //           Created By
    //         </label>
    //         <input
    //           type="text"
    //           id="tb_creatdBy"
    //           name="tb_creatdBy"
    //           className="inputBox form-control  text-dark"
    //           readOnly
    //         />
    //       </div>
    //       <div className="col-md-6  formGroup">
    //         <label htmlFor="tb_updatedBy" className="">
    //          Updated By
    //         </label>
    //         <input
    //           type="text"
    //           id="tb_updatedBy"
    //           name="tb_updatedBy"
    //           className="inputBox form-control  text-dark"
    //           readOnly
    //         />
    //       </div>
    //     </div>
    //   </form>
    // </div>
  );
};

export default ViewLedgers;
