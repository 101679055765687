import React, { useEffect, useState } from "react";
import "./Company.css";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const Company = () => {
  const [formData, setFormData] = useState({
    tb_companyName: "",
    tb_companyShortName: "",
    tb_companySmallName: "",
    tb_tin: "",
    tb_vrn: "",
    tb_companyURL: "",
    tb_companyBranchName: "",
    tb_companyEmailAddress: "",
    tb_companyNumber: "",
    tb_physicalAddress: "",
    tb_companyStatus:"",
    tb_createdBy:"",
  });
  const [dob, setDob] = useState("");
  const [error, setError] = useState("");

  const today = new Date().toISOString().split("T")[0];

  const [companyData, setCompanyData] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {

    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
         const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getUserList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      })  // Replace with your API endpoint
      const options = response.data.OPTO[0].OPTO_MESSAGE.user_list.map((option) => ({
          value: option.user_id, // Adjust based on your API response structure
          label: option.user_full_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
    // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
    setFormData({
    ...formData,
    [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
    });
    } else {
    // Handle regular inputs (like text, number, etc.)
    setFormData({
    ...formData,
    [e.target.name]: e.target.value, // Update with the regular input value
    });
    }
    };


useEffect(()=> {
  console.log(formData)
},[formData])


  // Handle form submission
 const handleSubmit = async (e) => {
  e.preventDefault();


  try {

    console.log(formData.tb_clientName);
    axios({
      method: 'post',
      url: API_BASE_URL + 'GODMODE/companySearchHandler.php',
      // data: params,
      data: {
        'tb_companyName': formData.tb_companyName,
        'tb_companyShortName': formData.tb_companyShortName,
        'tb_companySmallName': formData.tb_companySmallName,
        'tb_tin': formData.tb_tin,
        'tb_vrn': formData.tb_vrn,
        'tb_companyURL': formData.tb_companyURL,
        'tb_address': formData.tb_address,
        'tb_companyEmailAddress': formData.tb_companyEmailAddress,
        'tb_companyNumber': formData.tb_companyNumber,
        'tb_companyStatus': formData.tb_companyStatus,
        'tb_createdBy': formData.tb_createdBy,
      },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true,
    }).then((response) => {
      if (response.status === 200 && response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS") {
          // Extract the OPTO_MESSAGE array and set it to the state
          setCompanyData(response.data.OPTO[0].OPTO_MESSAGE);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
      }
  })
  .catch((error) => {
    if (error.response && error.response.data && error.response.data.OPTO) {
      // Extract the "OPTO" array
      const opto = error.response.data.OPTO;
      toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
    }
  });
} catch (error) {
console.error("Error:", error);
}
};
const handleSingleSelect = (singleselectedOption, actionMeta) => {
  console.log(singleselectedOption?.value);
  const { name } = actionMeta;
  if(singleselectedOption == null){
    setFormData({
      ...formData,
      [name]: "",
    });
  }
  else{
    setFormData({
      ...formData,
      [name]: singleselectedOption?.value,
    });
  }
};

const handleReset = () => {
  setFormData({
    tb_companySmallName: "",
    tb_tin: "",
    tb_vrn: "",
    tb_companyEmailAddress: "",
    tb_companyNumber: "",
  });
  setCompanyData([]);
};


  const options_xxxx = [
    {
      value: "Active/Inactive",
      label: "Active/Inactive",
    },
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ];

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
      width: "30%",
    },
    {
      name: "Status",
      selector: (row) => row.active_status.toUpperCase() === "A" ? "Active" : "Inactive",
      width: "15%",
    },    
    {
      name: "Email",
      selector: (row) => row.contact_email_address,
      width: "25%",
    },
    {
      name: "Phone Number",
      selector: (row) => row.contact_number,
      width: "20%",
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
          value={row.company_id}
        >
          <Link to={`/GodPage/View/${row.company_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "70px",
    },
  ];

  const [singleselectedOption, setsingleSelectedOption] = useState("");


const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

 

  return (
    <div>
      <ToastContainer/>
      {/* Heading and Sub Heading */}
      <div className="formHeading">COMPANY MANAGEMENT</div>
      <div className="formSubHeading">Manage companies here</div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_companyName" className="">
                Company Name{" "}
              </label>
              <input onChange={handleChange}
                type="text"
                id="tb_companyName"
                name="tb_companyName"
                className="inputBox form-control formControl  text-dark"
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="string2" className="">
                Company Short Name
              </label>
              <input onChange={handleChange}
                type="text"
                id="tb_companyShortName"
                name="tb_companyShortName"
                className="inputBox form-control formControl  text-dark"
              />
            </div>
            {/* Pincode Fields */}
            <div className="col-md-3  formGroup">
              <label htmlFor="tb_tin" className="">
                TIN
              </label>
              <input onChange={handleChange}
                type="text"
                id="tb_tin"
                name="tb_tin"
                className="inputBox form-control formControl  text-dark"
              />
            </div>
            <div className="col-md-3  formGroup">
              <label htmlFor="vrn" className="">
                VRN
              </label>
              <input onChange={handleChange}
                type="text"
                id="tb_vrn"
                name="tb_vrn"
                className="inputBox form-control formControl  text-dark"
              />
            </div>
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_companyURL" className="">
                Company URL
              </label>
              <input onChange={handleChange}
                type="text"
                id="tb_companyURL"
                name="tb_companyURL"
                className="inputBox form-control formControl  text-dark"
              />
            </div>
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_address" className="">
                Provide Portion of Physical Address
              </label>
              <input onChange={handleChange}
                type="text"
                id="tb_address"
                name="tb_address"
                className="inputBox form-control formControl  text-dark tb_address"
              />
            </div>
            <div className="formGroup col-6  formGroup">
              <label htmlFor="tb_companyStatus">Status</label>
              <Select
                options={options_xxxx}
                placeholder="Single select example"
                id="tb_companyStatus"
                name="tb_companyStatus"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    minHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-6  formGroup">
              <label htmlFor="tb_createdBy">Created by</label>
              <Select
                options={createdByOptions} // Use the options fetched from the API
                // options={options_xxxx}
                placeholder="Select Creator"
                id="tb_createdBy"
                name="tb_createdBy"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    minHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                value={singleselectedOption?.value}
                onChange={handleSingleSelect}
              />
            </div>
             <LocalizationProvider dateAdapter={AdapterDayjs}>
            <label>Company created between</label>
            <div className="col-5 formGroup">
              <DatePicker
                value={dayjs(formData.tb_dob)}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_dob" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_dob" {...params} />}
              />
            </div>
        And
        <div className="col-5 formGroup">
              <DatePicker
                value={dayjs(formData.tb_dob)}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_dob" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_dob" {...params} />}
              />
            </div>
            </LocalizationProvider>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA btnFilter">
                Filter
              </button>
            </div>
            <div className="col-4">
              <Link to={"/GodPage/AddCompany"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add Company
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData"
        columns={columns}
        data={companyData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </div>
  );
};

export default Company;
