import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddStaff = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_staffName: "",
    tb_staffType: "",
    tb_contactNumber: "",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_joiningDate: "",
    tb_dob: "",
    tb_departmentName: "",
    tb_designationName: "",
    tb_tin: "",
    tb_vrn: "",
  });

  const [error, setError] = useState("");

  const today = new Date().toISOString().split("T")[0];

  // Handle input change
  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
    // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
    setFormData({
    ...formData,
    [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
    });
    } else {
    // Handle regular inputs (like text, number, etc.)
    setFormData({
    ...formData,
    [e.target.name]: e.target.value, // Update with the regular input value
    });
    }
    };

  // Datetimepicker

  // Single select
  const [singleselectedOption, setsingleSelectedOption] = useState("");

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if(singleselectedOption == null){
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  const [departmentOptions, setdepartmentOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchDepartmentOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
           url: API_BASE_URL + "/COMMON/getDepartmentList.php",
           headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
           withCredentials: true,
      }); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.department_id, // Adjust based on your API response structure
          label: option.department_name, // Adjust based on your API response structure
        }));
        setdepartmentOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDepartmentOptions();
  }, []);

  const [designationOptions, setdesignationOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchDesignationOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
           url: API_BASE_URL + "/COMMON/getDesignationList.php",
           headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
           withCredentials: true,
      });// Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.designation_id, // Adjust based on your API response structure
          label: option.designation_name, // Adjust based on your API response structure
        }));
        setdesignationOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDesignationOptions();
  }, []);

  const options_xxxx = [
    {
      value: "A",
      label: "Admin",
    },
    {
      value: "N",
      label: "Staff",
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/STAFF/addStaffHandler.php",
        // data: params,
        data: {
          tb_staffName: formData.tb_staffName,
          tb_staffType: formData.tb_staffType,
          tb_clientName: formData.tb_clientName,
          tb_contactNumber: formData.tb_contactNumber,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_gender: formData.tb_gender,
          tb_emailAddress: formData.tb_emailAddress,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_poBox: formData.tb_poBox,
          tb_dob: formData.tb_dob,
          tb_departmentName: formData.tb_departmentName,
          tb_designationName: formData.tb_designationName,
          tb_staffId : formData.staff_id,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
          navigate(response.data.OPTO[0].OPTO_LINK)
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };

  useEffect(()=>{
    console.log(formData)
  },[formData])
  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}

      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-12  formGroup">
            <label htmlFor="tb_staffName" className="">
              Staff Name
            </label>
            <input
              type="text"
              id="tb_staffName"
              name="tb_staffName"
              className="inputBox form-control formControl text-dark"
              placeholder="Enter staff name"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="singleSelect">Staff Type</label>
            <Select
              options={options_xxxx}
              placeholder="Select staff type"
              defaultValue={{ value: "N", label: "Staff" }}
              id="singleSelect"
              name="tb_staffType"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              // value={singleselectedOption?.value}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_contactNumber" className="">
              Contact Number
            </label>
            <input
              type="text"
              id="tb_contactNumber"
              name="tb_contactNumber"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter contact number"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="col-12  formGroup">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter email address"
              onChange={handleChange}
            />
          </div>
          {/* Datetimepicker */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="col-6 formGroup">
              <label htmlFor="tb_dob" className="">
                Date of Birth
              </label>
              <DatePicker
                value={dayjs(formData.tb_dob)}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_dob" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_dob" {...params} />}
              />
            </div>
            </LocalizationProvider>
          {/* Datetimepicker */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="col-6 formGroup">
              <label htmlFor="tb_joiningDate" className="">
                Joining Date
              </label>
              <DatePicker
                value={dayjs(formData.tb_joiningDate)}
                className=""
                id="tb_joiningDate"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_joiningDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_joiningDate" {...params} />}
              />
            </div>
            </LocalizationProvider>
          <div className="formGroup col-6">
            <label htmlFor="tb_departmentName">Department</label>
            <Select
              options={departmentOptions}
              placeholder="Enter department"
              id="tb_departmentName"
              name="tb_departmentName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="tb_designationName">Designation</label>
            <Select
              options={designationOptions}
              placeholder="Designation"
              id="tb_designationName"
              name="tb_designationName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              onChange={handleSingleSelect}
            />
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_tin" className="">
              TIN
            </label>
            <input
              type="text"
              id="tb_tin"
              name="tb_tin"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_vrn" className="">
              VRN
            </label>
            <input
              type="text"
              id="tb_vrn"
              name="tb_vrn"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <Link to={"/home-page/searchstaff"} className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddStaff;
