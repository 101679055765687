import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddDiagnosis = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_clientId: "",
    tb_doctorId: "",
    tb_diagnosisDate: "",
    tb_gender: "M",
    ta_healthIssue: "",
    tb_vrre: "",
    tb_vrle: "",
    tb_srre: "",
    tb_srle: "",
    tb_orre: "",
    tb_orle: "",
    ta_doctorDiagnosis: "",
    tb_cc: "",
    tb_age:"",
    tb_patientId:"",


  });
  const [gender, setGender] = useState('M'); // Default value is "M"

  const today = new Date().toISOString().split("T")[0];
  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options
  const [doctorOptions, setDoctorOptions] = useState([]); // State to store "Created by" dropdown options
  const [patientOptions, setPatientOptions] = useState([]);

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getClientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchPatientOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getPatientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.patient_id, // Adjust based on your API response structure
          label: option.patient_name, // Adjust based on your API response structure
        }));
        setPatientOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchPatientOptions();
  }, []);

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchDoctorOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
           url: API_BASE_URL + "/COMMON/getDoctorList.php",
           headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
           withCredentials: true,
      }); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.doctorId, // Adjust based on your API response structure
          label: option.doctorName, // Adjust based on your API response structure
        }));
        setDoctorOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDoctorOptions();
  }, []);

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if(singleselectedOption == null){
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  // Handle input change
  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
    // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
    setFormData({
    ...formData,
    [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
    });
    } else {
    // Handle regular inputs (like text, number, etc.)
    setFormData({
    ...formData,
    [e.target.name]: e.target.value, // Update with the regular input value
    });
    }
    setGender(e.target.value);
    };

  
  useEffect(()=>{
    console.log(formData)
  }, [formData]);

   // Handle form submission
   const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      console.log(formData.tb_patientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'DIAGNOSIS/addDiagnosisHandler.php',
        // data: params,
        data: {
          'tb_patientId': formData.tb_patientId,
          'tb_clientId': formData.tb_clientId,
          'tb_doctorId': formData.tb_doctorId,
          'tb_diagnosisDate': formData.tb_diagnosisDate,
          'tb_gender': formData.tb_gender,
          'ta_healthIssue': formData.ta_healthIssue,
          'tb_vrre': formData.tb_vrre,
          'tb_vrle': formData.tb_vrle,
          'tb_srre': formData.tb_srre,
          'tb_srle': formData.tb_srle,
          'tb_orre': formData.tb_orre,
          'tb_orle': formData.tb_orle,
          'ta_doctorDiagnosis': formData.ta_doctorDiagnosis,
          'tb_cc': formData.tb_cc,
          'tb_age': formData.tb_age,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
           toast.success("Diagnosis added successfully!", { position: "top-center" });
          navigate(response.data.OPTO[0].OPTO_LINK)
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };

  return (
    <>
    <ToastContainer/>
      {/* Heading and Sub Heading */}
      
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          <div className="formGroup col-12">
          <label htmlFor="singleSelect">Cilent Name</label>
          <Select
            options={createdByOptions}
            key={'client_id'}
            placeholder="Select Client"
            name="tb_clientId"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #d2d2d2",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            onChange={handleSingleSelect}
          />
        </div>
        <div className="formGroup col-6">
          <label htmlFor="singleSelect">Patient Name</label>
          <Select
            options={patientOptions}
            key={'patient_Id'}
            placeholder="Select Patient"
            name="tb_patientId"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #d2d2d2",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            onChange={handleSingleSelect}
          />
        </div>
        <div className="formGroup col-6">
          <label htmlFor="singleSelect">Doctor</label>
          <Select
            options={doctorOptions}
            key={'doctor_id'}
            placeholder="Select Doctor"
            name="tb_doctorId"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #d2d2d2",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            onChange={handleSingleSelect}
          />
        </div>
         {/* Datepicker */}
         <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div className="col-4 formGroup">
              <label htmlFor="tb_diagnosisDate" className="">
                Diagnosis Date
              </label>
              <DatePicker
                value={dayjs(formData.tb_diagnosisDate)}
                className=""
                id="tb_diagnosisDate"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_diagnosisDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_diagnosisDate" {...params} />}
              />
            </div>
            </LocalizationProvider>
            <div className="formGroup col-md-3">
                  <label htmlFor="tb_age" className="">
                    Age
                  </label>
                  <input
                    type="text"
                    id="tb_age"
                    name="tb_age"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-3">
            <label className="form-check-label" htmlFor="gender">
              Gender
            </label>
            <div className="radioGroup d-flex justify-content-start">
              <div className="form-check me-3">
              <input
                className="form-check-input"
                    type="radio"
                    name="tb_gender"
                    id="genderMale"
                    value="M"
                    checked={gender === 'M'}
                    onChange={handleChange}
                />

                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check me-3">
              <input
                className="form-check-input"
                    type="radio"
                    name="tb_gender"
                    id="genderFemale"
                    value="F"
                    checked={gender === 'F'}
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="ta_healthIssue" className="">
              Health Details
            </label>
            <textarea
              type="text"
              id="ta_healthIssue"
              name="ta_healthIssue"
              className="inputBox form-control textarea  text-dark tb_address"
              
              cols="100"
              onChange={handleChange}
            />
          </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_vrre" className="">
                    VRRE
                  </label>
                  <input
                    type="text"
                    id="tb_vrre"
                    name="tb_vrre"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_orre" className="">
                  ORRE
                  </label>
                  <input
                    type="text"
                    id="tb_orre"
                    name="tb_orre"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_srre" className="">
                    SRRE
                  </label>
                  <input
                    type="text"
                    id="tb_srre"
                    name="tb_srre"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_vrle" className="">
                    VRLE
                  </label>
                  <input
                    type="text"
                    id="tb_vrle"
                    name="tb_vrle"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_orle" className="">
                    ORLE
                  </label>
                  <input
                    type="text"
                    id="tb_orle"
                    name="tb_orle"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-4">
                  <label htmlFor="tb_srle" className="">
                    SRLE
                  </label>
                  <input
                    type="text"
                    id="tb_srle"
                    name="tb_srle"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_doctorDaignosis" className="">
              Doctor Diagnosis
            </label>
            <textarea
              type="text"
              id="ta_doctorDiagnosis"
              name="ta_doctorDiagnosis"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_cc" className="">
                Notes
            </label>
            <textarea
              type="text"
              id="tb_cc"
              name="tb_cc"
              className="inputBox form-control textarea  text-dark tb_address"
              rows={5}
              cols="100"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <Link to={"/home-page/searchdiagnosis"} className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddDiagnosis;
