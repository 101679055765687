import React, { useState, useRef } from "react";
import DataTable from "react-data-table-component";
import Mainpopup from "../../../HomePage/Masters/Client/EditClientMainDetails";
import Docspopup from "../../../HomePage/Masters/Client/EditClientKnowYourClientsDetails";
import AddDiagnosisPopup from "../../../HomePage/Diagnosis/AddDiagnosisPopup";
import AddPatientPopup from "../../../HomePage/Masters/Patients/AddPatientPopup";
import { useEffect } from "react";
import axios from "axios";
import { API_BASE_URL, formatDate } from "../../../../optoCustom";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const ViewClient = () => {
  const { clientId } = useParams();

  const [mainIsOpen, setmainIsOpen] = useState(false);

  const [DocsIsOpen, setDocsIsOpen] = useState(false);
  const [AddDiagnosisIsOpen, setAddDiagnosisIsOpen] = useState(false);
  const [AddPatientIsOpen, setAddPatientIsOpen] = useState(false);

  const [mainDetails, setMainDetails] = useState([]);
  const [editClientMainDetails, setClientMainData] = useState(null);

  const [kycDetails, setKycDetails] = useState([]);
  const [diagnosisDetails, setDiagnosisDetails] = useState([]);
  const [patientDetails , setPatientDetails] = useState([]);
  const [historyDetails, setHistoryDetails] = useState([]);

  const setmainIsClose = () => {
    setmainIsOpen(false);
    window.location.reload(); // Refresh the page
  };

  const hasFetchedData = useRef(false);
  useEffect(() => {
    // Prevent the API call from running more than once
    if (hasFetchedData.current) return;
    // Mark that the API has been called
    hasFetchedData.current = true;

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "post",
          url: API_BASE_URL + "MASTER/CLIENT/viewClientDetailsHandler.php",
          data: {
            tb_clientId: clientId,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        });

        if (response.status === 200 && response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS") {
          const responsedData = response.data.OPTO[0].OPTO_MESSAGE;
          setMainDetails(responsedData.main_details[0] || {});
          setKycDetails(responsedData.kyc_details || []);
          setPatientDetails(responsedData.patient_details || []);
          setDiagnosisDetails(responsedData.diagnosis_details || []);
          setHistoryDetails(responsedData.history_details || []);
        }
      } catch (error) {
        console.error("Error:", error.response?.data?.OPTO[0]?.OPTO_CODE);
        toast.error(error.response?.data?.OPTO[0]?.OPTO_MESSAGE, { position: "top-center" });
      }
    };

    fetchData();
  }, []);

  const toggleMainPopup = (editClientMainData) => {
    console.log("Popup Open : " + editClientMainData.client_id);
    setClientMainData(editClientMainData); // Store tb_clientName in state
    setmainIsOpen(!mainIsOpen);
  };

  const toggleDocsPopup = () => {
    setDocsIsOpen(!DocsIsOpen);
  };

  const toggleAddDiagnosisPopup = (editClientMainData) => {
    console.log("Popup Open : " + editClientMainData.client_id);
    setClientMainData(editClientMainData);
    setAddDiagnosisIsOpen(!AddDiagnosisIsOpen);
  };

  const toggleAddPatientPopup = (editClientMainData) => {
    console.log("Popup Open : " + editClientMainData.client_id);
    setClientMainData(editClientMainData);
    setAddPatientIsOpen(!AddPatientIsOpen);
  };

  const documentAccordionColumns = [
    {
      name: "Document Name",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          // value={}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "",
      selector: (row, index) => <button className="btnCTA">Some Action</button>,
    },
  ];

  const financeAccordionColumns = [
    {
      name: "Reference",
      // selector:
    },
    {
      name: "Reference Type",
      // selector:
    },
    {
      name: "Transaction Date",
      // selector:
    },
    {
      name: "Transaction Amount",
      // selector:
    },
    {
      name: "Action",
      // selector:
    },
  ];

  const PatientAccordionColumns = [
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
    },
    {
      name: "Moblie Number",
      selector: (row) => row.primary_number,
    },
  ];

  const diagnosisAccordionColumns = [
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row.primary_number,
      sortable: true,
    },
    {
      name: "Diagnosis Date",
      selector: (row) => formatDate(row.diagnosis_date),
      sortable: true,
    },
  ];

  const historyAccordionColumns = [
    {
      name: "Client Name",
    },
    {
      name: "Primary Number",
    },
    {
      name: "some coumn",
    },
  ];

  return (
    <div className="viewFormContainer row">
      <ToastContainer />
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading">
            {mainDetails.client_name}
          </div>
        </div>
        <Link to={"/home-page/searchclient"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      <div className="accordion col-9" id="accordionExample">
        <div className="accordion-item active">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Main
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row mt-1">
                <div className="col-md-8 mt-1 formGroup">
                  <label htmlFor="tb_clientName" className="">
                    Client Name
                  </label>
                  <input
                    type="text"
                    id="tb_clientName"
                    name="tb_clientName"
                    className="inputBox form-control   text-dark"
                    value={mainDetails.client_name || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-4 mt-1 formGroup">
                  <label htmlFor="db_clientType" className="">
                    Client Type
                  </label>
                  <input
                    type="text"
                    id="db_clientType"
                    name="db_clientType"
                    className="inputBox form-control   text-dark"
                    value={mainDetails.client_type === "I"
                      ? "Individual"
                      : "Corporate"
                    }
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_primaryContact" className="">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="tb_primaryNumber"
                    name="tb_primaryNumber"
                    value={mainDetails.primary_number || ""}
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_secondaryNumber" className="">
                    Telephone Number
                  </label>
                  <input
                    type="text"
                    id="tb_secondaryNumber"
                    name="tb_secondaryNumber"
                    value={mainDetails.secondary_number || ""}
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_emailAddress" className="">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="tb_emailAddress"
                    name="tb_emailAddress"
                    value={mainDetails.email_address || ""}
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-3 formGroup">
                  <label className="">Date Of Birth</label>
                  <input
                    type="text"
                    value={formatDate(mainDetails.dob) || "N/A"}
                    id="tb_dob"
                    name="tb_dob"
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="tb_gender" className="">
                    Gender
                  </label>
                  <input
                    type="text"
                    id="tb_gender"
                    value={
                      mainDetails.gender === "M"
                        ? "Male"
                        : mainDetails.gender === "F"
                          ? "Female"
                          : ""
                    }
                    name="tb_gender"
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-8 formGroup">
                  <label htmlFor="tb_physicalAddress" className="">
                    Physical Address
                  </label>
                  <input
                    type="text"
                    id="tb_physicalAddress"
                    name="tb_physicalAddress"
                    value={mainDetails.physical_address || ""}
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-4 mt-1 formGroup">
                  <label htmlFor="tb_poBox" className="">
                    Post Box
                  </label>
                  <input
                    type="text"
                    id="tb_poBox"
                    name="tb_poBox"
                    value={mainDetails.post_box || ""}
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_occupation" className="">
                    Occupation
                  </label>
                  <input
                    type="text"
                    id="tb_occupation"
                    name="tb_occupation"
                    value={mainDetails.occupation || ""}
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_reference" className="">
                    Refer By
                  </label>
                  <input
                    type="text"
                    id="tb_reference"
                    name="tb_reference"
                    value={mainDetails.reference_name || ""}
                    className="inputBox form-control   text-dark"
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row btnGroup">
                <div className=" d-flex justify-content-center ">
                  <button className="btn_edit col-6" onClick={() => toggleMainPopup(mainDetails)}>
                    Edit
                  </button>
                  <Mainpopup
                    id="mainDetailspopup"
                    open={mainIsOpen}
                    // onClose={toggleMainPopup}
                    onClose={setmainIsClose}
                    editClientMainData={editClientMainDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Know Your Client
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={documentAccordionColumns}
                    data={kycDetails}
                    pagination
                    responsive
                    id="documentAccordionTable"
                  ></DataTable>
                  <div className="d-flex justify-content-center ">
                    <button className="btn_edit col-6" onClick={toggleDocsPopup} >
                      Edit
                    </button>
                  </div>
                  <Docspopup
                    id="KnowYourClientDetailspopup"
                    open={DocsIsOpen}
                    onClose={toggleDocsPopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              Patient
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={PatientAccordionColumns}
                    data={patientDetails}
                    pagination
                    responsive
                    id="financeAccordionTable"
                  ></DataTable>
                  <div className="d-flex justify-content-center ">
                    <button className="btn_edit col-6" onClick={() => toggleAddPatientPopup(mainDetails)}>
                      Add Patient
                      <AddPatientPopup
                        id="AddPatientpopup"
                        open={AddPatientIsOpen}
                        onClose={setmainIsClose}
                        editClientMainData={editClientMainDetails}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Diagnosis
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={diagnosisAccordionColumns}
                    data={diagnosisDetails}
                    pagination
                    responsive
                    id="financeAccordionTable"
                  ></DataTable>
                  <div className="d-flex justify-content-center ">
                    <button className="btn_edit col-6" onClick={() => toggleAddPatientPopup(mainDetails)}>Add New Diagnosis
                      <AddDiagnosisPopup
                        id="AddDiagnosispopup"
                        open={AddDiagnosisIsOpen}
                        onClose={setmainIsClose}
                        editClientMainData={editClientMainDetails}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Finance
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={financeAccordionColumns}
                    // data={formData}
                    pagination
                    responsive
                    id="financeAccordionTable"
                  ></DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              History
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse accordion"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={historyAccordionColumns}
                    data={historyDetails}
                    pagination
                    responsive
                    id="historyAccordionTable"
                  ></DataTable>
                  <Link to={"/home-page/ViewMoreHistory"} className="link">
                    <div className="d-flex justify-content-center ">
                      <button className="btn_edit col-6" onClick={toggleDocsPopup}>
                        View more History
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="linkContainer col-3 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <p className="links" onClick={() => toggleAddPatientPopup(mainDetails)}>
          New Patient
        </p>
        <AddPatientPopup
          id="AddPatientpopup"
          open={AddPatientIsOpen}
          onClose={setmainIsClose}
          editClientMainData={editClientMainDetails}
        />
        <p className="links" onClick={() => toggleAddDiagnosisPopup(mainDetails)}>
          New Diagnosis
        </p>
        <AddDiagnosisPopup
          id="AddDiagnosispopup"
          open={AddDiagnosisIsOpen}
          onClose={setmainIsClose}
          editClientMainData={editClientMainDetails}
        />
        <Link to={"/home-page/adddiagnosisreceipts"}>
          <p className="links">
            New Diagnosis Receipt
          </p>
        </Link>
        <Link to={"/home-page/addproductreceipts"}>
          <p className="links">
            New Product Receipt
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ViewClient;
