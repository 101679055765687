import React, { useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
const SearchSuppliers = () => {
  const [formData, setFormData] = useState([]);
  const [supplierData, setsupplierData] = useState([]);
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleReset = () => {
    setFormData({
      tb_supplier: "",
      tb_primaryNumber: "",
      tb_secondaryNumber: "",
      tb_emailAddress: "",
      tb_physicalAddress: "",
      tb_poBox: "",
    });
    setsupplierData([]);
  };
  const handleExport = () => {
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(supplierData);

    // Create a workbook containing the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SupplierSearchData");

    // Generate an XLSX file and download it
    XLSX.writeFile(wb, "supplier_search_data.xlsx");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFilterClicked(false);
    try {
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/SUPPLIER/filterSupplierHandler.php',
        // data: params,
        data: {
          'tb_supplier': formData.tb_supplier,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setsupplierData(response.data.OPTO[0].OPTO_MESSAGE);
          setIsFilterClicked(true);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const columns = [
    {
      name: "Supplier Name",
      selector: (row) => `${row.supplier_name}    [${row.supplier_short_name}]`,
      sortable: true,
      width: "20%"
    },
    {
      name: "Supplier Type",
      selector: (row) => row.supplier_type,
      sortable: true,
      width: "20%"
    },
    {
      name: "Contact Person",
      selector: (row) => row.contact_person_name,
      sortable: true,
      width: "20%"
    },
    {
      name: "Mobile Number",
      selector: (row) => row.primary_number,
      sortable: true,
      width: "15%"
    },
    {
      name: "Email Address",
      selector: (row) => row.email_address,
      sortable: true,

    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          {/* Wrap the icon in an anchor tag with mailto */}
          <a href={`mailto:${row.email_address}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEnvelope}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </a>
        </button>
      ),
      width: "70px",
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <a href={`tel:${row.primary_number}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faPhone}
              style={{
                color: "#717171",
                width: "20px",
                height: "auto",
              }}
            />
          </a>
        </button>
      ),
      width: "70px",
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/home-page/viewsuppliers/${row.supplier_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "60px"
    },
  ];


  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };


  return (
    <div>
      <ToastContainer />
      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_supplier" className="">
                Supplier Name
              </label>
              <input
                type="text"
                id="tb_supplier"
                name="tb_supplier"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter supplier name"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_primaryNumber" className="">
                Mobile Number
              </label>
              <input
                type="text"
                id="tb_primaryNumber"
                name="tb_primaryNumber"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter mobile number"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="col-6  formGroup">
              <label htmlFor="tb_emailAddress" className="">
                Email Address
              </label>
              <input
                type="email"
                id="tb_emailAddress"
                name="tb_emailAddress"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter email address"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA btnFilter" onClick={handleSubmit}>
                Filter
              </button>
            </div>
            <div className="col-4">
              <Link to={"/home-page/addsuppliers"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add Supplier
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
      {isFilterClicked && supplierData.length > 0 && (
        <CSVLink data={supplierData} filename="supplier_data.csv">
          <button
            type="button"
            className="btnCTA btnFilter me-2 px-3"
            style={{
              width: "auto",
              borderRadius: "5px",
              height: "30px",
              fontSize: "14px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
              position: "relative",
              overflow: "hidden", // Make the button relative for absolute positioning of the triangle
            }}
          >

            CSV

          </button>
        </CSVLink>
      )}
      {isFilterClicked && supplierData.length > 0 && (
        <button
          type="button"
          className="btnCTA btnAddSave me-2 px-3"
          onClick={handleExport}
          style={{
            width: "auto",
            borderRadius: "5px",
            height: "30px",
            fontSize: "14px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
          }}
        >
          Excel
        </button>
      )}
      <DataTable
        className="resultData"
        columns={columns}
        data={supplierData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </div>
  );
};

export default SearchSuppliers;
