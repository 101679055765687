import React, { useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Select from "react-select";

const PatientSearch = () => {
  const [formData, setFormData] = useState({
      tb_clientName: "",
      tb_primaryNumber: "",
      tb_patientName: "",
      tb_occupation: "",
      tb_gender: "MF",
  });
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [patientData, setPatientData] = useState([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  const handleReset = () => {
    setFormData({
      tb_clientName: "",
      tb_primaryNumber: "",
      tb_secondaryNumber: "",
      tb_emailAddress: "",
      tb_gender: "M",
      tb_physicalAddress: "",
    });
    setPatientData([]);
  };

  const handleExport = () => {
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(patientData);

    // Create a workbook containing the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "PatientSearchData");

    // Generate an XLSX file and download it
    XLSX.writeFile(wb, "patient_search_data.xlsx");
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFilterClicked(false);
    try {
      const params = new URLSearchParams();
      // console.log(formData.tb_clientName);
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/PATIENT/filterPatientHandler.php",
        // data: params,
        data: {
          tb_clientName: formData.tb_clientName,
          tb_patientName: formData.tb_patientName,
          tb_primaryNumber: formData.tb_primaryNumber,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_occupation: formData.tb_occupation,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_gender: formData.tb_gender,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setPatientData(response.data.OPTO[0].OPTO_MESSAGE);
            setIsFilterClicked(true);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          } else {
            toast.error(
              response.data.OPTO[0].OPTO_CODE + ":" + response.data.OPTO_MESSAGE
            );
          }
        })
        .catch(() => {
          toast.error("Network Error", { position: "top-center" });
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const GenderOptions = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
    {
      value: "MF",
      label: "Male And Female",
    },
  ];

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Client Name",
      selector: (row) => row.client_name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.primary_number,
    },
    {
      name: "Gender",
      selector: (row) => row.gender === "M" ? "Male" : "Female",
    },
    {
      name: "Occupation",
      selector: (row) => row.occupation,
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          {/* Wrap the icon in an anchor tag with mailto */}
          <a href={`mailto:${row.email_address}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEnvelope}
              style={{
                color: "#717171",
                width: "20px",
                height: "auto",
              }}
            />
          </a>
        </button>
      ),
      width: "70px",
    },
    {
      name: "",
      cell: (row) => (
        <button
        type="button"
        className="btnEYE"
        style={{ border: "none", background: "none" }}
      >
        <a href={`tel:${row.phone_number}`}>
          <FontAwesomeIcon
            className="actionIcon"
            icon={faPhone}
            style={{
              color: "#717171",
              width: "20px",
              height: "auto",
            }}
          />
        </a>
      </button>
      ),
      width: "70px",
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none", width: "20px" }}
        >
          <Link to={`/home-page/viewpatient/${row.patient_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "70px",
    },
  ];

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer />

      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_patientName" className="">
                Patient Name
              </label>
              <input
                type="text"
                id="tb_patientName"
                name="tb_patientName"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter patient name"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_clientName" className="">
                Client Name
              </label>
              <input
                type="text"
                id="tb_clientName"
                name="tb_clientName"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter client name"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_primaryNumber" className="">
                Mobile Number
              </label>
              <input
                type="text"
                id="tb_primaryNumber"
                name="tb_primaryNumber"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter mobile number"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_occupation" className="">
                Occupation
              </label>
              <input
                type="email"
                id="tb_occupation"
                name="tb_occupation"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter occupation"
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-4">
            <label htmlFor="tb_gender" className="">
              Gender
            </label>
            <Select
              options={GenderOptions}
              placeholder="Gender"
              id="tb_gender"
              name="tb_gender"
              defaultValue={{ value: "MF", label: "Male And Female" }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "40px",
                }),
              }}
              className=""
              // value={formData.db_clientType}
              isSearchable={false}
              isClearable={false}
              onChange={handleSingleSelect}
            />
          </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-6">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={() => window.location.reload()}
              >
                Reset
              </button>
            </div>
            <div className="col-md-6">
              <button type="submit" className="btnCTA btnFilter">
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
      {isFilterClicked && patientData.length > 0 && (
        <CSVLink data={patientData} filename="patient_data.csv">
          <button
            type="button"
            className="btnCTA btnFilter me-2 px-3"
            style={{
              width: "auto",
              borderRadius: "5px",
              height: "30px",
              fontSize: "14px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
              position: "relative",
              overflow: "hidden", // Make the button relative for absolute positioning of the triangle
            }}
          >

            CSV

          </button>
        </CSVLink>
      )}
      {isFilterClicked && patientData.length > 0 && (
        <button
          type="button"
          className="btnCTA btnAddSave me-2 px-3"
          onClick={handleExport}
          style={{
            width: "auto",
            borderRadius: "5px",
            height: "30px",
            fontSize: "14px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
          }}
        >
          Excel
        </button>
      )}
      <DataTable
        className="resultData col-md-8"
        columns={columns}
        data={patientData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>

      <div className="col-md-8 mx-auto">
        <Link to={"/home-page/addpatient"}>
          <button className="btnCTA btnAddSave">Add Patient</button>
        </Link>
      </div>
    </div>
  );
};

export default PatientSearch;
