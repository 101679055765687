import React from "react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_BASE_URL } from '../../../../optoCustom.js';
import StockDetails from "../../Inventory/StockDetails.jsx";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faDownload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

const ViewReceipts = () => {
  const { receiptId } = useParams();
    const [receiptsDetails, setReceiptsDetails] = useState([]);
  const hasFetchedData = useRef(false);
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        if (hasFetchedData.current) return;
        // Mark that the API has been called
        hasFetchedData.current = true;
        axios({
          method: "post",
          url: API_BASE_URL + "FINANCE/RECEIPTS/viewDiagnosisReceiptDetailsHandler.php",
          data: {
            tb_receiptId: receiptId
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
            ) {
              // Extract the OPTO_MESSAGE array and set it to the state
              // setClientData(response.data.OPTO[0].OPTO_MESSAGE);
              console.log(response.data.OPTO[0].OPTO_MESSAGE);
              const responsedData = response.data.OPTO[0].OPTO_MESSAGE;
              setReceiptsDetails(responsedData.receipt_details[0] || {});
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
              } else {
                toast.error(error.message, { position: "top-center" });
              }
            } else {
              toast.error("Network Error", { position: "top-center" });
            }
          });
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };
  const columns = [
    {
      name: "Prescription Details",
      // selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Course Duration",
      // selector: (row) => row.name,
    },
    {
      name: "Follow up Date",
      // selector: (row) => row.address.street,
    },
    {
      name: "Prescription Date",
      // selector: (row) => row.phone,
    },

    {
      name: "Download",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={"/Hompage/ViewDiagnosis"}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faDownload}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
          // onClick={handleDelete}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "25px",
              height: "auto",
            }}
          />
        </button>
      ),
    },
  ];

  return (
    <div className="viewFormContainer row">
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading"> 
          {receiptsDetails.name_on_receipt}
          </div>
        </div>
        <Link to={"/home-page/searchreceipts"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      <form className="inputContent col-md-9">
        <div className="row">
          <div className="col-md-6 formGroup">
            <label htmlFor="tb_receiptNumber" className="">
              Receipt Number
            </label>
            <input
              type="text"
              id="tb_receiptNumber"
              name="tb_receiptNumber"
              className="inputBox form-control  text-dark"
              maxLength="12"
              value={receiptsDetails.receipt_number || ""}
              readOnly
            />
          </div>
          <div className="col-6 formGroup">
            <label htmlFor="tb_quantity" className="">
              Quantity
            </label>
            <input
              type="text"
              id="tb_quantity"
              name="tb_quantity"
              className="inputBox form-control  text-dark"
              value={receiptsDetails.quantity || ""}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_referenceType" className="">
              Reference Type
            </label>
            <input
              type="text"
              id="tb_referenceType"
              name="tb_referenceType"
              className="inputBox form-control  text-dark"
              value={
                receiptsDetails.reference_type === "D"
                  ? "Diagnosis"
                  : receiptsDetails.reference_type === "S"
                    ? "Stock"
                    : ""
              }
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_grossAmount" className="">
              Gross Amount
            </label>
            <input
              type="text"
              id="tb_grossAmount"
              name="tb_grossAmount"
              className="inputBox form-control  text-dark"
              value={receiptsDetails.total_gross_amount || ""}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_totalDiscount" className="">
              Total Discount
            </label>
            <input
              type="text"
              id="tb_totalDiscount"
              name="tb_totalDiscount"
              className="inputBox form-control  text-dark"
              value={receiptsDetails.total_discount || ""}
              readOnly
            />
          </div>
          <div className="col-6 formGroup">
            <label htmlFor="tb_totaltax" className="">
              Total Tax
            </label>
            <input
              type="text"
              id="tb_totalTax"
              name="tb_totalTax"
              className="inputBox form-control  text-dark"
              value={receiptsDetails.total_tax || ""}
              readOnly
            />
          </div>
          <div className="col-6 formGroup">
            <label htmlFor="tb_totalNetAmount" className="">
              Total Net Amount
            </label>
            <input
              type="text"
              id="tb_totalNetAmount"
              name="tb_totalNetAmount"
              className="inputBox form-control  text-dark"
              value={receiptsDetails.total_net_amount || ""}
              readOnly
            />
          </div>
          <div className="col-12 formGroup">
            <label htmlFor="tb_bankAccount" className="">
              Our bank Account
            </label>
            <input
              type="text"
              id="tb_bankAccount"
              name="tb_bankAccount"
              className="inputBox form-control  text-dark"
              value={receiptsDetails.our_bank_account || ""}
              readOnly
            />
          </div>
        </div>
      </form>
      <div className="linkContainer col-2 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Color"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Name"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Brand"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Style"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default ViewReceipts;
