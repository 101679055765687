import React from 'react'
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit } from "@fortawesome/free-solid-svg-icons";
import EditMyProfilePopup from "./EditMyProfilePopup.jsx";
const MyProfile = () => {

    // const [formData, setData] = useState([]);
    const [DocsIsOpen, setDocsIsOpen] = useState(false);

    const toggleDocsPopup = () => {
        setDocsIsOpen(!DocsIsOpen);
    };

    const tableStyles = {
        headRow: {
            style: {
                backgroundColor: "#E6E6E6",
                fontWeight: 600,
                borderBottom: "2px solid #D7D8D8",
            },
        },
        headCells: {
            style: {},
        },
        rows: {
            style: {},
        },
        cells: {
            style: {},
        },
    };
    const columns = [
        {
            name: "Prescription Details",
            // selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Course Duration",
            // selector: (row) => row.name,
        },
        {
            name: "Follow up Date",
            // selector: (row) => row.address.street,
        },
        {
            name: "Prescription Date",
            // selector: (row) => row.phone,
        },

        {
            name: "Download",
            cell: (row) => (
                <button
                    type="button"
                    className="btnEYE"
                    style={{ border: "none", background: "none" }}
                >
                    <Link to={"/Hompage/ViewDiagnosis"}>
                        <FontAwesomeIcon
                            className="actionIcon"
                            icon={faDownload}
                            style={{
                                color: "#717171",
                                width: "25px",
                                height: "auto",
                            }}
                        />
                    </Link>
                </button>
            ),
        },
    ];
    return (
      
        <div>
            <div className="headings d-flex   justify-content-between row">
                <div className="titletext d-flex  col-md-5"><div className="formHeading">
                    Welcome, Dev Bagga
                    {/* {mainDetails?.client_name} */}
                </div>
                </div>

                <div className="row btnGroup col-md-7">
                    <div className="col-md-5">
                        <button type="button" className="btnCTA btnAddSave" onClick={toggleDocsPopup}>
                            Login Credentials
                        </button>
                    </div>
                    <div className="col-md-5">
                        <button type="button" className="btnCTA btnAddSave" onClick={toggleDocsPopup}>
                            Approval Credentials
                        </button>
                    </div>
                    <div className="col-md-2">
                        <button type="button" className="btnCTA btnAddSave" onClick={toggleDocsPopup}>
                            <FontAwesomeIcon
                                className="navbaricon"
                                icon={faEdit}
                                style={{ color: "#FFFFFF", width: "20px", height: "auto" }}
                                data-bs-toggle-tooltip="tooltip"
                                title="Profile"
                            />
                        </button>
                        <EditMyProfilePopup
                            id="EditMyProfilePopup"
                            open={DocsIsOpen}
                            onClose={toggleDocsPopup}
                            customHeight={"300px"}
                        />
                    </div>
                </div>
            </div>

            <div className="row mt-0">
                <div className="col-md-6 formGroup">
                    <label htmlFor="tb_contactNumber" className="">
                        Contact Number
                    </label>
                    <input
                        type="text"
                        id="tb_contactNumber"
                        name="tb_contactNumber"
                        className="inputBox form-control text-dark"
                        readOnly
                    />
                </div>
                <div className="col-md-6 formGroup">
                    <label htmlFor="tb_emailAddress" className="">
                        Email Address
                    </label>
                    <input
                        type="email"
                        id="tb_emailAddress"
                        name="tb_emailAddress"
                        className="inputBox form-control   text-dark"
                        readOnly
                    />
                </div>
 
            </div>
            <hr />
            <div>
                <div className="formHeading">Recent Activites</div>
                <DataTable
                    className="resultData"
                    columns={columns}
                    // data={formData}
                    pagination
                    responsive
                    id="myTable"
                    customStyles={tableStyles}
                ></DataTable>
            </div>
        </div>
    )
}

export default MyProfile
