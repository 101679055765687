import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  useNavigate } from 'react-router-dom';
import './ForgotCredientials.css';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import img_optolinqName from '../../../Assets/OPTO LINQ.png';
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Card,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  FloatingLabel,
} from "react-bootstrap";



const ForgotCredientials = () => {

  const navigate = useNavigate();

 const [formData, setFormData] = useState({
    tb_userNameField: "",
    opto_domain: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const labelstyle = {
    color: "#e0e0e0",
    fontSize: "14px",
    opacity: "50%"
  };

  const linkStyle = {
    textDecoration: "none"
  };

  const inputStyle = {
    backgroundColor: "#333333",
    borderRadius: "30px",
    color: "#ffffff",
    maxWidth:" 350px",
    margin: "auto",
    border: "none",
    height: "50px"
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      axios({
        method: 'post',
        url: API_BASE_URL + 'IAMS/forgotCredentialsHandler.php',
        data: { 
          tb_userNameField: formData.tb_userNameField,
          opto_domain:  window.location.hostname,
        },
        withCredentials: true,
        crossDomain: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
          navigate(response.data.OPTO[0].OPTO_LINK);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };
  return (
    <div>
      <ToastContainer />
      <Container className="cont_login d-flex justify-content-center align-items-center min-vh-100">
        <Card className="p-4" style={{ maxWidth: "400px", width: "100%" }}>
          <Card.Body>
            <br />
            <div className="img_optolinqName text-center mb-3">
              <img
                src={img_optolinqName}
                alt="logo"
                style={{ width: "95%", height: "auto" }}
              />
            </div>
            <Form onSubmit={handleSubmit} className="frm_login mt-3">
              <div className="text-center mt-4 name mx-auto" id="sub-heading">
                Enter your username and we'll send you a recovery email
              </div>
              <FormGroup className="tb_userNameField d-flex flex-column align-items-left mb-3 mx-auto" style={{maxWidth: "350px"}}>
                <FloatingLabel htmlFor="username"  className="username" style={labelstyle}>
                  Re-Enter Username
                </FloatingLabel>
                <InputGroup>
                <FormControl
                    type="text"
                    id="tb_userNameField"
                    name="tb_userNameField"
                    className="tb_userNameFieldInput"
                    placeholder="Username"
                    value={formData.tb_userNameField}
                    onChange={handleChange}
                    style={inputStyle}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button type="submit" style={linkStyle} onClick={handleSubmit} id='btn_getEmail' name='btn_getEmail' className="btn_login mt-3 w-100 d-flex justify-content-center align-items-center mx-auto">
                  Get Email
                </Button>
              </div>
              <br />
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default ForgotCredientials
