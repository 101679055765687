import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import img_optolinqName from "../../../Assets/OPTO LINQ.png";
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Card,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  FloatingLabel,
} from "react-bootstrap";

const Login = () => {

  const navigate = useNavigate();
  const [showForgotLink, setShowForgotLink] = useState(false);

  const [formData, setFormData] = useState({
    tb_userNameField: "",
    tb_passwordField: "",
    opto_domain: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const labelstyle = {
    color: "#e0e0e0",
    fontSize: "14px",
    opacity: "50%",
  };

  const linkstyle = {
    color: "#BB86FC",
    fontSize: "14px",
    margin: "auto",
    marginBottom: "0px",
    padding: "0px",
    border: "none",
  };

  const inputStyle = {
    backgroundColor: "#333333",
    borderRadius: "30px",
    color: "#ffffff",
    maxWidth: " 350px",
    margin: "auto",
    border: "none",
    height: "50px"
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      axios({
        method: 'post',
        url: API_BASE_URL + 'IAMS/loginHandler.php',
        data: {
          tb_userNameField: formData.tb_userNameField,
          tb_passwordField: formData.tb_passwordField,
          opto_domain: window.location.hostname,
        },
        withCredentials: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
          navigate(response.data.OPTO[0].OPTO_LINK);
        }
      })
        .catch((error) => {
          // console.log(error.response.data);
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
            setShowForgotLink(true);
          } else {
            toast.error('Error: Connection refused. Please reload the page or contact the administrator at contactus@optolinq.online', { position: "top-center" })
          }
        });
    } catch (error) {
      // console.error("Error: asd as");
      toast.error('Error: Connection refused. Please reload the page or contact the administrator at contactus@optolinq.online', { position: "top-center" })
    }
  };

  return (
    <div className="background">
      <ToastContainer />
      <Container className="cont_login d-flex justify-content-center align-items-center min-vh-100">
        <Card className="p-4" style={{ maxWidth: "400px", width: "100%" }}>
          <Card.Body>
            <br />
            <div className="img_optolinqName text-center mb-3">
              <img
                src={img_optolinqName}
                alt="logo"
                style={{ width: "95%", height: "auto" }}
              />
            </div>
            <Form onSubmit={handleSubmit} className="frm_login mt-3">
              <div className="text-center mt-4 name mx-auto" id="sub-heading">
                Enter your credentials to sign in
              </div>

              <FormGroup className="tb_userNameField d-flex flex-column align-items-left mb-3 mx-auto" style={{ maxWidth: "350px" }}>
                <FloatingLabel htmlFor="username" className="username" style={labelstyle}>
                  Username
                </FloatingLabel>
                <InputGroup>
                  <FormControl
                    type="text"
                    id="tb_userNameField"
                    name="tb_userNameField"
                    className="tb_userNameFieldInput"
                    placeholder="Username"
                    value={formData.tb_userNameField}
                    onChange={handleChange}
                    style={inputStyle}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup className="tb_passwordField flex flex-column align-items-left mx-auto mb-3" style={{ maxWidth: "350px" }}>
                <FloatingLabel htmlFor="password" className="password" style={labelstyle}>
                  Password
                </FloatingLabel>
                <InputGroup>
                  <FormControl
                    type="password"
                    id="tb_passwordField"
                    name="tb_passwordField"
                    className="tb_passwordFieldInput"
                    placeholder="Password"
                    value={formData.tb_passwordField}
                    onChange={handleChange}
                    style={inputStyle}
                  />
                </InputGroup>
              </FormGroup>
              <div className="mx-auto" style={{ maxWidth: "350px" }}>
                {showForgotLink && (
                  <Link to={"/ForgotCredientials"} style={linkstyle}>
                    Forgot Credentials? Click Here
                  </Link>
                )}
              </div>
              <div className="d-flex ">
                <Button
                  type="submit"
                  id="btn_login"
                  name="btn_login"
                  className="btn_login mt-3 w-100 mx-auto"
                >
                  Sign in
                </Button>
              </div>
              <br />
            </Form>
          </Card.Body>
          
        </Card>
      </Container>
    </div>
  );
};

export default Login;
