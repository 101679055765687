// export const API_BASE_URL = 'http://localhost/OptoLinq/optolinq-web/handler/';  // Test PSHAH
// export const API_BASE_URL = 'http://192.168.29.186/';  // Test NANDI
// export const API_BASE_URL = 'http://localhost/OptoLinq//';  // Test RIYA
// export const API_BASE_URL = 'http://dev.projects.local/OploLinq//';  // PILLP VPS
export const API_BASE_URL = 'https://handler.optolinq.online/';  // PILLP PROD
// export const API_BASE_URL = 'https://pillp.optolinq.online/';  // PILLP PROD
// export const API_BASE_URL = 'https://ecc.optolinq.online/';  // PILLP PROD
// export const API_BASE_URL = 'http://dev.optolinq.local/handler/'; // test riya

export const formatDate = (dob) => {
    if (!dob) return "N/A"; // If dob is empty or falsy, return "N/A"

    const date = new Date(dob); // Convert the string to a Date object
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
    const month = date.toLocaleString('en-US', { month: 'short' }); // Get the short month name (e.g., Jan)
    const year = date.getFullYear(); // Get the full year (e.g., 2005)

    return `${day}/${month}/${year}`; // Return in the format dd/Mon/yyyy
};