import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { API_BASE_URL , formatDate } from "../../../../src/optoCustom.js";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const SearchDiagnosis = () => {
  const [formData, setFormData] = useState({
    tb_patientName: "",
    tb_clientName: "",
    tb_diagnosisdate: "",
    tb_doctorId: "",
    tb_gender: "MF",
  });
  const [diagnosisData, setDiagnosisData] = useState([]);

  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
      // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // Handle regular inputs (like text, number, etc.)
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
  };

  const handleReset = () => {
    setFormData({
      tb_patientName: "",
      tb_clientName: "",
      tb_diagnosisdate: "",
      tb_doctorId: "",
      tb_gender: "",
    });
    setDiagnosisData([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "DIAGNOSIS/filterDiagnosisHandler.php",
        data: {
          tb_patientName: formData.tb_patientName,
          tb_clientName: formData.tb_clientName,
          tb_diagnosisdate: formData.tb_diagnosisdate,
          tb_doctorId: formData.tb_doctorId,
          tb_gender: formData.tb_gender,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setDiagnosisData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: API_BASE_URL + "/COMMON/getDoctorList.php",
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        }); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.doctorId, // Adjust based on your API response structure
          label: option.doctorName, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);
  const GenderOptions = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
    {
      value: "MF",
      label: "Male And Female",
    },
  ];

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
      width: "25%",
    },
    {
      name: "Client Name",
      selector: (row) => row.client_name,
      width: "25%",
    },
    {
      name: "Diagnosis Date",
      selector: (row) => formatDate(row.diagnosis_date),
      width: "20%",
    },
    {
      name: "Doctor",
      selector: (row) => row.staff_name,
      width: "16%",
    },

    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/home-page/viewdiagnosis/${row.diagnosis_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "80px",
    },
    ,
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "25px",
              height: "auto",
            }}
          />
        </button>
      ),
      width: "80px",
    },
  ];
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer />
      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_patientName" className="">
                Patient Name
              </label>
              <input
                type="text"
                id="tb_patientName"
                name="tb_patientName"
                placeholder="Enter patient name"
                className="inputBox form-control formControl  text-dark"
                value={formData.tb_patientName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_clientName" className="">
                Client Name
              </label>
              <input
                type="text"
                id="tb_clientName"
                name="tb_clientName"
                placeholder="Enter client name"
                className="inputBox form-control formControl  text-dark"
                value={formData.tb_clientName}
                onChange={handleChange}
              />
            </div>
            {/* Datepicker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="col-4 formGroup">
              <label htmlFor="tb_diagnosisDate" className="">
                Diagnosis Date
              </label>
              <DatePicker
                value={dayjs(formData.tb_diagnosisDate)}
                className=""
                id="tb_diagnosisDate"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_diagnosisDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_diagnosisDate" {...params} />}
              />
            </div>
            </LocalizationProvider>
            <div className="formGroup col-4">
              <label htmlFor="singleSelect">Doctor</label>
              <Select
                options={createdByOptions}
                placeholder="Select Doctor"
                id="tb_doctorId"
                 name="tb_doctorId"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "40px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-4">
            <label htmlFor="tb_gender" className="">
              Gender
            </label>
            <Select
              options={GenderOptions}
              placeholder="Gender"
              id="tb_gender"
              name="tb_gender"
              defaultValue={{ value: "MF", label: "Male And Female" }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "40px",
                }),
              }}
              className=""
              // value={formData.db_clientType}
              isSearchable={false}
              isClearable={false}
              onChange={handleSingleSelect}
            />
          </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button type="reset" className="btnCTA btnReset" onClick={handleReset}>
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA btnFilter">
                Filter
              </button>
            </div>
            <div className="col-4">
              <Link to={"/home-page/adddiagnosis"}>
                <button type="submit" className="btnCTA btnAddSave">
                  Add Diagnosis
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData"
        columns={columns}
        data={diagnosisData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </div>
  );
};

export default SearchDiagnosis;
