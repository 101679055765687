import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Popup from 'reactjs-popup';
import Select from "react-select";
import 'reactjs-popup/dist/index.css';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddPatientPopup = ({ open, editClientMainData , onClose  }) => {
  const navigate = useNavigate();
  // State to handle form data
  const hasFetchData = useRef(false); 
  const [createdByOptions, setCreatedByOptions] = useState([]);
  const [formData, setFormData] = useState({
    tb_patientName: "",
    tb_clientName: "",
    tb_primaryContact: "",
    tb_secondaryContact: "",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_poBox: "",
    tb_gender: "M",
    tb_dateOfBirth: "",
    tb_occupation: "",
  });

  const [gender, setGender] = useState('M'); // Default value is "M"

  useEffect(() => {
      console.log('Form With FormData : ', formData);
      if (editClientMainData) {
        setFormData({
          tb_clientId: editClientMainData.client_id,
          tb_clientName: editClientMainData.client_name,
        });
      }
    }, [editClientMainData]);

    const handleSingleSelect = (singleselectedOption, actionMeta) => {
      console.log(singleselectedOption?.value);
      const { name } = actionMeta;
      if (singleselectedOption == null) {
        setFormData({
          ...formData,
          [name]: "",
        });
      } else {
        setFormData({
          ...formData,
          [name]: singleselectedOption?.value,
        });
      }
    };

    useEffect(() => {
      if (hasFetchData.current) return;
      // Mark that the API has been called
      hasFetchData.current = true;
      const CreatedByOptions = async () => {
        try {
          const response = await axios({
            method: 'get',
            url: API_BASE_URL + 'COMMON/getPatientList.php',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true,
          }) // Replace with your API endpoint
          const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
            value: option.patient_id, // Adjust based on your API response structure
            label: option.patient_name, // Adjust based on your API response structure
          }));
          setCreatedByOptions(options);
        } catch (err) {
          console.log(err);
        }
      };
  
      CreatedByOptions();
    }, []);
  // Handle input change
  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
    // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
    setFormData({
    ...formData,
    [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
    });
    } else {
    // Handle regular inputs (like text, number, etc.)
    setFormData({
    ...formData,
    [e.target.name]: e.target.value, // Update with the regular input value
    });
    }

    };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/PATIENT/addPatientHandler.php',
        // data: params,
        data: {
          'tb_patientName': formData.tb_patientName,
          'tb_clientId': formData.tb_clientId,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_gender': formData.tb_gender,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
          'tb_dob': formData.tb_dob,
          'tb_occupation': formData.tb_occupation,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          onClose();
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };

  return (
    <>
       <div>
      <Popup open={open} closeOnDocumentClick={false} onClose={onClose} >
        <div className="editPopup" style={{height: "600px"}}>
           {/* Cross button to close the popup */}
           <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
        <div className="row ">
          <div className="col-md-12  formGroup">
            <label htmlFor="tb_clientId" className="">
              Client Name
            </label>
            <input
              type="text"
              id="tb_clientId"
              name="tb_clientId"
              className="inputBox form-control formControl text-dark"
               placeholder="Enter Client Name"
               value={formData.tb_clientName}
               readOnly
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 formGroup">
            <label htmlFor="tb_patientName" className="">
              Patient Name
            </label>
            <input
              type="text"
              id="tb_patientName"
              name="tb_patientName"
              className="inputBox form-control formControl text-dark"
               placeholder="Enter Patient Name"
               value={formData.tb_patientName}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
               placeholder="Enter email Address"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_primaryNumber" className="">
              Mobile Number
            </label>
            <input
              type="text"
              id="tb_primaryNumber"
              name="tb_primaryNumber"
              className="inputBox form-control formControl  text-dark"
               placeholder="Enter Mobile Number"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_secondaryNumber" className="">
              Telephone Number
            </label>
            <input
              type="text"
              id="tb_secondaryNumber"
              name="tb_secondaryNumber"
              className="inputBox form-control formControl  text-dark"
               placeholder="Enter Telephone Number"
              onChange={handleChange}
            />
          </div>
          {/* Datetimepicker */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="col-6 formGroup">
              <label htmlFor="tb_dob" className="">
                Date of Birth
              </label>
              <DatePicker
                value={dayjs(formData.tb_dob)}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_dob" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_dob" {...params} />}
              />
            </div>
            </LocalizationProvider>
            <div className="col-md-6 pe-2">
                <label className="form-check-label" htmlFor="gender">
                  Gender
                </label>
                <div className="dropdownGroup">
                  <select
                    className="form-select inputBox form-control formControl text-dark"
                    name="tb_gender"
                    id="tb_gender"
                    value={formData.tb_gender || ''} // Ensure it is never undefined
                    onChange={handleChange} // Handle the change when the user selects an option
                  >
                    <option value="M">Male</option> {/* Option for Male */}
                    <option value="F">Female</option> {/* Option for Female */}
                  </select>
                </div>
              </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
               placeholder="Enter physical address"
              cols="100"
              rows={5}
              onChange={handleChange}
            />
          </div>
          <div className="col-6  formGroup">
            <label htmlFor="tb_poBox" className="">
              Post Box
            </label>
            <input
              type="text"
              id="tb_poBox"
              name="tb_poBox"
              className="inputBox form-control formControl  text-dark"
               placeholder="Enter post box number"
              onChange={handleChange}
            />
          </div>

          <div className="col-6  formGroup">
            <label htmlFor="tb_occupation" className="">
              Occupation
            </label>
            <input
              type="text"
              id="tb_occupation"
              name="tb_occupation"
              className="inputBox form-control formControl  text-dark"
               placeholder="Enter occupation"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA btnAddSave" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
        </div>
        </Popup>
        </div>
    </>
  );
};

export default AddPatientPopup;
