import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../../optoCustom.js";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
const SearchLedgers = () => {
  const [formData, setFormData] = useState([]);
  const [staffData, setStaffData] = useState([]);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: API_BASE_URL + "MASTER/STAFF/viewStaffListHandler.php",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setStaffData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else {
            toast.error("Network Error", { position: "top-center" });
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  }, []);

  const handleReset = () => {
    setFormData({
      tb_staffName: "",
      db_departmentName: "",
      db_designationName: "",
    });
    setStaffData([]);
  };
  const columns = [
    {
      name: "Ledger Code",
      selector: (row) => row.staff_name,
      sortable: true,
    },
    {
      name: "Ledger Name",
      selector: (row) => row.department_id,
      sortable: true,
    },
    {
      name: "Introducted on",
      selector: (row) => row.designation_id,
      sortable: true,
    },
    {
        name: "Introducted by",
        selector: (row) => row.designation_id,
        sortable: true,
      },
  
    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/home-page/ViewLedgers/${row.ledger_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];

const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      
      <ToastContainer />
      <DataTable
        className="resultData"
        columns={columns}
        data={staffData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
      <div className="inputContent"></div>
      <div className="row btnGroup">
        <div className="col-md-4"></div>
        <div className="col-4">
          <Link to={"/home-page/addledgers"}>
            <button type="button" className="btnCTA btnAddSave">
              Add Ledger
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchLedgers;
