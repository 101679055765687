import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const EditPatientMainDetails = ({ open, editPatientMainData, onClose }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_clientId: '',
    tb_patientName: '',
    tb_clientName: '',
    tb_emailAddress: '',
    tb_primaryContact: '',
    tb_gender: '',
    tb_dateOfBirth: '',
    tb_physicalAddress: '',
    tb_poBox: '',
    tb_occupation: ''
  });

  // Initialize form data if editPatientMainData is available
  useEffect(() => {

    if (editPatientMainData) {
      setFormData({
        tb_patientId: editPatientMainData.patient_id,
        tb_patientName: editPatientMainData.patient_name,
        tb_clientId: editPatientMainData.client_id,
        tb_clientName: editPatientMainData.client_name,
        tb_primaryNumber: editPatientMainData.primary_number,
        tb_secondaryNumber: editPatientMainData.secondary_number,
        tb_emailAddress: editPatientMainData.email_address,
        tb_physicalAddress: editPatientMainData.physical_address,
        tb_poBox: editPatientMainData.post_box,
        tb_dob: editPatientMainData.dob,
        tb_occupation: editPatientMainData.occupation,
        tb_reference: editPatientMainData.reference,
        tb_gender: editPatientMainData.gender,
      });
    }
  }, [editPatientMainData]);


  const handleChange = (e, value) => {
    if (value && value.format) {
      // This is the DatePicker case
      const formattedDate = value ? value.format('YYYY-MM-DD') : '';
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // This is the regular input case
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form With FormData : ', formData);
    try {
      const params = new URLSearchParams();
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/PATIENT/editPatientMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_clientId': formData.tb_clientId,
          'tb_patientId': formData.tb_patientId,
          'tb_patientName': formData.tb_patientName,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_gender': formData.tb_gender,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
          'tb_dob': formData.tb_dob,
          'tb_occupation': formData.tb_occupation,
          'tb_reference': formData.tb_reference,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          onClose();
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:",  error.response.data.OPTO[0].OPTO_CODE);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Popup open={open} closeOnDocumentClick={false} onClose={onClose} >
        <div className="editPopup" style={{ height: "600px" }}>
           {/* Cross button to close the popup */}
           <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
          <div className="row ">
            <div className="col-md-12 formGroup">
              <label htmlFor="tb_patientName" className="">
                Patient Name
              </label>
              <input
                type="text"
                id="tb_patientName"
                name="tb_patientName"
                className="inputBox form-control formControl text-dark"
                placeholder='Enter patient name'
                value={formData.tb_patientName}
                onChange={handleChange}
              />
            </div>

            {/* Container for Client Name and Email Address */}
            <div className="formGroup d-flex">
              <div className="col-md-6 pe-2">
                <label htmlFor="tb_clientName" className="">
                  Client Name
                </label>
                <input
                  type="text"
                  readOnly
                  id="tb_clientName"
                  name="tb_clientName"
                  className="inputBox form-control formControl text-dark"
                  placeholder='Enter client name'
                  value={formData.tb_clientName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 ps-2">
                <label htmlFor="tb_emailAddress" className="">
                  Email Address
                </label>
                <input
                  type="email"
                  id="tb_emailAddress"
                  name="tb_emailAddress"
                  className="inputBox form-control formControl text-dark"
                  placeholder='Enter email address'
                  value={formData.tb_emailAddress}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Container for Primary Contact and Secondary Contact */}
            <div className="formGroup d-flex">
              <div className="col-md-6 pe-2">
                <label htmlFor="tb_primaryNumber" className="">
                  Mobile Number
                </label>
                <input
                  type="number"
                  id="tb_primaryNumber"
                  name="tb_primaryNumber"
                  className="inputBox form-control formControl text-dark"
                  placeholder='Enter mobile number'
                  value={formData.tb_primaryNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 ps-2">
                <label htmlFor="tb_secondaryNumber" className="">
                  Telephone Number
                </label>
                <input
                  type="number"
                  id="tb_secondaryNumber"
                  name="tb_secondaryNumber"
                  className="inputBox form-control formControl text-dark"
                  placeholder='Enter telephone number'
                  value={formData.tb_secondaryNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Container for Gender and Date of Birth */}
            <div className="formGroup d-flex">
              <div className="col-md-6 pe-2">
                <label className="form-check-label" htmlFor="gender">
                  Gender
                </label>
                <div className="dropdownGroup">
                  <select
                    className="form-select inputBox form-control formControl text-dark"
                    name="tb_gender"
                    id="tb_gender"
                    value={formData.tb_gender || ''} // Ensure it is never undefined
                    onChange={handleChange} // Handle the change when the user selects an option
                  >
                    <option value="M">Male</option> {/* Option for Male */}
                    <option value="F">Female</option> {/* Option for Female */}
                  </select>
                </div>
              </div>
              {/* Datetimepicker */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="col-6 formGroup">
                <label htmlFor="tb_dob" className="">
                  Date of Birth
                </label>
                <DatePicker
                  value={dayjs(formData.tb_dob)}
                  className=""
                  id="tb_dob"
                  maxDate={dayjs()}
                  // onChange={(newValue) =>
                  //   handleChange({ target: { name: "tb_dob" } }, newValue)
                  // } // Pass value to handleChange
                  onChange={(newValue) =>
                    handleChange({ target: { name: "tb_dob" } }, newValue) // Pass value to handleChange
                  }
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                    "& .MuiOutlinedInput-root": {
                      "&:hover > fieldset": {
                        border: "1px solid #b0b0b0 !important",
                      },
                      height: "35px",
                      border: "1px solid #b0b0b0 !important",
                      borderRadius: "0px",
                    },
                  }}
                  format="DD/MMM/YYYY"
                  renderInput={(params) => <input name="tb_dob" {...params} />}
                />
              </div>
              </LocalizationProvider>
            </div>

            {/* Container for Occupation and Post Box */}
            <div className="formGroup d-flex">
              <div className="col-md-6 pe-2">
                <label htmlFor="tb_poBox" className="">
                  Post Box
                </label>
                <input
                  type="text"
                  id="tb_poBox"
                  name="tb_poBox"
                  className="inputBox form-control formControl text-dark"
                  placeholder='Enter post box number'
                  value={formData.tb_poBox}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 ps-2">
                <label htmlFor="tb_occupation" className="">
                  Occupation
                </label>
                <input
                  type="text"
                  id="tb_occupation"
                  name="tb_occupation"
                  className="inputBox form-control formControl text-dark"
                  placeholder='Enter occupation'
                  value={formData.tb_occupation}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-md-12 formGroup">
              <label htmlFor="tb_physicalAddress" className="">
                Physical Address
              </label>
              <textarea
                id="tb_physicalAddress"
                name="tb_physicalAddress"
                className="inputBox form-control textarea text-dark tb_address"
                placeholder='Enter physical address'
                cols="100"
                value={formData.tb_physicalAddress}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-6 ">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={() => window.location.reload()}
              >
                Reset
              </button>
            </div>
            <div className="col-md-6 ">
              <button type="submit" className="btnCTA btnAddSave" onClick={handleSubmit} onClose={onClose}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditPatientMainDetails;
