import React from "react";
import { Link, useParams } from "react-router-dom";
import ViewPatient from "../Masters/Patients/ViewPatient";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faDownload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import AddPrescriptionPopup from "../../HomePage/Diagnosis/AddPrescriptionPopup";
import AddAnotherDiagnosisPopup from "../../HomePage/Diagnosis/AddAnotherDiagnosisPopup";
import ViewClient from "../Masters/Client/ViewClient";
import { API_BASE_URL, formatDate } from "../../../optoCustom";

import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

const ViewDiagnosis = () => {
  const { diagnosisId } = useParams();
  console.log("Diagnosis id From Search : " + diagnosisId);
  const [formData, setData] = useState([]);
  const [diagnosisDetails, setDiagnosisDetails] = useState([]);
  const [editDiagnosisMainDetails, setDiagnosisMainData] = useState(null);
  const [prescriptiondetails, setPrescriptionDetails] = useState([]);
  const [editPrescriptionMainDetails, setPrescriptionMainData] = useState(null);
  const [AddDiagnosisIsOpen, setAddDiagnosisIsOpen] = useState(false);
  const [AddPrescriptionIsOpen, setAddPrescriptionIsOpen] = useState(false);

  const toggleAddPrescriptionPopup = (editPescriptionMainData) => {
    console.log("Popup Open : " + diagnosisDetails.doctor_name);
    setPrescriptionMainData(editPescriptionMainData); // Store tb_clientName in state
    setAddPrescriptionIsOpen(!AddPrescriptionIsOpen);
  };

  const toggleAddDiagnosisPopup = (editClientMainData) => {
    console.log("Popup Open : " + editClientMainData.diagnosis_id);
    setDiagnosisMainData(editClientMainData); // Store tb_clientName in state
    setAddDiagnosisIsOpen(!AddDiagnosisIsOpen);
  };

  const setmainIsClose = () => {
    setAddDiagnosisIsOpen(false);
    window.location.reload(); // Refresh the page
  };

  const setprescriptionIsClose = () => {
    setAddPrescriptionIsOpen(false);
    window.location.reload(); // Refresh the page
  };
  const hasFetchedData = useRef(false);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (hasFetchedData.current) return;
        // Mark that the API has been called
        hasFetchedData.current = true;
        axios({
          method: "post",
          url: API_BASE_URL + "DIAGNOSIS/viewDiagnosisDetails.php",
          data: {
            tb_diagnosisId: diagnosisId
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
            ) {
              // Extract the OPTO_MESSAGE array and set it to the state
              // setClientData(response.data.OPTO[0].OPTO_MESSAGE);
              console.log(response.data.OPTO[0].OPTO_MESSAGE);
              const responsedData = response.data.OPTO[0].OPTO_MESSAGE;
              setDiagnosisDetails(responsedData.diagnosis_details[0] || {});
              setPrescriptionDetails(responsedData.prescription_details || {});
              // toast.success("Clients retrieved successfully!", { position: "top-center" });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
              } else {
                toast.error(error.message, { position: "top-center" });
              }
            } else {
              toast.error("Network Error", { position: "top-center" });
            }
          });
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const handleExport = () => {
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(prescriptiondetails);

    // Create a workbook containing the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "PrescriptionDetails");

    // Generate an XLSX file and download it
    XLSX.writeFile(wb, "Prescription_details.xlsx");
  };
  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        API_BASE_URL + "DIAGNOSIS/deletePrescriptionHandler.php",
        formData
      );
      console.log("Document has been Deleted successfully", response.data);
      toast.success(
        "Document has been Deleted successfully",
        response.data["success"],
        { position: "top-center" }
      );
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };
  const columns = [
    {
      name: "Prescription Details",
      selector: (row) => row.prescription_details,
      sortable: true,
      width: "40%",
    },
    {
      name: "Course Duration",
      selector: (row) => row.course_time,
      width: "15%",
    },
    {
      name: "Follow up Date",
      selector: (row) => formatDate(row.followup_date),
      width: "15%",
    },
    {
      name: "Prescription Date",
      selector: (row) => formatDate(row.prescription_date),
      width: "15%",
    },

    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={"/Hompage/ViewDiagnosis"}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faDownload}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "60px",
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
          onClick={handleDelete}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "25px",
              height: "auto",
            }}
          />
        </button>
      ),
      width: "60px",
    },
  ];

  return (
    <div className="viewFormContainer row">
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading"> 
          {diagnosisDetails.patient_name}
          </div>
        </div>
        <Link to={"/home-page/searchdiagnosis"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      <form className="inputContent col-md-9">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-12  formGroup">
            <label htmlFor="clientName" className="">
              Client Name
            </label>
            <input
              type="text"
              id="tb_clientName"
              name="clientName"
              className="inputBox form-control  text-dark"
              value={diagnosisDetails.client_name || ""}
              readOnly
            />
          </div>
          <div className="col-md-6 formGroup">
            <label htmlFor="tb_age" className="">
              Age
            </label>
            <input
              type="text"
              id="tb_age"
              name="tb_age"
              className="inputBox form-control  text-dark"
              value={diagnosisDetails.age || ""}
              readOnly
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_gender" className="">
              Gender
            </label>
            <input
              type="text"
              id="tb_gender"
              name="tb_gender"
              className="inputBox form-control   text-dark"
              value={
                diagnosisDetails.gender === "M"
                  ? "Male"
                  : diagnosisDetails.gender === "F"
                    ? "Female"
                    : ""
              }
              readOnly
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_diagnosisNumber" className="">
              Diagnosis Number
            </label>
            <input
              type="text"
              id="tb_diagnosisNumber"
              name="tb_diagnosisNumber"
              className="inputBox form-control   text-dark"
              value={diagnosisDetails.client_name || ""}
              readOnly
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_diagnosisDate" className="">
              Diagnosis Date
            </label>
            <input
              type="text"
              id="tb_diagnosisDate"
              name="tb_diagnosisDate"
              className="inputBox form-control   text-dark"
              value={formatDate(diagnosisDetails.diagnosis_date) || "N/A"}
              readOnly
            />
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_healthIssue" className="">
              Health Issue
            </label>
            <textarea
              type="text"
              id="tb_healthIssue"
              name="tb_healthIssue"
              className="inputBox form-control text-dark tb_address"
              value={diagnosisDetails.health_issue || ""}
              cols="100"
              readOnly
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_vrre" className="">
              VRRE
            </label>
            <input
              type="text"
              id="tb_vrre"
              name="vrre"
              className="inputBox form-control   text-dark"
              value={diagnosisDetails.vrre || ""}
              readOnly
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_vrle" className="">
              VRLE
            </label>
            <input
              type="text"
              id="tb_vrle"
              name="tb_vrle"
              className="inputBox form-control   text-dark"
              value={diagnosisDetails.vrle || ""}
              readOnly
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_srre" className="">
              SRRE
            </label>
            <input
              type="text"
              id="tb_srre"
              name="srre"
              className="inputBox form-control   text-dark"
              value={diagnosisDetails.srre || ""}
              readOnly
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_srle" className="">
              SRLE
            </label>
            <input
              type="text"
              id="tb_srle"
              name="srle"
              className="inputBox form-control   text-dark"
              value={diagnosisDetails.srle || ""}
              readOnly
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_orre" className="">
              ORRE
            </label>
            <input
              type="text"
              id="tb_orre"
              name="orre"
              className="inputBox form-control   text-dark"
              value={diagnosisDetails.orre || ""}
              readOnly
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_orle" className="">
              ORLE
            </label>
            <input
              type="text"
              id="tb_orle"
              name="orle"
              className="inputBox form-control   text-dark"
              value={diagnosisDetails.orle || ""}
              readOnly
            />
          </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_cc" className="">
              Notes
            </label>
            <textarea
              type="text"
              id="tb_cc"
              name="tb_cc"
              className="inputBox form-control text-dark tb_address"
              value={diagnosisDetails.cc || ""}
              rows={5}
              cols="100"
              readOnly
            />
          </div>
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_doctorDaignosis" className="">
              Doctor Diagnosis
            </label>
            <textarea
              type="text"
              id="tb_doctorDaignosis"
              name="tb_doctorDaignosis"
              className="inputBox form-control text-dark tb_address"
              value={diagnosisDetails.doctor_diagnosis || ""}
              rows={5}
              cols="100"
              readOnly
            />
          </div>
        </div>
      </form>
      <div className="linkContainer col-3 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <p className="links" onClick={() => toggleAddDiagnosisPopup(diagnosisDetails)}>
          Add Diagnosis
        </p>
        <AddAnotherDiagnosisPopup
          id="AddDiagnosispopup"
          open={AddDiagnosisIsOpen}
          onClose={setmainIsClose}
          editClientMainData={editDiagnosisMainDetails}
        />
        <p className="links" onClick={() => toggleAddPrescriptionPopup({ ...prescriptiondetails, diagnosis_id: diagnosisDetails.diagnosis_id, doctor_name: diagnosisDetails.doctor_name ,doctor_id: diagnosisDetails.doctor_id, patient_id: diagnosisDetails.patient_id })}>
          Add Prescription
        </p>
        <AddPrescriptionPopup
          id="AddPrescriptionpopup"
          open={AddPrescriptionIsOpen}
          onClose={setprescriptionIsClose}
          editPescriptionMainData={editPrescriptionMainDetails}
        />
        {/* <Link
          to="/home-page/ViewPatient/"
          element={<ViewPatient />}
          className="links mb-2 "
        >
          View Patient Details
        </Link>
        <Link
          to="/home-page/ViewClient"
          element={<ViewClient />}
          className="links mb-2"
        >
          View Client Details
        </Link> */}
      </div>
      <hr />
      <div>
        <div className="formHeading">PRESCRIPTION TABLE</div>
        <CSVLink data={prescriptiondetails} filename="Prescription_details.csv">
          <button
            type="button"
            className="btnCTA btnFilter me-2 px-3"
            style={{
              width: "auto",
              borderRadius: "5px",
              height: "30px",
              fontSize: "14px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
              position: "relative",
              overflow: "hidden", // Make the button relative for absolute positioning of the triangle
            }}
          >

            CSV

          </button>
        </CSVLink>
        <button
          type="button"
          className="btnCTA btnAddSave me-2 px-3"
          onClick={handleExport}
          style={{
            width: "auto",
            borderRadius: "5px",
            height: "30px",
            fontSize: "14px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
          }}
        >
          {/* <FontAwesomeIcon
          className="actionIcon me-1"
          icon={faPlus}
          style={{
            color: "#ffffff",
            width: "12px",
            height: "auto",
          }}
        /> */}
          Excel
        </button>
        <DataTable
          className="resultData"
          columns={columns}
          data={prescriptiondetails}
          pagination
          responsive
          id="myTable"
          customStyles={tableStyles}
        ></DataTable>
      </div>
    </div>
  );
};

export default ViewDiagnosis;
