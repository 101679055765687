
import ViewPatient from '../HomePage/Masters/Patients/ViewPatient';
import Formtemplate from '../HomePage/Formtemplate';
import ClientSearch from '../HomePage/Masters/Client/ClientSearch';
import AddClient from '../HomePage/Masters/Client/AddClient';
import PatientSearch from '../HomePage/Masters/Patients/PatientSearch';
import SearchSuppliers from '../HomePage/Masters/Suppliers/SearchSuppliers';
import ViewSuppliers from '../HomePage/Masters/Suppliers/ViewSuppliers';
import AddSuppliers from '../HomePage/Masters/Suppliers/AddSuppliers';
import SearchStaff from '../HomePage/Masters/Staff/SearchStaff';
import AddStaff from '../HomePage/Masters/Staff/AddStaff';
import ViewStaff from '../HomePage/Masters/Staff/ViewStaff';
import SearchDiagnosis from '../HomePage/Diagnosis/SearchDiagnosis';
import AddDiagnosis from '../HomePage/Diagnosis/AddDiagnosis';
import ViewDiagnosis from '../HomePage/Diagnosis/ViewDiagnosis';
import ViewClient from '../HomePage/Masters/Client/ViewClient';
import AddPatient from '../HomePage/Masters/Patients/AddPatient';
import AddCompany from '../GodMode/AddCompany/AddCompany';
import Company from '../GodMode/Company/Company';
import View from '../GodMode/View/View';
import ViewMoreHistory from '../HomePage/Masters/Client/ViewMoreHistory';
import Appointments from '../HomePage/Appointments/Appointments';
import Dashboard from '../HomePage/Dashboard';
import Inventory from '../HomePage/Inventory/Inventory';
import SearchReceipts from '../HomePage/Finance/Receipts/SearchReceipts';
import ViewReceipts from '../HomePage/Finance/Receipts/ViewReceipts';
import MyProfile from '../HomePage/MyProfile';
import SearchVouchers from '../HomePage/Finance/Vouchers/SearchVouchers';
import AddVouchers from '../HomePage/Finance/Vouchers/AddVouchers';
import AddOrder from '../HomePage/Inventory/AddOrder';
import SearchLedgers from '../HomePage/Finance/Ledgers/SearchLedgers';
import AddLedgers from '../HomePage/Finance/Ledgers/AddLedgers';
import ViewLedgers from '../HomePage/Finance/Ledgers/ViewLedgers';
import AddDiagnosisReceipts from '../HomePage/Finance/Receipts/AddDiagnosisReceipts';
import AddProductReceipts from '../HomePage/Finance/Receipts/AddProductReceipts';





const GodpageRoutes = [
    { path: '/addcompany', name: 'addcompany', component: AddCompany },
    { path: '/company', name: 'company', component: Company },
    { path: '/addcompany', name: 'addcompany', component: AddCompany },
    // { path: '/View', name: 'View', component: View },
    { path: '/view/:companyId', name: 'view', component: View },
]

const Homepageroutes = [
    { path: 'viewpatient', name: 'viewpatient', component: ViewPatient },

    { path: 'viewpatient/:patientId', name: 'viewpatient', component: ViewPatient },
    { path: 'formtemplate', name: 'formtemplate', component: Formtemplate },
    { path: 'searchclient', name: 'searchclient', component: ClientSearch },
    { path: 'addclient', name: 'addclient', component: AddClient },
    { path: 'searchpatient', name: 'searchpatient', component: PatientSearch },
    { path: 'searchsuppliers', name: 'searchsuppliers', component: SearchSuppliers },

    { path: 'viewsuppliers', name: 'viewsuppliers', component: ViewSuppliers },

    { path: 'viewsuppliers/:supplierId', name: 'viewsuppliers', component: ViewSuppliers },
    { path: 'addsuppliers', name: 'addsuppliers', component: AddSuppliers },
    { path: 'searchstaff', name: 'searchstaff', component: SearchStaff },
    { path: 'addstaff', name: 'addstaff', component: AddStaff },
    
    { path: 'viewstaff', name: 'viewstaff', component: ViewStaff },

    { path: 'viewstaff/:staffId', name: 'viewstaff', component: ViewStaff },
    { path: 'searchdiagnosis', name: 'searchdiagnosis', component: SearchDiagnosis },
    { path: 'adddiagnosis', name: 'adddiagnosis', component: AddDiagnosis },
    { path: 'viewdiagnosis', name: 'viewdiagnosis', component: ViewDiagnosis },

    { path: 'viewdiagnosis/:diagnosisId', name: 'viewdiagnosis', component: ViewDiagnosis },

    { path: 'viewclient', name: 'viewclient', component: ViewClient },

    { path: 'viewmorehistory', name: 'viewmorehistory', component: ViewMoreHistory },
    { path: 'viewclient/:clientId', name: 'viewclient', component: ViewClient },
    { path: 'addpatient', name: 'addpatient', component: AddPatient },
    { path: 'appointments', name: 'appointments', component: Appointments },
    { path: 'dashboard', name: 'dashboard', component: Dashboard },
    { path: 'inventory', name: 'inventory', component: Inventory },
    { path: 'searchreceipts', name: 'searchreceipts', component: SearchReceipts },
    { path: 'adddiagnosisreceipts', name: 'adddiagnosisreceipts', component: AddDiagnosisReceipts },
    { path: 'addproductreceipts', name: 'addproductreceipts', component: AddProductReceipts },
    { path: 'viewreceipts', name: 'viewreceipts', component: ViewReceipts },
    { path: 'viewreceipts/:receiptId', name: 'viewreceipts', component: ViewReceipts },
    { path: 'myprofile', name: 'myprofile', component: MyProfile },
    { path: 'searchvouchers', name: 'searchvouchers', component: SearchVouchers },
    { path: 'addvouchers', name: 'addvouchers', component: AddVouchers },
    { path: 'addorder', name: 'addorder', component: AddOrder },
    { path: 'searchledgers', name: 'searchledgers', component: SearchLedgers },
    { path: 'addledgers', name: 'addledgers', component: AddLedgers },
    { path: 'viewledgers', name: 'viewledgers', component: ViewLedgers },
];




export { Homepageroutes, GodpageRoutes };

