import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


const AddPrescriptionPopup = ({ open, editPescriptionMainData, onClose }) => {
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_doctorName: "",
    tb_prescriptionDate: "",
    tb_followupDate: "",
    ta_prescriptionDetails: "",
    tb_diagnosisId: "",
    tb_patientId:"",
    tb_doctorId:"",
  });


  useEffect(() => {

    if (editPescriptionMainData) {
      setFormData({
        tb_doctorId: editPescriptionMainData.doctor_id,
        tb_doctorName: editPescriptionMainData.doctor_name,
        tb_diagnosisDate: editPescriptionMainData.diagnosis_date,
        tb_diagnosisId: editPescriptionMainData.diagnosis_id,
        tb_patientId : editPescriptionMainData.patient_id,
      });
    }
    console.log('Form With FormData : ', editPescriptionMainData);
  }, [editPescriptionMainData]);

  const handleChange = (e, value) => {
    if (value && value.format) {
      // This is the DatePicker case
      const formattedDate = value ? value.format('YYYY-MM-DD') : '';
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // This is the regular input case
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      axios({
        method: 'post',
        url: API_BASE_URL + 'DIAGNOSIS/addPrescriptionHandler.php',
        data: {
          tb_doctorName: formData.tb_doctorName,
          tb_prescriptionDate: formData.tb_prescriptionDate,
          tb_followupDate: formData.tb_followupDate,
          ta_prescriptionDetails: formData.ta_prescriptionDetails,
          tb_courseTime: formData.tb_courseTime,
          tb_diagnosisId: formData.tb_diagnosisId,
          tb_patientId:formData.tb_patientId,
          tb_doctorId:formData.tb_doctorId,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          toast.success("Prescriptions added successfully!", { position: "top-center" });
          onClose();
        }
      })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div>
        <Popup open={open} closeOnDocumentClick={false} onClose={onClose} >
          <div className="editPopup" style={{ height: "600px" }}>
            {/* Cross button to close the popup */}
            <button
              className="closePopup"
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
            <br />
            <div className="row ">
              <div className="col-md-12 formGroup">
                <label htmlFor="tb_doctorName" className="">
                  Doctor
                </label>
                <input
                  type="text"
                  id="tb_doctorName"
                  name="tb_doctorName"
                  className="inputBox form-control textarea  text-dark tb_address"
                  cols="100"
                  value={formData.tb_doctorName || ''}
                  onChange={handleChange}
                />
              </div>

              {/* Datepicker */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="col-6 formGroup">
                  <label htmlFor="tb_prescriptionDate" className="">
                    Prescription Date
                  </label>
                  <DatePicker
                    value={dayjs(formData.tb_prescriptionDate)}
                    className=""
                    id="tb_prescriptionDate"
                    maxDate={dayjs()}
                    // onChange={(newValue) =>
                    //   handleChange({ target: { name: "tb_dob" } }, newValue)
                    // } // Pass value to handleChange
                    onChange={(newValue) =>
                      handleChange({ target: { name: "tb_prescriptionDate" } }, newValue) // Pass value to handleChange
                    }
                    sx={{
                      width: "100%",
                      "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                      "& .MuiOutlinedInput-root": {
                        "&:hover > fieldset": {
                          border: "1px solid #b0b0b0 !important",
                        },
                        height: "35px",
                        border: "1px solid #b0b0b0 !important",
                        borderRadius: "0px",
                      },
                    }}
                    format="DD/MMM/YYYY"
                    renderInput={(params) => <input name="tb_prescriptionDate" {...params} />}
                  />
                </div>
                </LocalizationProvider>
                <div className="col-6 formGroup">
                  <label htmlFor="tb_followupDate" className="">
                    Followup Date
                  </label>
                  <DatePicker
                    value={dayjs(formData.tb_followupDate)}
                    className=""
                    id="tb_followupDate"
                    maxDate={dayjs()}
                    // onChange={(newValue) =>
                    //   handleChange({ target: { name: "tb_dob" } }, newValue)
                    // } // Pass value to handleChange
                    onChange={(newValue) =>
                      handleChange({ target: { name: "tb_followupDate" } }, newValue) // Pass value to handleChange
                    }
                    sx={{
                      width: "100%",
                      "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                      "& .MuiOutlinedInput-root": {
                        "&:hover > fieldset": {
                          border: "1px solid #b0b0b0 !important",
                        },
                        height: "35px",
                        border: "1px solid #b0b0b0 !important",
                        borderRadius: "0px",
                      },
                    }}
                    format="DD/MMM/YYYY"
                    renderInput={(params) => <input name="tb_followupDate" {...params} />}
                  />
                </div>
                <div className="col-md-12 formGroup">
                  <label htmlFor="ta_prescriptionDetails" className="">
                    Prescription Details
                  </label>
                  <textarea
                    type="text"
                    id="ta_prescriptionDetails"
                    name="ta_prescriptionDetails"
                    className="inputBox form-control textarea  text-dark tb_address"

                    cols="100"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12 formGroup">
                <label htmlFor="tb_courseTime" className="">
                 Course Time
                </label>
                <input
                  type="text"
                  id="tb_courseTime"
                  name="tb_courseTime"
                  className="inputBox form-control textarea  text-dark tb_address"
                  cols="100"
                  value={formData.tb_courseTime || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row btnGroup">
              <div className="col-md-6 ">
                <button
                  type="reset"
                  className="btnCTA btnReset"
                  onClick={() => window.location.reload()}
                >
                  Reset
                </button>
              </div>
              <div className="col-md-6 ">
                <button type="submit" className="btnCTA btnAddSave" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

export default AddPrescriptionPopup;
