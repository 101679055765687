import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faDownload } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditPatientDocumentDetails = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [formData, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     axios
  //       .get("https://jsonplaceholder.typicode.com/users")
  //       .then((res) => setData(res.data))
  //       .catch((err) => console.log(err));
  //   };
  //   fetchData();
  // }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/CLIENT/addClientDocumentHandler.php',
        // data: params,
        data: {
          'tb_documentName': formData.tb_documentName,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        API_BASE_URL + "MASTER/PATIENT/deletePatientDocumentHandler.php",
        formData,

      );
      console.log("Document has been Deleted successfully", response.data);
      toast.success("Document has been Deleted successfully", response.data['success'], { position: "top-center" });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Column 2",
      selector: (row) => row.address.street,
    },
    {
      name: "Column 3",
      selector: (row) => row.phone,
    },
    {
      name: "Download",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faDownload}
            style={{
              color: "#717171",
              width: "25px",
              height: "auto",
            }}
          />
        </button>
      ),
    }, {
      name: "Delete",
      cell: (row) => (
        <button
          type="submit"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
          onClick={handleDelete}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "25px",
              height: "auto",
            }}
          />
        </button>
      ),
    },
  ];

  // custom styles for result react-data-table-component
  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer />
      <Popup open={open} closeOnDocumentClick onClose={onClose}>
        <div className="editPopup" style={{ height: "600px" }}>
           {/* Cross button to close the popup */}
           <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
          <div className='row mt-1 popup'>
            <label htmlFor="formFile" className="">
              Document Name
            </label>
            <input
              className="form-control form-control-lg formControl"
              type="tb_documentName"
              id="tb_documentName"
              name='tb_documentName'
              placeholder='Enter document name'
              onChange={handleFileChange}
            />
          </div>
          <button className='btnCTA btnAddSave col-6' onClick={handleSubmit}>
            Save
          </button>
          <DataTable
            className="resultData"
            columns={columns}
            data={formData}
            pagination
            responsive
            id="myTable"
            customStyles={tableStyles}
          />
        </div>
      </Popup>
    </div>
  );
};

export default EditPatientDocumentDetails;
