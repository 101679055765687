import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../optoCustom.js";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Appointments = () => {
  const [formData, setFormData] = useState({
    tb_individualName: "",
    db_appointmentsType: "",
    db_doctorName: "",
    db_CompanyBranch: "",
    db_clientId: "",
    dp_appointmentDate: "",
    tp_appointmentTime: "",
    ta_description:"",
  });

  const [clientData, setClientData] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  useEffect(()=>{
    console.log(formData)
  }, [formData]);

  // Handle input change
  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
      // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // Handle regular inputs (like text, number, etc.)
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "/APPOINTMENT/addAppointmentHandler.php",
        data: {
            tb_individualName: formData.tb_individualName,
            db_appointmentsType: formData.db_appointmentsType,
            db_doctorName: formData.db_doctorName,
            db_CompanyBranch: formData.db_CompanyBranch,
            db_clientId: formData.db_clientId,
            dp_appointmentDate: formData.dp_appointmentDate,
            tp_appointmentTime: formData.tp_appointmentTime,
            ta_description: formData.ta_description,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setClientData(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else {
            toast.error("Network Error", { position: "top-center" });
          }
        });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  const handleReset = () => {
    setFormData({
      tb_individualName: "",
      tb_primaryNumber: "",
      tb_secondaryNumber: "",
      tb_emailAddress: "",
      tb_physicalAddress: "",
    });
    setClientData([]);
  };

  const columns = [
    {
      name: "Date Time",
      selector: (row) => row.client_name,
      sortable: true,
    },
    {
        name: "Individual Name",
        selector: (row) => row.secondary_number,
    },
    {
      name: "Description",
      selector: (row) => row.primary_number,
    },
    {
      name: "Appointment Type",
      selector: (row) => row.email_address,
    },
    {
      name: "Doctor",
      selector: (row) => row.physical_address,
    },
    {
        name: "Branch",
        selector: (row) => row.physical_address,
      },
    {
      name: "Cancel",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/home-page/viewclient/${row.client_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faTrash}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];


  const [doctorOptions, setDoctorOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchDoctorOptions = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "COMMON/getDoctorList.php", {
            withCredentials: true,
          }
        ); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.docotrId, // Adjust based on your API response structure
          label: option.doctorName, // Adjust based on your API response structure
        }));
        setDoctorOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDoctorOptions();
  }, []);

  //handles value change of single select and adds them to formData
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if(singleselectedOption == null){
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };


  const options_xxxx = [
    {
      value: "checkup",
      label: "Check Up",
    },
    {
      value: "pickup",
      label: "Pick Up",
    },
  ];

  const [clientOptions, setClientOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const clientOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getClientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setClientOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    clientOptions();
  }, []);

  const [companyBranchOptions, setCompanyBranchOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCompanyBranchOptions = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "COMMON/getCompanyBranchList.php", {
            withCredentials: true,
          }
        ) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setCompanyBranchOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCompanyBranchOptions();
  }, []);
  
  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
  };

  return (
   
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4 formGroup">
              <label htmlFor="tb_individualName" className="">
                Individual Name
              </label>
              <input
                type="text"
                id="tb_individualName"
                name="tb_individualName"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_individualName}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-4">
          <label htmlFor="singleSelect">Cilent Name</label>
          <Select
            options={clientOptions}
            key={'client_id'}
            placeholder="Select Client"
            name="tb_clientId"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #d2d2d2",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            onChange={handleSingleSelect}
          />
        </div>
        <div className="formGroup col-4">
              <label htmlFor="singleSelect">Doctor</label>
              <Select
                options={doctorOptions}
                placeholder="Select Doctor"
                id="singleSelect"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "40px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="col-md-6 formGroup">
            <label htmlFor="ta_description" className="">
              Description
            </label>
            <textarea
              type="text"
              id="ta_description"
              name="ta_description"
              className="inputBox form-control textarea  text-dark tb_address"
              rows="5"
              cols="10"
              onChange={handleChange}
            />
          </div>
            <div className="formGroup col-3">
          <label htmlFor="singleSelect" className="">Appointment Type</label>
          <Select
            options={options_xxxx}
            key={'client_id'}
            placeholder="Appointment Type"
            name="tb_clientId"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #d2d2d2",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            onChange={handleSingleSelect}
          />
           {/* Datetimepicker */}
           <LocalizationProvider dateAdapter={AdapterDayjs}>
           <div className="col-12 formGroup">
              <label htmlFor="dp_appointmentDate" className="">
                Date of Birth
              </label>
              <DatePicker
                value={dayjs(formData.dp_appointmentDate)}
                className=""
                id="dp_appointmentDate"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "dp_appointmentDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="dp_appointmentDate" {...params} />}
              />
            </div>
            </LocalizationProvider> 
        </div>
        <div className="formGroup col-md-3">
          <label htmlFor="singleSelect" className="">Company Branch</label>
          <Select
            options={companyBranchOptions}
            // key={'client_id'}
            placeholder="Company Branch"
            name="db_companyBranch"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #d2d2d2",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            onChange={handleSingleSelect}
          />
          {/* Timepicker */}
        <div className="formGroup1">
          <label htmlFor="timePicker" className="">
            Appointment Time
          </label>
          <input
            className="form-control formControl"
            type="time"
            id="timePicker"
            name="tp_appointmentTime"
            onChange={handleChange} 
          />
        </div>
        </div>
          </div>
          <div className="row btnGroup d-flex justify-content-center ">
            <div className="col-6">
                <button type="button" className=" btnCTA btnAddSave">
                  Save
                </button>
            </div>
          </div>
        </form>
      </div>

      {/* DataTable to display client data */}
      <DataTable
        className="resultData"
        columns={columns}
        data={clientData} // Data received from API
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      />
    </div>
  );
};

export default Appointments;
