import React from "react";
import Eye from "../../../../Assets/Eye.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const EditSuppliersBankDetails = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [branchDetails, setBranchDetails] = useState({
    db_bankName: "",
    tb_bankAccount: "",
    tb_branchName: "",
    tb_branchCode: "",
    tb_swift: "",
    tb_aba: "",
    tb_iBan: "",
    tb_sortCode: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBranchDetails({ ...branchDetails, [name]: value });
  };

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  const [bankNameOptions, setBankNameOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {

    // Fetch "Created by" options from API
    const fetchBankNameOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: API_BASE_URL + '/COMMON/getBankList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.bank_id, // Adjust based on your API response structure
          label: option.bank_name, // Adjust based on your API response structure
        }));
        setBankNameOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchBankNameOptions();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      // console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/SUPPLIER/editSupplierBankDetailsHandler.php',
        // data: params,
        data: {
          'db_bankName': formData.db_bankName,
          'tb_bankAccount': formData.tb_bankAccount,
          'tb_branchName': formData.tb_branchName,
          'tb_branchCode': formData.tb_branchCode,
          'tb_swift': formData.tb_swift,
          'tb_aba': formData.tb_aba,
          'tb_iBan': formData.tb_iBan,
          'tb_sortCode': formData.tb_sortCode,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          onClose();
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Column 2",
      selector: (row) => row.address.street,
    },
    {
      name: "Column 3",
      selector: (row) => row.phone,
    },
    {
      name: "Column 4",
      selector: (row) => row.website,
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <img src={Eye} className="inventory" alt="inventory" />
        </button>
      ),
    },
  ];

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer />
      <Popup open={open} closeOnDocumentClick onClose={onClose}>
        <div className="editPopup" style={{ height: "600px" }}>
          {/* Cross button to close the popup */}
          <button
            className="closePopup"
            onClick={onClose} // Close popup without reloading
            style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
          >
            &times;
          </button>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="row mt-1 popup">
              <div className="formGroup col-12">
                <label htmlFor="singleSelect">Bank Name</label>
                <Select
                  options={bankNameOptions}
                  placeholder="Select Bank Name"
                  name="db_BankName"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      border: "1px solid #d2d2d2",
                      borderRadius: "0px",
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0px",
                      fontSize: "14px",
                      maxHeight: "45px",
                    }),
                  }}
                  className=""
                  isSearchable
                  // isDisabled
                  onChange={handleSingleSelect}
                />
              </div>
              <div className="formGroup col-12 mt-1">
                <label htmlFor="tb_bankAccount" className="">
                  Bank Account
                </label>
                <input
                  type="text"
                  id="tb_bankAccount"
                  name="tb_bankAccount"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter bank account"
                  value={branchDetails.bankAccount}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_branchName" className="">
                  Branch Name
                </label>
                <input
                  type="text"
                  id="tb_branchName"
                  name="tb_branchName"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter branch name"
                  value={branchDetails.branchName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_branchCode" className="">
                  Branch Code
                </label>
                <input
                  type="text"
                  id="tb_branchCode"
                  name="tb_branchCode"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter branch code"
                  value={branchDetails.branchCode}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_swift" className="">
                  Swift Code
                </label>
                <input
                  type="text"
                  id="tb_swift"
                  name="tb_swift"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter swift"
                  value={branchDetails.swift}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-6 mt-1">
                <label htmlFor="tb_aba" className="">
                  ABA
                </label>
                <input
                  type="text"
                  id="tb_aba"
                  name="tb_aba"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter ABA"
                  value={branchDetails.aba}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-12 mt-1">
                <label htmlFor="tb_iBan" className="">
                  iBAN
                </label>
                <input
                  type="text"
                  id="tb_iBan"
                  name="tb_iBan"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter iBAN"
                  value={branchDetails.iBan}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formGroup col-md-12">
                <label htmlFor="tb_sortCode" className="">
                  Sort Code
                </label>
                <input
                  type="text"
                  id="tb_sortCode"
                  name="tb_sortCode"
                  className="inputBox form-control formControl text-dark"
                  placeholder="Enter sort code"
                  value={branchDetails.sortCode}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row btnGroup">
              <div className="col-md-6 ">
                <button
                  type="reset"
                  className="btnCTA btnReset"
                  onClick={() => window.location.reload()}
                >
                  Reset
                </button>
              </div>
              <div className="col-md-6 ">
                <button type="submit" className="btnCTA btnAddSave" onClick={handleSubmit} onClose={onClose}>
                  Save
                </button>
              </div>
            </div>
          </form>
          <DataTable
            className="resultData"
            columns={columns}
            data={formData}
            pagination
            responsive
            id="myTable"
            customStyles={tableStyles}
          />
        </div>
      </Popup>
    </div>
  );
};

export default EditSuppliersBankDetails;
