import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_BASE_URL } from "../../../optoCustom";
import { ToastContainer, toast } from "react-toastify";
import { faTrashCan, faPlus, faLock } from "@fortawesome/free-solid-svg-icons";

function AddOrder() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    db_supplierName: "",
    db_stockType: "",
    db_stockSecondaryType: "",
    db_brand: "",
    tb_discount: "0",
    tb_tax: "0",
    tb_netAmount: "0",
  });
  const [supplierByOptions, setSupplierOptions] = useState([]);
  const [readOnly, setIsReadOnly] = useState(false);
  const [orderDetails, setorderDetails] = useState([
    {
      db_itemName: "",
      tb_metalCode: "",
      db_color: "",
      tb_pricePerPiece: "",
      tb_quantity: "",
      tb_grossAmount: "",
    },
  ]);

  const handleRowInputChange = (index, field, value) => {
    const updatedData = [...orderDetails];
    updatedData[index][field] = value;
    // console.log(value);
    setorderDetails(updatedData);

    if (field === "tb_pricePerPiece" || field === "tb_quantity") {
      const pricePerPiece = updatedData[index]["tb_pricePerPiece"] || 0;
      const quantity = updatedData[index]["tb_quantity"] || 0;
      updatedData[index]["tb_grossAmount"] = (
        pricePerPiece * quantity
      ).toString();
    }

    setorderDetails(updatedData);
  };

  useEffect(() => {
    console.log(formData);
    console.log(orderDetails);
  }, [formData, orderDetails]);

  useEffect(() => {
    setNetTax();
  }, [formData.tb_discount]);

  const handleAddNewRow = () => {
    setorderDetails([
      ...orderDetails,
      {
        db_itemName: "",
        tb_metalCode: "",
        db_color: "",
        tb_pricePerPiece: "",
        tb_quantity: "",
        tb_grossAmount: "",
      },
    ]);
  };

  const setNetTax = () => {
    let totalGrossAmount = 0;
    const discount = parseInt(formData.tb_discount) || 0;
    let taxAmount = 0;
    let netAmount = 0;

    orderDetails.forEach((row) => {
      totalGrossAmount += parseInt(row.tb_grossAmount);
    });

    taxAmount = (totalGrossAmount - discount) * 0.18;
    netAmount = totalGrossAmount - discount + taxAmount;

    if (isNaN(taxAmount) || isNaN(netAmount)) {
      console.log("not a number");
      setFormData({
        ...formData,
        tb_tax: "0",
        tb_netAmount: "0",
      });
    } else {
      setFormData({
        ...formData,
        tb_tax: taxAmount.toString(),
        tb_netAmount: netAmount.toString(),
      });
    }
  };

  const handleLockAndCalculate = () => {
    if (
      orderDetails.some((orderRow) =>
        Object.values(orderRow).some(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === 0 ||
            value === "0"
        )
      )
    ) {
      console.log("tried");
      toast.error(
        "Some fields in the order summary are empty. Unable to Lock Table"
      );
    } 
    else if (readOnly) {
      toast.info("Table already locked");
    }
    else {
      const confirmation = window.confirm(
        "Are you sure you want to lock the table?"
      );
      if (confirmation) {
        setNetTax();
        setIsReadOnly(true); 
        
        // Assuming you have a state variable to control read-only behavior
      }
    }
  };

  const columns = [
    {
      name: "Item Name",
      selector: (row, index) => (
        <textarea
          type="text"
          rows={3}
          readOnly={readOnly}
          className="form-control"
          name="tb_itemName"
          value={row.db_itemName}
          onChange={(e) =>
            handleRowInputChange(index, "db_itemName", e.target.value)
          }
          style={{
            fontSize: "14px",
            width: "250px",
            padding: "4px",
            margin: "0",
          }}
        />
      ),
      sortable: true,
      width: "290px",
    },
    {
      name: "Metal Code",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          readOnly={readOnly}
          value={row.tb_metalCode}
          name="tb_metalCode"
          onChange={(e) =>
            handleRowInputChange(index, "tb_metalCode", e.target.value)
          }
          style={{ fontSize: "14px" }}
        />
      ),
    },
    {
      name: "Color",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          readOnly={readOnly}
          value={row.db_color}
          name="db_color"
          onChange={(e) =>
            handleRowInputChange(index, "db_color", e.target.value)
          }
          style={{ fontSize: "14px" }}
        />
      ),
    },
    {
      name: "Price per piece",
      selector: (row, index) => (
        <input
          type="number"
          className="form-control text-right"
          readOnly={readOnly}
          value={row.tb_pricePerPiece}
          name="tb_pricePerPiece"
          onChange={(e) =>
            handleRowInputChange(index, "tb_pricePerPiece", e.target.value)
          }
          style={{ fontSize: "14px", textAlign: "right" }}
        />
      ),
    },
    {
      name: "Quantity",
      selector: (row, index) => (
        <input
          type="number"
          className="form-control"
          readOnly={readOnly}
          value={row.tb_quantity}
          name="tb_quantity"
          onChange={(e) =>
            handleRowInputChange(index, "tb_quantity", e.target.value)
          }
          style={{ fontSize: "14px", textAlign: "right" }}
        />
      ),
    },
    {
      name: "Gross Amount",
      selector: (row, index) => (
        <input
          type="number"
          name="tb_grossAmount"
          className="form-control"
          value={row.tb_pricePerPiece * row.tb_quantity}
          onChange={(e) =>
            handleRowInputChange(index, "tb_grossAmount", e.target.value)
          }
          readOnly
          style={{ fontSize: "14px", textAlign: "right" }}
        />
      ),
    },
    {
      name: "",
      cell: (row, index) => (
        <button
          type="button"
          className="btnDELETE"
          style={{ border: "none", background: "none" }}
          onClick={() => handleDeleteRow(index)}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "16px",
              height: "auto",
            }}
          />
        </button>
      ),
      width: "50px",
    },
  ];

  // Delete row handler
  const handleDeleteRow = (index) => {
    if (orderDetails.length === 1) {
      toast.error("Order Cannot be empty");
    } else {
      const updatedData = [...orderDetails];
      updatedData.splice(index, 1);
      setorderDetails(updatedData);
    }
  };

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchSupplierNameOptions = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "COMMON/getSupplierList.php", {
            withCredentials: true,
          }
        ); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.supplier_id, // Adjust based on your API response structure
          label: option.supplier_name, // Adjust based on your API response structure
        }));
        setSupplierOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSupplierNameOptions();
  }, []);

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!readOnly) {
      console.log("tried");
      toast.error(
        "Please Lock the Table first In order to Proceed Further"
      );
    } else {
      try {
        console.log(formData.tb_clientName);
        axios({
          method: "post",
          url: API_BASE_URL + "INVENTORY/addInventoryHandler.php",
          // data: params,
          data: {
            db_supplierName: formData.db_supplierName,
            db_stockType: formData.db_stockType,
            db_stockSecondaryType: formData.db_stockSecondaryType,
            db_brand: formData.db_brand,
            tb_discount: formData.tb_discount,
            tb_tax: formData.tb_tax,
            tb_netAmount: formData.tb_netAmount,
            order_summary: orderDetails,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        })
          .then(function (response) {
            console.log(response);
            if (response.status == "200") {
              console.log(response);
              toast.success(response.data["success"], {
                position: "top-center",
              });
              navigate(`/home-page/viewclient/${response.data.OPTO[0].OPTO_ID}`);
            }
            console.log(response.status);
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status === "401") {
                console.error("Error:", error);
                toast.error(
                  error.response.data.OPTO[0].OPTO_CODE +
                    error.response.data.OPTO[0].OPTO_MESSAGE,
                  { position: "top-center" }
                );
              } else {
                toast.error(error.message, { position: "top-center" });
              }
            } else if (error.request) {
              console.error("Network Error:", error.request);
              toast.error("Network Error", error.request, {
                position: "top-center",
              });
            } else {
              console.log("Error:", error.message);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleReset = () => {
    setFormData({
      db_supplierName: "",
      db_stockType: "",
      db_stockSecondaryType: "",
      db_brand: "",
      tb_discount: "0",
      tb_tax: "0",
      tb_netAmount: "0",
    });
    setorderDetails([
      {
        db_itemName: "",
        tb_metalCode: "",
        db_color: "",
        tb_pricePerPiece: "",
        tb_quantity: "",
        tb_grossAmount: "",
      },
    ]);
    setIsReadOnly(false);
  };
  return (
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="formGroup col-md-12">
              <label htmlFor="singleSelect">Supplier Name</label>
              <Select
                options={supplierByOptions}
                key={"supplier_name"}
                placeholder="Select Supplier Name"
                name="db_supplierName"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-4">
              <label htmlFor="singleSelect">Stock Type</label>
              <Select
                options={supplierByOptions}
                key={"supplier_name"}
                placeholder="Select Type"
                name="db_stockType"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-4">
              <label htmlFor="singleSelect">Stock Secondary Type</label>
              <Select
                options={supplierByOptions}
                key={"supplier_name"}
                placeholder="Select Stock Secondary Type"
                name="db_stockType"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>

            <div className="formGroup col-md-4">
              <label htmlFor="singleSelect">Brand</label>
              <Select
                options={supplierByOptions}
                key={"supplier_name"}
                placeholder="Select Brand"
                name="db_stockType"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
          </div>

          <hr />
          <div className="d-flex flex-row align-items-end justify-content-between">
            <div className="formSubHeading">Order Summary</div>
            <div className="btnRow" style={{ right: "true" }}>
              <button
                type="button"
                className="btnCTA btnAddSave ms-2 px-3"
                onClick={handleLockAndCalculate}
                style={{
                  width: "auto",
                  borderRadius: "5px",
                  height: "30px",
                  fontSize: "14px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
                }}
              >
                <FontAwesomeIcon
                  className="actionIcon me-1"
                  icon={faLock}
                  style={{
                    color: "#ffffff",
                    width: "12px",
                    height: "auto",
                  }}
                />
                Lock and Calculate
              </button>
              <button
                type="button"
                className="btnCTA btnFilter ms-2 px-3"
                onClick={handleAddNewRow}
                style={{
                  width: "auto",
                  borderRadius: "5px",
                  height: "30px",
                  fontSize: "14px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
                }}
              >
                <FontAwesomeIcon
                  className="actionIcon me-1"
                  icon={faPlus}
                  style={{
                    color: "#ffffff",
                    width: "12px",
                    height: "auto",
                  }}
                />
                Add Row
              </button>
            </div>
          </div>
          <div className="mb-3">
            <DataTable
              className="resultData"
              columns={columns}
              data={orderDetails}
              pagination
              responsive
              id="myTable"
              customStyles={tableStyles}
              paginationPerPage={30}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
            />
          </div>
          <div className="row">
            <div className="col-md-4  formGroup">
              <label htmlFor="tb_discount" className="">
                Discount
              </label>
              <input
                type="text"
                id="tb_discount"
                name="tb_discount"
                value={formData.tb_discount || 0}
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4  formGroup">
              <label htmlFor="tb_tax" className="">
                Tax ( 18% )
              </label>
              <input
                type="text"
                id="tb_tax"
                name="tb_tax"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_tax}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4  formGroup">
              <label htmlFor="tb_netAmount" className="">
                Net Amount
              </label>
              <input
                type="text"
                id="tb_netAmount"
                name="tb_netAmount"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_netAmount}
                // onChange={handleChange}
                readOnly
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <Link to={"/home-page/inventory"}>
                <button type="button" className="btnCTA btnCancel">
                  Cancel
                </button>
              </Link>
            </div>
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA  btnAddSave">
                Save Order
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddOrder;
