import Login from '../IAMS/Login/Login';
import ForgotCredientials from '../IAMS/ForgotCredientials/ForgotCredientials';
import Acknowledgement from '../IAMS/acknowledgement/acknowledgement';
import AccountBlocked from '../IAMS/AccountBlocked';
import SetPassword from '../IAMS/SetPassword/SetPassword';
import HomePage from '../HomePage/HomePage';
import GodPage from '../GodMode/GodPage/GodPage';

const rootRoutes = [
    { path: '/', name: 'Login', component: Login },
    { path: '/forgotcredientials', name: 'forgotcredientials', component: ForgotCredientials },
    { path: '/acknowledgement', name: 'acknowledgement', component: Acknowledgement },
    { path: '/accountblocked', name: 'accountBlocked', component: AccountBlocked },
    { path: '/home-page/*', name: 'homepage', component: HomePage },
    { path: '/god-page/*', name: 'godPage', component: GodPage },
    { path: '/setpassword', name: 'setpassword', component: SetPassword },
];

export default rootRoutes;