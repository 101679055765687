import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
// import { styled } from "@mui/material/styles";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Select from "react-select";

const AddLedgers = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_ledgerCode: "",
    tb_ledgerName: "",
    tb_ledgerDescription: "",
    
  });


  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // // Datetimepicker

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/CLIENT/addClientHandler.php",
        // data: params,
        data: {
            tb_ledgerCode: formData.tb_ledgerCode,
            tb_ledgerName: formData.tb_ledgerName,
            tb_ledgerDescription: formData.tb_ledgerDescription,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then(function (response) {
          console.log(response);
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/home-page/ViewClient/${response.data.OPTO[0].OPTO_ID}`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              console.error("Error:", error);
              toast.error(
                error.response.data.OPTO[0].OPTO_CODE +
                  error.response.data.OPTO[0].OPTO_MESSAGE,
                { position: "top-center" }
              );
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_ledgerCode" className="">
              Ledger Code
            </label>
            <input
              type="text"
              id="tb_ledgerCode"
              name="tb_ledgerCode"
              className="inputBox form-control formControl  text-dark"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_ledgerName" className="">
              Ledger Name
            </label>
            <input
              type="text"
              id="tb_ledgerName"
              name="tb_ledgerName"
              className="inputBox form-control formControl  text-dark"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_ledgerDescription" className="">
             Ledger Description
            </label>
            <textarea
              type="text"
              id="tb_ledgerDescription"
              name="tb_ledgerDescription"
              className="inputBox form-control textarea text-dark tb_address"
              cols="100"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <Link to={"/home-page/searchledgers"} className="col-md-4">
            <button type="submit" className="btnCTA btnCancel">
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddLedgers;
