import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_BASE_URL } from "../../../../optoCustom";
import { ToastContainer, toast } from "react-toastify";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function AddDiagnosisReceipts() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    db_currencyName: "",
    db_modeOfPayment: "",
    tb_paymentDate: "",
    tb_ourBankAccount: "",
    tb_discount: "",
    tb_tax: "",
    tb_netAmount: "0",
    tb_grossAmount: "0",
    db_clientId: "",
    db_patientId: "",
    db_currencyId: "",
    db_modeOfPayment: "",
    tb_paymentDate: "",
    tb_transactionId: "",
    db_ourBankAccount: "",
    tb_totalDiscount: "",
    tb_totalTax: "0",
    tb_totalNetAmount: "0",
    tb_totalGrossAmount: "0",
  });
  const [clientList, setClientList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [ourBankAccountsList, setOurBankAccountsList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [modeOfPaymentList, setmodeOfPaymentList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);


  const [diagnosisTable, setdiagnosisTable] = useState([
    {
      gross_amount: "",
      discount: "",
      net_amount: "",
      tax_amount: "",
    },
  ]);

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log("Selected Rows:", selectedRows);
  };




  useEffect(() => {
    console.log(formData);
    console.log(diagnosisTable);
  }, [formData, diagnosisTable]);


  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (hasFetchedData.current) return;
    // Mark that the API has been called
    hasFetchedData.current = true;
    setNetTax();
  }, [formData.tb_discount]);

  const handleFetchDiagnosis = async () => {

    try {

      const response = await axios({
        method: "post",
        url: API_BASE_URL + "FINANCE/RECEIPTS/fetchDiagnosisReceiptListHandler.php",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
        data: {
          db_clientId: formData.db_clientId,
          db_patientId: formData.db_patientId,
        },

      });

      if (
        response.status === 200 &&
        response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
      ) {

        // Extract the diagnosis_list and update state
        const diagnosisList = response.data.OPTO[0].OPTO_MESSAGE.diagnosis_list;
        setdiagnosisTable(diagnosisList);
        toast.success("Diagnosis data fetched successfully!", {
          position: "top-center",

        });
      }

    } catch (error) {
      if (error.response && error.response.data && error.response.data.OPTO) {
        const opto = error.response.data.OPTO;
        toast.error(`${opto[0].OPTO_CODE}: ${opto[0].OPTO_MESSAGE}`, {

          position: "top-center",

        });

      } else {

        console.error("Error:", error);

      }

    }

  };
  const setNetTax = () => {
    const totals = selectedRows.reduce(
      (acc, row) => {
        acc.tb_grossAmount += parseFloat(row.gross_amount || 0);
        acc.tb_discount += parseFloat(row.discount || 0);
        acc.tb_netAmount += parseFloat(row.net_amount || 0);
        acc.tb_tax += parseFloat(row.tax_amount || 0);
        return acc;
      },
      {
        tb_grossAmount: 0,
        tb_discount: 0,
        tb_netAmount: 0,
        tb_tax: 0,
      }
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      tb_grossAmount: totals.tb_grossAmount.toString(),
      tb_discount: totals.tb_discount.toString(),
      tb_netAmount: totals.tb_netAmount.toString(),
      tb_tax: totals.tb_tax.toString(),
    }));

    console.log("Updated FormData with Totals:", {
      tb_grossAmount: totals.tb_grossAmount,
      tb_discount: totals.tb_discount,
      tb_netAmount: totals.tb_netAmount,
      tb_tax: totals.tb_tax,
    });
  };

  const handleLockAndCalculate = () => {
    if (
      diagnosisTable.some((orderRow) =>
        Object.values(orderRow).some(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === 0 ||
            value === "0"
        )
      )
    ) {
      console.log("tried");
      toast.error(
        "Some fields in the order summary are empty. Unable to Lock Table"
      );
    } else {
      setNetTax();
    }
  };

  const columns = [
    {
      name: "Gross Amount",
      selector: (row) => row.gross_amount,
      sortable: true,
    },
    {
      name: "Tax Amount",
      selector: (row) => row.tax_amount,
      sortable: true,
    },
    {
      name: "Net Amount",
      selector: (row) => row.net_amount,
      sortable: true,
    },
  ];



  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  const fetchCommonLists = async () => {
    try {
      const clientListResponse = await axios({
        method: 'get',
        url: API_BASE_URL + 'COMMON/getClientList.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      })


      const patientListResponse = await axios({
        method: 'get',
        url: API_BASE_URL + 'COMMON/getPatientList.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      });

      const ourBankAccountsResponse = await axios({
        method: 'get',
        url: API_BASE_URL + 'COMMON/getOurBankAccountList.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      });

      const currencyListResponse = await axios({
        method: 'get',
        url: API_BASE_URL + 'COMMON/getCurrencyList.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      });
      const modeOfPaymentListResponse = await axios({
        method: 'get',
        url: API_BASE_URL + 'COMMON/getModeOfPaymentList.php',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      });

      const clientListOptions = clientListResponse.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
        value: option.client_id,
        label: option.client_name,
      }));

      const patientListOptions = patientListResponse.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
        value: option.patient_id,
        label: option.patient_name,
      }));

      const ourBankAccountsOptions = ourBankAccountsResponse.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
        value: option.account_id,
        label: option.account_number,
      }));

      const currencyListOptions = currencyListResponse.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
        value: option.currency_id,
        label: option.currency_name,
      }));
      const modeOfPaymentListOptions = modeOfPaymentListResponse.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
        value: option.mode_of_payment_id,
        label: option.mode_of_payment_name,
      }));

      setClientList(clientListOptions);
      setPatientList(patientListOptions);
      setOurBankAccountsList(ourBankAccountsOptions);
      setCurrencyList(currencyListOptions);
      setmodeOfPaymentList(modeOfPaymentListOptions);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCommonLists();

  }, []);

  // useEffect(()=>{
  // },[clientList])



  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
      // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // Handle regular inputs (like text, number, etc.)
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
  };

  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      diagnosisTable.some((orderRow) =>
        Object.values(orderRow).some(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === 0 ||
            value === "0"
        )
      )
    ) {
      console.log("tried");
      toast.error(
        "Some fields in the order summary are empty. Unable to save order"
      );
    } else {
      try {
        axios({
          method: "post",
          url: API_BASE_URL + "FINANCE/RECEIPTS/addDiagnosisReceiptDetailsHandler.php",
          // data: params,
          data: {
            db_clientId: formData.db_clientId,
            db_patientId: formData.db_patientId,
            db_currencyName: formData.db_currencyName,
            db_modeOfPayment: formData.db_modeOfPayment,
            tb_paymentDate: formData.tb_paymentDate,
            tb_transactionId: formData.tb_transactionId,
            tb_ourBankAccount: formData.tb_ourBankAccount,
            tb_discount: formData.tb_discount,
            tb_tax: formData.tb_tax,
            tb_netAmount: formData.tb_netAmount,
            tb_grossAmount:formData.tb_grossAmount,
            order_summary: diagnosisTable,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
            ) {
              // Extract the OPTO_MESSAGE array and set it to the state
              setFormData(response.data.OPTO[0].OPTO_MESSAGE);
              console.log(response.data.OPTO[0].OPTO_MESSAGE);
              toast.success("Clients retrieved successfully!", { position: "top-center" });
              navigate(response.data.OPTO[0].OPTO_LINK);
            }
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.OPTO) {
              // Extract the "OPTO" array
              const opto = error.response.data.OPTO;
              toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleReset = () => {
    setFormData({
      db_clientId: "",
      db_patientId: "",
      db_currencyName: "",
      db_modeOfPayment: "",
      tb_discount: "0",
      tb_tax: "0",
      tb_netAmount: "0",
    });
    setdiagnosisTable([
      {
        gross_amount: "",
        discount: "",
        net_amount: "",
        tax_amount: "",
      },
    ]);
  };
  return (
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="formGroup col-12">
              <label htmlFor="db_clientId">Client Name</label>
              <Select
                options={clientList}
                key={'client_id'}
                placeholder="Select client name"
                name="db_clientId"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-12">
              <label htmlFor="db_patientId">Patient Name</label>
              <Select
                options={patientList}
                key={"patient_id"}
                placeholder="Select Patient Name"
                name="db_patientId"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-4">
              <label htmlFor="db_currencyName">Currency Name</label>
              <Select
                options={currencyList}
                // defaultValue={{ value: "1|TZS", label: "Tanzanian Shilling" }}
                key={"currency_id"}
                placeholder="Select Currency Name"
                name="db_currencyName"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-4">
              <label htmlFor="db_modeOfPayment">Mode Of Payment</label>
              <Select
                options={modeOfPaymentList}
                key={"supplier_name"}
                placeholder="Select Mode of Payment"
                name="db_modeOfPayment"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="col-4 formGroup">
              <label htmlFor="tb_paymentDate" className="">
                Payment Date
              </label>
              <DatePicker
                value={dayjs(formData.tb_paymentDate)}
                className=""
                id="tb_paymentDate"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_paymentDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_paymentDate" {...params} />}
              />
            </div>
            </LocalizationProvider>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_transactionId" className="">
                Transaction ID
              </label>
              <input
                type="text"
                id="tb_transactionId"
                name="tb_transactionId"
                className="inputBox form-control formControl  text-dark"
                placeholder="Enter transaction id"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-md-6">
              <label htmlFor="tb_ourBankAccount">Our Bank Account</label>
              <Select
                options={ourBankAccountsList}
                key={"account_id"}
                placeholder="Select Bank Account"
                name="tb_ourBankAccount"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
          </div>

          <hr />
          <div className="d-flex flex-row align-items-end justify-content-between">
            <div className="formSubHeading">Order Summary</div>
            <div className="btnRow d-flex justify-content-center" style={{ position: "relative" }} >
              <button
                type="button"
                className="btnCTA btnFilter ms-2 px-3"
                onClick={handleFetchDiagnosis}
                style={{
                  width: "auto",
                  borderRadius: "10px",
                  height: "30px",
                  fontSize: "14px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                }}
              >
                Fetch Diagnosis
              </button>
            </div>
          </div>
          <div className="mb-3">
            <DataTable
              className="resultData"
              columns={columns}
              data={diagnosisTable}
              responsive
              id="myTable"
              customStyles={tableStyles}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
            />
          </div>
          <div className="btnRow d-flex justify-content-center " style={{ position: "relative" }}>
            <button
              type="button"
              className="btnCTA btnAddSave ms-2 px-3"
              onClick={handleLockAndCalculate}
              style={{
                width: "auto",
                borderRadius: "10px",
                height: "30px",
                fontSize: "14px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
              }}
            >
              <FontAwesomeIcon
                className="actionIcon me-1"
                icon={faLock}
                style={{
                  color: "#ffffff",
                  width: "12px",
                  height: "auto",
                }}
              />
              Lock and Calculate
            </button>
          </div>
          <br />
          <div className="row">
            <div className="col-md-3  formGroup">
              <label htmlFor="tb_discount" className="">
                Total Discount
              </label>
              <input
                type="text"
                id="tb_discount"
                name="tb_discount"
                value={formData.tb_discount}
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-3  formGroup">
              <label htmlFor="tb_tax" className="">
                Total Tax
              </label>
              <input
                type="text"
                id="tb_tax"
                name="tb_tax"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_tax}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-3  formGroup">
              <label htmlFor="tb_netAmount" className="">
                Total Net Amount
              </label>
              <input
                type="text"
                id="tb_netAmount"
                name="tb_netAmount"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_netAmount}
                // onChange={handleChange}
                readOnly
              />
            </div>
            <div className="col-md-3  formGroup">
              <label htmlFor="tb_grossAmount" className="">
                Total Gross Amount
              </label>
              <input
                type="text"
                id="tb_grossAmount"
                name="tb_grossAmount"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_grossAmount}
                // onChange={handleChange}
                readOnly
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <Link to={"/home-page/searchreceipts"}>
                <button type="button" className="btnCTA btnCancel">
                  Cancel
                </button>
              </Link>
            </div>
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA  btnAddSave">
                Save Receipt
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddDiagnosisReceipts;
