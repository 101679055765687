import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
const AddSuppliers = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_supplier: "",
    tb_shortName: "",
    tb_supplierType: "Local",
    tb_tin: "",
    tb_vrn: "",
    tb_physicalAddress: "",
    tb_poBox: "",
    tb_city: "",
    tb_country: "Tanzania",
    tb_primaryNumber: "",
    tb_secondaryNumber: "",
    tb_emailAddress: "",
    tb_contactName: "",
    tb_designation: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  // Single select
  const [singleselectedOption, setsingleSelectedOption] = useState("");

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;

    // Reset the field if no option is selected
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
        country: "", // Reset the country field
        disableCountry: true, // Disable the country input
      });
    } else {
      if (singleselectedOption.value.toLowerCase() === "local") {
        setFormData({
          ...formData,
          [name]: singleselectedOption.value,
          country: "Tanzania", // Auto-fill the country to Tanzania
          disableCountry: true, // Disable the country input
        });
      } else if (singleselectedOption.value.toLowerCase() === "foreign") {
        setFormData({
          ...formData,
          [name]: singleselectedOption.value,
          country: "", // Clear the country field
          disableCountry: false, // Enable the country input
        });
      }
    }
  };
  const [designationOptions, setdesignationOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchDesignationOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: API_BASE_URL + "/COMMON/getDesignationList.php",
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        });// Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.designation_id, // Adjust based on your API response structure
          label: option.designation_name, // Adjust based on your API response structure
        }));
        setdesignationOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDesignationOptions();
  }, []);
  const supplierOptions = [
    {
      value: "Local",
      label: "Local",
    },
    {
      value: "Foreign",
      label: "Foreign",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      console.log(formData.tb_country);
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/SUPPLIER/addSupplierHandler.php",
        // data: params,
        data: {
          tb_supplier: formData.tb_supplier,
          tb_shortName: formData.tb_shortName,
          tb_supplierType: formData.tb_supplierType,
          tb_tin: formData.tb_tin,
          tb_vrn: formData.tb_vrn,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_primaryNumber: formData.tb_primaryNumber,
          tb_emailAddress: formData.tb_emailAddress,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_contactName: formData.tb_contactName,
          tb_poBox: formData.tb_poBox,
          tb_city: formData.tb_city,
          tb_country: formData.tb_country,
          tb_designation: formData.tb_designation,
          tb_supplierId: formData.supplier_id,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setFormData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
            navigate(response.data.OPTO[0].OPTO_LINK)
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}

      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row mt-1">
          <div className="formGroup col-md-12 mt-1">
            <label htmlFor="tb_supplier" className="">
              Supplier Name
            </label>
            <input
              type="text"
              id="tb_supplier"
              name="tb_supplier"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter supplier name"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_shortName" className="">
              Short Name
            </label>
            <input
              type="text"
              id="tb_shortName"
              name="tb_shortName"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter supplier short name"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="tb_supplierType" className="">
              Supplier Type
            </label>
            <Select
              options={supplierOptions}
              placeholder="Select supplier type"
              id="tb_supplierType"
              name="tb_supplierType"
              defaultValue={{ value: "Local", label: "Local" }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable={false}
              // isDisabled
              // value={singleselectedOption?.value}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-md-12">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              placeholder="Enter physical address"
              cols="100"
              rows={5}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_poBox" className="">
              Post Box
            </label>
            <input
              type="text"
              id="tb_poBox"
              name="tb_poBox"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter post box"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_city" className="">
              City
            </label>
            <input
              type="text"
              id="tb_city"
              name="tb_city"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter city"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_country" className="">
              Country
            </label>
            <input
              type="text"
              id="tb_country"
              name="tb_country"
              className="inputBox form-control formControl text-dark"
              placeholder="Enter country"
              value={formData.tb_country || ""} // Bind the value to formData.country
              onChange={handleChange}
              disabled={formData.disableCountry || false} // Use a flag to manage disabled state
            />
          </div>
          <div className="formGroup col-md-12">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="text"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter email address"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_contactName" className="">
              Contact Person
            </label>
            <input
              type="text"
              id="tb_contactName"
              name="tb_contactName"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter contact person"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_designation" className="">
              Designation
            </label>
            <input
              type="text"
              id="tb_designation"
              name="tb_designation"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter contact person"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_primaryNumber" className="">
              Mobile Number
            </label>
            <input
              type="text"
              id="tb_primaryNumber"
              name="tb_primaryNumber"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter mobile number"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_secondaryNumber" className="">
              Telephone Number
            </label>
            <input
              type="text"
              id="tb_secondaryNumber"
              name="tb_secondaryNumber"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter telephone number"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_tin" className="">
              TIN
            </label>
            <input
              type="text"
              id="tb_tin"
              name="tb_tin"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter tin"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_vrn" className="">
              VRN
            </label>
            <input
              type="text"
              id="tb_vrn"
              name="tb_vrn"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter vrn"
              onChange={handleChange}
            />
          </div>
        </div>
        <br />
        <div className="row btnGroup">
          <Link to={"/home-page/searchsuppliers"} className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSuppliers;
