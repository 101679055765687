import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const ClientSearch = () => {
  const [formData, setFormData] = useState({
    tb_clientName: "",
    tb_primaryNumber: "",
    tb_emailAddress: "",
    db_clientType: "B",
    tb_gender:"MF",
  });

  const [clientData, setClientData] = useState([]);
  const [isFilterClicked, setIsFilterClicked] = useState(false); 
  const [gender, setGender] = useState('M'); // Default value is "M"
 
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setGender(e.target.value);
  };

  const handleExport = () => {
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(clientData);

    // Create a workbook containing the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ClientSearchData");

    // Generate an XLSX file and download it
    XLSX.writeFile(wb, "client_search_data.xlsx");
  };

  const clientTypeOptions = [
    {
      value: "C",
      label: "Corporate",
    },
    {
      value: "I",
      label: "Individual",
    },
    {
      value: "B",
      label: "Individual and Corporate",
    },
  ];
  const GenderOptions = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
    {
      value: "MF",
      label: "Male And Female",
    },
  ];
  useEffect(() => {
      console.log(formData);
    }, [formData]);
  
    const handleSingleSelect = (singleselectedOption, actionMeta) => {
      console.log(singleselectedOption?.value);
      const { name } = actionMeta;
      if (singleselectedOption == null) {
        setFormData({
          ...formData,
          [name]: "",
        });
      } else {
        setFormData({
          ...formData,
          [name]: singleselectedOption?.value,
        });
      }
    };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
setIsFilterClicked(false);
    try {
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/CLIENT/filterClientHandler.php",
        data: {
          tb_clientName: formData.tb_clientName,
          tb_primaryNumber: formData.tb_primaryNumber,
          tb_emailAddress: formData.tb_emailAddress,
          db_clientType: formData.db_clientType,
          tb_gender: formData.tb_gender,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setClientData(response.data.OPTO[0].OPTO_MESSAGE);
            setIsFilterClicked(true);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          } else {
            toast.error(
              response.data.OPTO[0].OPTO_CODE + ":" + response.data.OPTO_MESSAGE
            );
          }
        })
        .catch(() => {
          toast.error("Network Error", { position: "top-center" });
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReset = () => {
    setFormData({
      tb_clientName: "",
      tb_primaryNumber: "",
      tb_secondaryNumber: "",
      tb_emailAddress: "",
      tb_gender :"",
      db_clientType: "B",
    });
    setClientData([]);
    setIsFilterClicked(false);
  };


  const columns = [
    {
      name: "Client Name",
      selector: (row) => row.client_name,
      sortable: true,
      width: "40%",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.primary_number,
      width: "15%",
    },
    {
      name: "Telephone Number",
      selector: (row) => row.secondary_number,
      width: "15%",
    },
    {
      name: "Email Address",
      selector: (row) => row.email_address,
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          {/* Wrap the icon in an anchor tag with mailto */}
          <a href={`mailto:${row.email_address}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEnvelope}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </a>
        </button>
      ),
      width: "70px",
    },    
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/home-page/viewclient/${row.client_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "70px",
    },
  ];

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
  };

  return (
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 formGroup">
              <label htmlFor="tb_clientName" className="">
                Client Name
              </label>
              <input
                type="text"
                id="tb_clientName"
                name="tb_clientName"
                className="inputBox form-control formControl text-dark"
                placeholder="Enter client name"
                value={formData.tb_clientName}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 formGroup">
              <label htmlFor="tb_primaryNumber" className="">
                Mobile Number
              </label>
              <input
                type="text"
                id="tb_primaryNumber"
                name="tb_primaryNumber"
                className="inputBox form-control formControl text-dark"
                placeholder="Enter mobile number"
                maxLength="12"
                value={formData.tb_primaryNumber}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 formGroup">
              <label htmlFor="tb_emailAddress" className="">
                Email Address
              </label>
              <input
                type="text"
                id="tb_emailAddress"
                name="tb_emailAddress"
                className="inputBox form-control formControl text-dark"
                placeholder="Enter email address"
                value={formData.tb_emailAddress}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-4">
            <label htmlFor="db_clientType" className="">
              Client Type
            </label>
            <Select
              options={clientTypeOptions}
              placeholder="Client Type"
              id="db_clientType"
              name="db_clientType"
              defaultValue={{ value: "B", label: "Individual and Corporate" }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "40px",
                }),
              }}
              className=""
              // value={formData.db_clientType}
              isSearchable={false}
              isClearable={false}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-4">
            <label htmlFor="tb_gender" className="">
              Gender
            </label>
            <Select
              options={GenderOptions}
              placeholder="Gender"
              id="tb_gender"
              name="tb_gender"
              defaultValue={{ value: "MF", label: "Male And Female" }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "40px",
                }),
              }}
              className=""
              // value={formData.db_clientType}
              isSearchable={false}
              isClearable={false}
              onChange={handleSingleSelect}
            />
          </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={() => window.location.reload()}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA  btnFilter">
                Filter
              </button>
            </div>
            <div className="col-md-4">
              <Link to={"/home-page/addclient"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add Client
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
      {isFilterClicked && clientData.length > 0 && (
      <CSVLink data={clientData} filename="client_data.csv">
        <button
          type="button"
          className="btnCTA btnFilter me-2 px-3"
          style={{
            width: "auto",
            borderRadius: "5px",
            height: "30px",
            fontSize: "14px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
            position: "relative",
            overflow: "hidden", // Make the button relative for absolute positioning of the triangle
          }}
        >

          CSV

        </button>
      </CSVLink>
      )}
      {isFilterClicked && clientData.length > 0 && (
      <button
        type="button"
        className="btnCTA btnAddSave me-2 px-3"
        onClick={handleExport}
        style={{
          width: "auto",
          borderRadius: "5px",
          height: "30px",
          fontSize: "14px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
        }}
      >
        Excel
      </button>
      )}
      {/* DataTable to display client data */}
      <DataTable
        className="resultData"
        columns={columns}
        data={clientData}
        // data={dummy}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      />
    </div>
  );
};

export default ClientSearch;
