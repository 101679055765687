import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../optoCustom.js";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Dropdown } from "bootstrap/dist/js/bootstrap.bundle.min.js";
import Select from "react-select";
import StockDetails from "./StockDetails.jsx";
import AddOrderPopup from "./AddOrderPopup.jsx";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Inventory = () => {
  const [formData, setFormData] = useState({
    db_supplierName: "",
    tb_orderDate: "",
    tb_metalCode: "",
    db_stockType: "",
    db_stockSecondaryType: "",
    db_color: "",
    db_brand: "",
    tb_itemName: "",
    db_status: "",
  });

  const [supplierData, setSupplierData] = useState([]);
  
  const dropdownElements = document.querySelectorAll(
    '[data-bs-toggle="dropdown"]'
  );

  if (dropdownElements.length > 0) {
    dropdownElements.forEach((dropdownElement) => {
      new Dropdown(dropdownElement);
    });
  }

  useEffect(() => {
    document.querySelectorAll(".dropdown").forEach((dropdown) => {
      dropdown.addEventListener("show.bs.dropdown", () => {
        dropdown.querySelector(".stockDropdown").classList.add("isActive");
      });
      dropdown.addEventListener("hide.bs.dropdown", () => {
        dropdown.querySelector(".stockDropdown").classList.remove("isActive");
      });
    });
    return () => {
      document.querySelectorAll(".dropdown").forEach((dropdown) => {
        dropdown.removeEventListener("show.bs.dropdown", () => {});
        dropdown.removeEventListener("hide.bs.dropdown", () => {});
      });
    };
  }, []);

  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
    // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
    setFormData({
    ...formData,
    [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
    });
    } else {
    // Handle regular inputs (like text, number, etc.)
    setFormData({
    ...formData,
    [e.target.name]: e.target.value, // Update with the regular input value
    });
    }
    };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "INVENTORY/inventoryHandler.php",
        data: {
          db_supplierName: formData.db_supplierName,
          tb_orderDate: formData.tb_orderDate,
          tb_metalCode: formData.tb_metalCode,
          db_stockType: formData.db_stockType,
          db_stockSecondaryType: formData.db_stockSecondaryType,
          db_color: formData.db_color,
          db_brand: formData.db_brand,
          tb_itemName: formData.tb_itemName,
          db_status: formData.db_status,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setSupplierData(response.data.OPTO[0].OPTO_MESSAGE.filter_order);
          console.log(response.data.OPTO[0].OPTO_MESSAGE.filter_order);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        } else {
          toast.error(
            response.data.OPTO[0].OPTO_CODE + ":" + response.data.OPTO_MESSAGE
          );
        }
      })
      .catch(() => {
          toast.error("Network Error", { position: "top-center" });
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };

  const handleReset = () => {
    setFormData({
      db_supplierName: "",
      tb_netAmount: "",
      tb_orderDate: "",
      db_status: "",
    });
    setSupplierData([]);
  };

  //handles value change of single select and adds them to formData
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  const [supplierByOptions, setSupplierOptions] = useState([]); // State to store "Created by" dropdown options
  const [DocsIsOpen, setDocsIsOpen] = useState(false);

  const toggleDocsPopup = () => {
    setDocsIsOpen(!DocsIsOpen);
  };
  useEffect(() => {
    const fetchSupplierNameOptions = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + '/COMMON/getSupplierList.php', {
            withCredentials: true,
          }
        ); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.supplier_id,
          label: option.supplier_name,
        }));
        setSupplierOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSupplierNameOptions();
  }, []);


  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const columns = [
    {
      name: "Supplier Name",
      selector: (row) => row.supplier_name,
      sortable: true,
    },
    {
      name: "Net Amount",
      selector: (row) => row.total_net_amount,
    },
    {
      name: "Order Date",
      selector: (row) => row.order_date,
    },
    {
      name: "Status",
      selector: (row) => row.active_status === "A" ? "Active" : "Inactive",
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/HomePage/viewsuppliers/${row.supplier_Id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
  };
  return (
  
    <div>
      <ToastContainer />
      <div className="inputContent">
        <div className="formHeading">Stock Overview</div>
        <div className="dropdowns d-flex flex-row">
          <StockDetails dropdownTitle="By Color" />
          <StockDetails dropdownTitle="By Name" />
          <StockDetails dropdownTitle="By Brand" />
          <StockDetails dropdownTitle="By Style" />
        </div>
        <hr />
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="formGroup col-md-5">
              <label htmlFor="singleSelect" className="">
                Supplier Name
              </label>
              <Select
                options={supplierByOptions}
                key={"client_id"}
                placeholder="Select Supplier"
                name="db_supplierName"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "40px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            {/* Datepicker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="col-3 formGroup">
              <label htmlFor="tb_orderDate" className="">
                Order Date
              </label>
              <DatePicker
                value={dayjs(formData.tb_orderDate)}
                className=""
                id="tb_orderDate"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_orderDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_orderDate" {...params} />}
              />
            </div>
            </LocalizationProvider>
            <div className="formGroup col-md-4">
              <label htmlFor="singleSelect">Stock Type</label>
              <Select
                options={supplierByOptions}
                key={"client_id"}
                placeholder="Select Stock Type"
                name="db_stockType"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                    // maxHeight: "40px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    // maxHeight: "40px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div
              class="accordion accordion-flush additionalFiltersAccordion mb-2"
              id="accordionFlushExample"
            >
              <div class="accordion-item">
                <div class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    style={{fontWeight: "bold"}}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#filtersAccordion"
                    aria-expanded="false"
                    aria-controls="filtersAccordion"
                  >
                    Additional Filters
                  </button>
                </div>
                <div
                  id="filtersAccordion"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body row">
                    <div className="col-md-4 formGroup">
                      <label htmlFor="tb_metalcode" className="">
                        Metal Code
                      </label>
                      <input
                        type="text"
                        id="tb_metalcode"
                        name="tb_metalcode"
                        className="inputBox form-control formControl text-dark"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 formGroup">
                      <label htmlFor="tb_itemName" className="">
                        Item name
                      </label>
                      <input
                        type="text"
                        id="tb_itemName"
                        name="tb_itemName"
                        className="inputBox form-control formControl text-dark"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="formGroup col-md-4">
                      <label htmlFor="singleSelect">Color</label>
                      <Select
                        options={supplierByOptions}
                        key={"client_id"}
                        placeholder="Select Color"
                        name="db_color"
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            border: "1px solid #d2d2d2",
                            borderRadius: "0px",
                          }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "0px",
                            fontSize: "14px",
                            maxHeight: "40px",
                          }),
                        }}
                        className=""
                        isSearchable
                        // isDisabled
                        onChange={handleSingleSelect}
                      />
                    </div>

                    <div className="formGroup col-md-4">
                      <label htmlFor="singleSelect">Stock Secondary Type</label>
                      <Select
                        options={supplierByOptions}
                        key={"client_id"}
                        placeholder="Select Stock Secondary Type"
                        name="db_stockSecondaryType"
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            border: "1px solid #d2d2d2",
                            borderRadius: "0px",
                          }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "0px",
                            fontSize: "14px",
                            maxHeight: "40px",
                          }),
                        }}
                        className=""
                        isSearchable
                        // isDisabled
                        onChange={handleSingleSelect}
                      />
                    </div>

                    <div className="formGroup col-md-4">
                      <label htmlFor="singleSelect">Brand</label>
                      <Select
                        options={supplierByOptions}
                        key={"client_id"}
                        placeholder="Select Brand"
                        name="db_brand"
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            border: "1px solid #d2d2d2",
                            borderRadius: "0px",
                          }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "0px",
                            fontSize: "14px",
                            maxHeight: "40px",
                          }),
                        }}
                        className=""
                        isSearchable
                        // isDisabled
                        onChange={handleSingleSelect}
                      />
                    </div>
                    <div className="formGroup col-md-4">
                      <label htmlFor="singleSelect">Status</label>
                      <Select
                        options={supplierByOptions}
                        key={"client_id"}
                        placeholder="Select Status"
                        name="db_status"
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            border: "1px solid #d2d2d2",
                            borderRadius: "0px",
                          }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "0px",
                            fontSize: "14px",
                            maxHeight: "40px",
                          }),
                        }}
                        className=""
                        isSearchable
                        // isDisabled
                        isClearable
                        onChange={handleSingleSelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA  btnFilter">
                Filter
              </button>
            </div>
            <div className="col-md-4">
            <Link to={"/home-page/addorder"}>
                <button type="button" className="btnCTA btnAddSave">
                  New Order
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData"
        columns={columns}
        data={supplierData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      />
    </div>
  );
};

export default Inventory;
