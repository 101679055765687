import React, { useState, useEffect } from "react";
// import { useLocation } from 'react-router-dom';
import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const EditClientMainDetails = ({ open, editClientMainData, onClose }) => {

  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_clientId: "",
    tb_clientName: "",
    db_clientType: "",
    tb_primaryNumber: "",
    tb_secondaryNumber: "",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_poBox: "",
    tb_dob: "",
    tb_occupation: "",
    tb_reference: "",
    tb_gender: "",
  });

  // Initialize form data if editClientMainData is available
  useEffect(() => {
    console.log('Form With FormData : ', formData);
    if (editClientMainData) {
      setFormData({
        tb_clientId: editClientMainData.client_id,
        db_clientType: editClientMainData.client_type,
        tb_clientName: editClientMainData.client_name,
        tb_primaryNumber: editClientMainData.primary_number,
        tb_secondaryNumber: editClientMainData.secondary_number,
        tb_emailAddress: editClientMainData.email_address,
        tb_physicalAddress: editClientMainData.physical_address,
        tb_poBox: editClientMainData.post_box,
        tb_dob: editClientMainData.dob,
        tb_occupation: editClientMainData.occupation,
        tb_reference: editClientMainData.reference_name,
        tb_gender: editClientMainData.gender,
      });
    }
  }, [editClientMainData]);


  const handleChange = (e, value) => {
    if (value && value.format) {
      // This is the DatePicker case
      const formattedDate = value ? value.format('YYYY-MM-DD') : '';
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // This is the regular input case
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/CLIENT/editClientMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_clientId': formData.tb_clientId,
          'tb_clientName': formData.tb_clientName,
          'db_clientType': formData.db_clientType,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_gender': formData.tb_gender,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
          'tb_dob': formData.tb_dob,
          'tb_occupation': formData.tb_occupation,
          'tb_reference': formData.tb_reference,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          onClose();
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
          navigate(response.data.OPTO[0].OPTO_LINK)
        }
      })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error.response.data.OPTO[0].OPTO_CODE);
    }
  };


  return (
    <>
      <ToastContainer />
      <div>
        <Popup open={open} closeOnDocumentClick={false} onClose={onClose}>
          <div className="editPopup" style={{ height: "600px" }}>
             {/* Cross button to close the popup */}
             <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
            <div className="row ">
              {/* String Fields */}
              <div className="col-md-6  formGroup">
                <label htmlFor="tb_clientName" className="">
                  Client Name
                </label>
                <input
                  type="text"
                  id="tb_clientName"
                  name="tb_clientName"
                  className="inputBox form-control formControl text-dark"
                  placeholder="Enter client name"
                  value={formData.tb_clientName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 pe-2">
                <label className="form-check-label" htmlFor="db_clientType">
                  Client Type
                </label>
                <div className="dropdownGroup">
                  <select
                    className="form-select inputBox form-control formControl text-dark"
                    name="db_clientType"
                    id="db_clientType"
                    value={formData.db_clientType || ''} // Ensure it is never undefined
                    onChange={handleChange} // Handle the change when the user selects an option
                  >
                    <option value="I">Individual</option> {/* Option for Male */}
                    <option value="C">Corporate</option> {/* Option for Female */}
                  </select>
                </div>
              </div>
              <div className="col-md-6  formGroup">
                <label htmlFor="tb_primaryNumber" className="">
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="tb_primaryNumber"
                  name="tb_primaryNumber"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter mobile number"
                  value={formData.tb_primaryNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6  formGroup">
                <label htmlFor="tb_secondaryNumber" className="">
                  Telephone Number
                </label>
                <input
                  type="text"
                  id="tb_secondaryNumber"
                  name="tb_secondaryNumber"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter telephone number"
                  value={formData.tb_secondaryNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6  formGroup">
                <label htmlFor="tb_emailAddress" className="">
                  Email Address
                </label>
                <input
                  type="email"
                  id="tb_emailAddress"
                  name="tb_emailAddress"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter email address"
                  value={formData.tb_emailAddress}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 pe-2">
                <label className="form-check-label" htmlFor="gender">
                  Gender
                </label>
                <div className="dropdownGroup">
                  <select
                    className="form-select inputBox form-control formControl text-dark"
                    name="tb_gender"
                    id="tb_gender"
                    value={formData.tb_gender || ''} // Ensure it is never undefined
                    onChange={handleChange} // Handle the change when the user selects an option
                  >
                    <option value="M">Male</option> {/* Option for Male */}
                    <option value="F">Female</option> {/* Option for Female */}
                  </select>
                </div>
              </div>
              {/* Contact Number Field */}
              <div className="col-md-12 formGroup">
                <label htmlFor="tb_physicalAddress" className="">
                  Physical Address
                </label>
                <textarea
                  type="text"
                  id="tb_physicalAddress"
                  name="tb_physicalAddress"
                  className="inputBox form-control formControl text-dark tb_address"
                  placeholder="Enter physical address"
                  cols="100"
                  rows={5}
                  value={formData.tb_physicalAddress}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6  formGroup">
                <label htmlFor="tb_poBox" className="">
                  Post Box
                </label>
                <input
                  type="text"
                  id="tb_poBox"
                  name="tb_poBox"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter postbox number"
                  value={formData.tb_poBox}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6 formGroup">
                <label htmlFor="tb_dob" className="">
                  Date of Birth
                </label>
                <DatePicker
                  value={dayjs(formData.tb_dob)}
                  className=""
                  id="tb_dob"
                  maxDate={dayjs()}
                  onChange={(newValue) =>
                    handleChange({ target: { name: "tb_dob" } }, newValue) // Pass value to handleChange
                  }
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                    "& .MuiOutlinedInput-root": {
                      "&:hover > fieldset": {
                        border: "1px solid #b0b0b0 !important",
                      },
                      height: "35px",
                      border: "1px solid #b0b0b0 !important",
                      borderRadius: "0px",
                    },
                  }}
                  format="DD/MMM/YYYY"
                  renderInput={(params) => <input name="tb_dob" {...params} />}
                />
              </div>
              <div className="col-6  formGroup">
                <label htmlFor="tb_occupation" className="">
                  Occupation
                </label>
                <input
                  type="text"
                  id="tb_occupation"
                  name="tb_occupation"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter occupation"
                  value={formData.tb_occupation}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6  formGroup">
                <label htmlFor="tb_reference" className="">
                  Refered By
                </label>
                <input
                  type="text"
                  id="tb_reference"
                  name="tb_reference"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter reference name"
                  value={formData.tb_reference}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row btnGroup">
              <div className="col-md-6 ">
                <button
                  type="reset"
                  className="btnCTA btnReset"
                  onClick={() => window.location.reload()}
                >
                  Reset
                </button>
              </div>
              <div className="col-md-6 ">
                <button type="submit" className="btnCTA btnAddSave" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Popup >
      </div >
    </>
  );
};

export default EditClientMainDetails;
