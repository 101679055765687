import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Select from "react-select";

const AddClient = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_clientName: "",
    db_clientType: "",
    tb_primaryNumber: "",
    tb_secondaryNumber: "",
    tb_gender: "M",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_poBox: "",
    tb_dob: "",
    tb_occupation: "",
    tb_reference: "",
  });

  const clientTypeOptions = [
    {
      value: "C",
      label: "Corporate",
    },
    {
      value: "I",
      label: "Individual",
    },
  ];

  const [gender, setGender] = useState('M'); // Default value is "M"
  const [occupationOptions, setOccupationOptions] = useState([]);

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchOccupationOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getPatientOccupationList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.occupation, // Adjust based on your API response structure
          label: option.occupation, // Adjust based on your API response structure
        }));
        setOccupationOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchOccupationOptions();
  }, []);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  // Handle input change
  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
      // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // Handle regular inputs (like text, number, etc.)
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
    setGender(e.target.value);
  };

  // // Datetimepicker
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/CLIENT/addClientHandler.php",
        // data: params,
        data: {
          tb_clientName: formData.tb_clientName,
          db_clientType: formData.db_clientType,
          tb_primaryNumber: formData.tb_primaryNumber,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_gender: formData.tb_gender,
          tb_emailAddress: formData.tb_emailAddress,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_poBox: formData.tb_poBox,
          tb_dob: formData.tb_dob,
          tb_occupation: formData.tb_occupation,
          tb_reference: formData.tb_reference,
          tb_clientId : formData.client_id,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
          navigate(response.data.OPTO[0].OPTO_LINK);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };

  return (
    <>
      <ToastContainer />

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-8  formGroup">
            <label htmlFor="tb_clientName" className="">
              Client Name
            </label>
            <input
              type="text"
              id="tb_clientName"
              name="tb_clientName"
              placeholder="Enter client name"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-4">
            <label htmlFor="db_clientType" className="">
              Client Type
            </label>
            <Select
              options={clientTypeOptions}
              placeholder="Client Type"
              id="db_clientType"
              name="db_clientType"
              defaultValue={{ value: "I", label: "Individual" }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "40px",
                }),
              }}
              className=""
              isSearchable={false}
              isClearable={false}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_primaryNumber" className="">
              Mobile Number
            </label>
            <input
              type="text"
              id="tb_primaryNumber"
              name="tb_primaryNumber"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter mobile number"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_secondaryNumber" className="">
              Telephone Number
            </label>
            <input
              type="text"
              id="tb_secondaryNumber"
              name="tb_secondaryNumber"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter telephone number"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="col-5  formGroup">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter email address"
              onChange={handleChange}
            />
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="col-4 formGroup">
              <label htmlFor="tb_dob" className="text-center">
                Date of Birth
              </label>
              <DatePicker
                defaultValue={dayjs()}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_dob" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_dob" {...params} />}
              />
            </div>
          </LocalizationProvider>
          <div className="formGroup col-md-3">
            <label className="form-check-label" htmlFor="gender">
              Gender
            </label>
            <div className="radioGroup d-flex justify-content-start">
              <div className="form-check me-3">
              <input
                className="form-check-input"
                    type="radio"
                    name="tb_gender"
                    id="genderMale"
                    value="M"
                    checked={gender === 'M'}
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check me-3">
              <input
                className="form-check-input"
                    type="radio"
                    name="tb_gender"
                    id="genderFemale"
                    value="F"
                    checked={gender === 'F'}
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
            </div>
          </div>
          {/* Contact Number Field */}
          <div className="col-md-8 formGroup">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea text-dark tb_address"
              placeholder="Enter physical address"
              cols="100"
              rows={5}
              onChange={handleChange}
            />
          </div>
          <div className="col-4  formGroup">
            <label htmlFor="tb_poBox" className="">
              Post Box
            </label>
            <input
              type="text"
              id="tb_poBox"
              name="tb_poBox"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter post box number"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-4">
            <label htmlFor="tb_occupation" className="">
             Occupation
            </label>
            <Select
              options={occupationOptions}
              placeholder="Occupation"
              id="tb_occupation"
              name="tb_occupation"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #d2d2d2",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "40px",
                }),
              }}
              className=""
              isSearchable={false}
              isClearable={false}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="col-6  formGroup">
            <label htmlFor="tb_reference" className="">
              Refered By
            </label>
            <input
              type="text"
              id="tb_reference"
              name="tb_reference"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter refered by"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <Link to={"/home-page/searchclient"} className="col-md-4">
            <button type="submit" className="btnCTA btnCancel">
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddClient;
