import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import Select from "react-select";
import "reactjs-popup/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../src/optoCustom.js";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddDiagnosisPopup = ({ open, editClientMainData, onClose }) => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_clientName: "",
    tb_patientId: "",
    tb_patientName: "",
    tb_clientId: "",
    tb_doctorId: "",
    tb_diagnosisDate: "",
    tb_gender: "M",
    ta_healthIssue: "",
    tb_vrre: "",
    tb_vrle: "",
    tb_srre: "",
    tb_srle: "",
    tb_orre: "",
    tb_orle: "",
    ta_doctorDiagnosis: "",
    tb_notes: "",
  });

  useEffect(() => {
    console.log('Form With FormData : ', formData);
    if (editClientMainData) {
      setFormData({
        tb_clientId: editClientMainData.client_id,
        tb_clientName: editClientMainData.client_name,
      });
    }
  }, [editClientMainData]);
  const [gender, setGender] = useState('M'); // Default value is "M"
  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options
  const [doctorOptions, setDoctorOptions] = useState([]); // State to store "Created by" dropdown options
  const hasFetchData = useRef(false);

  useEffect(() => {
    if (hasFetchData.current) return;
    // Mark that the API has been called
    hasFetchData.current = true;
    const CreatedByOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: API_BASE_URL + 'COMMON/getPatientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.patient_id, // Adjust based on your API response structure
          label: option.patient_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    CreatedByOptions();
  }, []);

  const hasFetchedData = useRef(false);
  useEffect(() => {
    if (hasFetchedData.current) return;
    // Mark that the API has been called
    hasFetchedData.current = true;
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "COMMON/getDoctorList.php", {
          withCredentials: true,
        }
        ); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.doctorId, // Adjust based on your API response structure
          label: option.doctorName, // Adjust based on your API response structure
        }));
        setDoctorOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const handleChange = (e, value) => {
    if (value && value.format) {
      // This is the DatePicker case
      const formattedDate = value ? value.format('YYYY-MM-DD') : '';
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // This is the regular input case
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
    setGender(e.target.value);
  };


  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      axios({
        method: "post",
        url: API_BASE_URL + "DIAGNOSIS/addDiagnosisHandler.php",
        // data: params,
        data: {
          tb_patientName: formData.tb_patientName,
          tb_clientName: formData.tb_clientName,
          tb_patientId: formData.tb_patientId,
          tb_clientId: formData.tb_clientId,
          tb_doctorId: formData.tb_doctorId,
          tb_diagnosisDate: formData.tb_diagnosisDate,
          tb_gender: formData.tb_gender,
          ta_healthIssue: formData.ta_healthIssue,
          tb_vrre: formData.tb_vrre,
          tb_vrle: formData.tb_vrle,
          tb_srre: formData.tb_srre,
          tb_srle: formData.tb_srle,
          tb_orre: formData.tb_orre,
          tb_orle: formData.tb_orle,
          ta_doctorDiagnosis: formData.ta_doctorDiagnosis,
          tb_notes: formData.tb_notes,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setFormData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
            onClose();
           toast.success("Diagnosis added successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <div>
        <ToastContainer />
        <Popup open={open} closeOnDocumentClick={false} onClose={onClose}>
          <div className="editPopup" style={{ height: "600px" }}>
             {/* Cross button to close the popup */}
             <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
            <div className="row ">
              {/* String Fields */}
              <div className="col-md-12  formGroup">
                <label htmlFor="tb_clientName" className="">
                  Client Name
                </label>
                <input
                  type="text"
                  id="tb_clientName"
                  name="tb_clientName"
                  className="inputBox form-control formControl text-dark"
                  placeholder="Enter client name"
                  value={formData.tb_clientName}
                  readOnly
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup col-6">
                <label htmlFor="singleSelect">Patient Name</label>
                <Select
                  options={createdByOptions}
                  key={"patient_id"}
                  placeholder="Select patient name"
                  name="tb_patientId"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      border: "1px solid #d2d2d2",
                      borderRadius: "0px",
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0px",
                      fontSize: "14px",
                      maxHeight: "45px",
                    }),
                  }}
                  className=""
                  isSearchable
                  // isDisabled
                  onChange={handleSingleSelect}
                />
              </div>
              <div className="formGroup col-6">
                <label htmlFor="singleSelect">Doctor</label>
                <Select
                  options={doctorOptions}
                  placeholder="Select doctor"
                  name="tb_doctorId"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      border: "1px solid #d2d2d2",
                      borderRadius: "0px",
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0px",
                      fontSize: "14px",
                      maxHeight: "45px",
                    }),
                  }}
                  className=""
                  isSearchable
                  // isDisabled
                  onChange={handleSingleSelect}
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="col-6 formGroup">
                <label htmlFor="tb_diagnosisDate" className="">
                 Diagnosis Date
                </label>
                <DatePicker
                  value={dayjs(formData.tb_diagnosisDate)}
                  className=""
                  id="tb_diagnosisDate"
                  maxDate={dayjs()}
                  // onChange={(newValue) =>
                  //   handleChange({ target: { name: "tb_dob" } }, newValue)
                  // } // Pass value to handleChange
                  onChange={(newValue) =>
                    handleChange({ target: { name: "tb_diagnosisDate" } }, newValue) // Pass value to handleChange
                  }
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                    "& .MuiOutlinedInput-root": {
                      "&:hover > fieldset": {
                        border: "1px solid #b0b0b0 !important",
                      },
                      height: "35px",
                      border: "1px solid #b0b0b0 !important",
                      borderRadius: "0px",
                    },
                  }}
                  format="DD/MMM/YYYY"
                  renderInput={(params) => <input name="tb_diagnosisDate" {...params} />}
                />
              </div>
              </LocalizationProvider>
              <div className="formGroup col-md-6">
                <label className="form-check-label" htmlFor="gender">
                  Gender
                </label>
                <div className="radioGroup d-flex justify-content-start">
                  <div className="form-check me-3">
                  <input
                className="form-check-input"
                    type="radio"
                    name="tb_gender"
                    id="genderMale"
                    value="M"
                    checked={gender === 'M'}
                    onChange={handleChange}
                />
                    <label className="form-check-label" htmlFor="genderMale">
                      Male
                    </label>
                  </div>
                  <div className="form-check me-3">
                  <input
                className="form-check-input"
                    type="radio"
                    name="tb_gender"
                    id="genderFemale"
                    value="F"
                    checked={gender === 'F'}
                    onChange={handleChange}
                />
                    <label className="form-check-label" htmlFor="genderFemale">
                      Female
                    </label>
                  </div>
                </div>
              </div>
              {/* Contact Number Field */}
              <div className="col-md-12 formGroup">
                <label htmlFor="ta_healthIssue" className="">
                  Health Issue
                </label>
                <textarea
                  type="text"
                  id="ta_healthIssue"
                  name="ta_healthIssue"
                  className="inputBox form-control textarea  text-dark tb_address"
                  placeholder="Enter health issue"
                  cols="100"
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup col-md-4">
                <label htmlFor="tb_vrre" className="">
                  VRRE
                </label>
                <input
                  type="text"
                  id="tb_vrre"
                  name="tb_vrre"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter vrre"
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup col-md-4">
                <label htmlFor="tb_orre" className="">
                  ORRE
                </label>
                <input
                  type="text"
                  id="tb_orre"
                  name="tb_orre"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter orre"
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup col-md-4">
                <label htmlFor="tb_srre" className="">
                  SRRE
                </label>
                <input
                  type="text"
                  id="tb_srre"
                  name="tb_srre"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter srre"
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup col-md-4">
                <label htmlFor="tb_vrle" className="">
                  VRLE
                </label>
                <input
                  type="text"
                  id="tb_vrle"
                  name="tb_vrle"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter vrle"
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup col-md-4">
                <label htmlFor="tb_orle" className="">
                  ORLE
                </label>
                <input
                  type="text"
                  id="tb_orle"
                  name="tb_orle"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter orle"
                  onChange={handleChange}
                />
              </div>
              <div className="formGroup col-md-4">
                <label htmlFor="tb_srle" className="">
                  SRLE
                </label>
                <input
                  type="text"
                  id="tb_srle"
                  name="tb_srle"
                  className="inputBox form-control formControl  text-dark"
                  placeholder="Enter srle"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 formGroup">
                <label htmlFor="tb_notes" className="">
                  Notes
                </label>
                <textarea
                  type="text"
                  id="tb_notes"
                  name="tb_notes"
                  className="inputBox form-control textarea  text-dark tb_address"
                  placeholder="Enter notes"
                  rows={5}
                  cols="100"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 formGroup">
                <label htmlFor="tb_doctorDaignosis" className="">
                  Doctor Diagnosis
                </label>
                <textarea
                  type="text"
                  id="ta_doctorDiagnosis"
                  name="ta_doctorDiagnosis"
                  className="inputBox form-control textarea  text-dark tb_address"
                  placeholder="Enter doctor diagnosis"
                  rows={5}
                  cols="100"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row btnGroup">
            <div className="col-md-6 ">
                <button
                  type="reset"
                  className="btnCTA btnReset"
                  onClick={() => window.location.reload()}
                >
                  Reset
                </button>
              </div>
              <div className="col-md-6 ">
                <button
                  type="submit"
                  className="btnCTA btnAddSave"
                  onClick={handleSubmit}
                  onClose={onClose}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

export default AddDiagnosisPopup;
