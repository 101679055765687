import React, { useState , useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditSuppliersContactDetails = ({ open ,editSupplierMainData , onClose }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_physicalAddress: '',
    tb_poBox: '',
    tb_city: '',
    tb_primaryNumber: '',
    tb_secondaryNumber: '',
    tb_emailAddress: '',
    tb_contactName: '',
    tb_contactdesignation: '',
  });
  useEffect(() => {
    console.log('Form With FormData : ', formData);
    if (editSupplierMainData) {
      setFormData({
        tb_emailAddress: editSupplierMainData.email_address,
        tb_physicalAddress: editSupplierMainData.physical_address,
        tb_poBox: editSupplierMainData.post_box,
        tb_primaryNumber: editSupplierMainData.primary_number,
        tb_secondaryNumber: editSupplierMainData.secondary_number,
      });
    }
  }, [editSupplierMainData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/SUPPLIER/editSupplierContactDetailsHandler.php',
        // data: params,
        data: {
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_contactName': formData.tb_contactName,
          'tb_poBox': formData.tb_poBox,
          'tb_city': formData.tb_city,
          'tb_contactdesignation': formData.tb_contactdesignation,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          onClose();
          toast.success("Contact details edited successfully!", { position: "top-center" });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };

  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick={false} onClose={onClose}>
        <div className="editPopup"  >
           {/* Cross button to close the popup */}
           <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
        <div className="row">
                <div className="col-md-12">
                  <label htmlFor="tb_physicalAddress" className="">
                    Physical Address
                  </label>
                  <textarea
                    type="text"
                    id="tb_physicalAddress"
                    name="tb_physicalAddress"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter physical address'
                    value={formData.tb_physicalAddress}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_poBox" className="">
                    Post Box
                  </label>
                  <input
                    type="text"
                    id="tb_poBox"
                    name="tb_poBox"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter post box'
                    value={formData.tb_poBox}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_city" className="">
                    City
                  </label>
                  <input
                    type="text"
                    id="tb_city"
                    name="tb_city"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter city'
                    value={formData.tb_city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_primaryNumber" className="">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="tb_primaryNumber"
                    name="tb_primaryNumber"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter mobile number'
                    value={formData.tb_primaryNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_secondaryNumber" className="">
                    Telephone Number
                  </label>
                  <input
                    type="text"
                    id="tb_secondaryNumber"
                    name="tb_secondaryNumber"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter telephone number'
                    value={formData.tb_secondaryNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="tb_emailAddress" className="">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="tb_emailAddress"
                    name="tb_emailAddress"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter email address'
                    value={formData.tb_emailAddress}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_contactPerson" className="">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    id="tb_contactPerson"
                    name="tb_contactPerson"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter contact person'
                    value={formData.tb_contactName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_designation" className="">
                    Designation
                  </label>
                  <input
                    type="text"
                    id="tb_designation"
                    name="tb_designation"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter designation'
                    value={formData.tb_contactdesignation}
                    onChange={handleChange}
                  />
                </div>
                </div>
                <br/>
                <div className="row btnGroup">
                <div className="col-md-12 ">
                  <button className="btnCTA btnAddSave col-6" onClick={handleSubmit} onClose={onClose}>
                    Save
                  </button>
                </div>
              </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditSuppliersContactDetails;
