import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import Mainpopup from "../../../HomePage/Masters/Suppliers/EditSuppliersMainDetails";
import Contactpopup from "../../../HomePage/Masters/Suppliers/EditSuppliersContactDetails";
import Bankpopup from "../../../HomePage/Masters/Suppliers/EditSuppliersBankDetails";
import axios from "axios";
import { API_BASE_URL } from "../../../../optoCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {  toast } from "react-toastify";
const ViewSuppliers = () => {
  const { supplierId } = useParams();
  const [mainIsOpen, setmainIsOpen] = useState(false);
  const [ContactIsOpen, setContactIsOpen] = useState(false);
  const [bankIsOpen, setbankIsOpen] = useState(false);
  const [mainDetails, setMainDetails] = useState([]);
  const [editSupplierMainDetails, setSupplierMainData] = useState(null);
  const [contactDetails, setContactDetails] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  // const [historyDetails, setHistoryDetails] = useState([]);
  // const [voucherDetails, setVoucherDetails] = useState([]);

  const setmainIsClose = () => {
    setmainIsOpen(false);
    window.location.reload(); // Refresh the page
  };
  const setContactIsClose = () => {
    setContactIsOpen(false);
    window.location.reload(); // Refresh the page
  };
  const setbankIsClose = () => {
    setbankIsOpen(false);
    window.location.reload(); // Refresh the page
  };
  const hasFetchedData = useRef(false);
  useEffect(() => {
    if (hasFetchedData.current) return;
    // Mark that the API has been called
    hasFetchedData.current = true;
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "post",
          url: API_BASE_URL + "MASTER/SUPPLIER/viewSupplierDetailsHandler.php",
          data: {
            tb_supplierId: supplierId,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        });

        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          const responsedData = response.data.OPTO[0].OPTO_MESSAGE;

          // Set state for each section of the JSON data
          setMainDetails(responsedData.main_details[0] || {});
          setContactDetails(responsedData.contact_details[0] || []);
          setBankDetails(responsedData.bank_details || []);
          // setHistoryDetails(responsedData.history_details || []);
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(
          error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
          { position: "top-center" }
        );
      }
    };

    fetchData();
  }, []);

  const toggleMainPopup = (editSupplierMainData) => {
    console.log("Popup Open : " + editSupplierMainData.supplier_name);
    setSupplierMainData(editSupplierMainData); // Store tb_clientName in state
    setmainIsOpen(!mainIsOpen);
  };

  const toggleContactPopup = (editSupplierMainData) => {
    console.log("Popup Open : " + editSupplierMainData.supplier_name);
    setSupplierMainData(editSupplierMainData); // Store tb_clientName in state
    setContactIsOpen(!ContactIsOpen);
  };

  const toggleBankPopup = () => {
    setbankIsOpen(!bankIsOpen);
  };

  const bankAccordionColumns = [
    {
      name: "Bank Account",
      selector: (row) => row.supplier_id,
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: (row) => row.bank_branch_name,
      sortable: true,
    },
    {
      name: "Branch Code",
      selector: (row) => row.bank_branch_code,
      sortable: true,
    },
    {
      name: "Swift",
      selector: (row) => row.bank_swift_code,
      sortable: true,
    },
    {
      name: "ABA",
      selector: (row) => row.bank_aba_number,
      sortable: true, // selector:
    },
    {
      name: "iBAN",
      selector: (row) => row.bank_iban_number,
      sortable: true,
    },
    {
      name: "Sort Code",
      selector: (row) => row.bank_sort_code,
      sortable: true,
    },
  ];

  const vouchersAccordionColumns = [
    {
      name: "Voucher",
      // selector:
    },
    {
      name: "Voucher Date",
      // selector:
    },
    {
      name: "Bank Account",
      // selector:
    },
    {
      name: "Voucher Amount",
      // selector:
    },
    {
      name: "Action",
      // selector:
    },
  ];

  // const historyAccordionColumns = [
  //   {
  //     name: "Name",
  //     selector: (row) => row.supplier_name,
  //     sortable: true,
  //   },
  //   {
  //     name: "TIN",
  //     selector: (row) => row.tin,
  //     sortable: true,
  //   },
  //   {
  //     name: "VRN",
  //     selector: (row) => row.vrn,
  //     sortable: true,
  //   },
  //   {
  //     name: "Changed On",
  //     selector: (row) => row.changed_on,
  //     sortable: true,
  //   },
  //   {
  //     name: "Created By",
  //     selector: (row) => row.changed_by,
  //     sortable: true,
  //   },
  // ];

  return (
    <div className="viewFormContainer row">
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading">
            {mainDetails.supplier_name} 
          </div>
          <div className="formSubHeading"></div>
        </div>
        <Link to={"/home-page/searchsuppliers"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      <div className="accordion col-12" id="viewDetailsAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Main
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#viewDetailsAccordion"
          >
            <div className="accordion-body">
              <div className="row mt-1">
                <div className="col-md-12 mt-1 formGroup ">
                  <label htmlFor="tb_supplierName" className="">
                    Supplier Name
                  </label>
                  <input
                    type="text"
                    id="tb_supplierName"
                    name="tb_supplierName"
                    value={mainDetails.supplier_name}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_supplierShortName" className="">
                    Supplier Short Name
                  </label>
                  <input
                    type="text"
                    id="tb_supplierShortName"
                    name="tb_supplierShortName"
                    value={mainDetails.supplier_short_name}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_supplierType" className="">
                    Supplier type
                  </label>
                  <input
                    type="text"
                    id="tb_supplierType"
                    name="tb_supplierType"
                    value={mainDetails.supplier_type}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_tin" className="">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    value={mainDetails.tin}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_vrn" className="">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    value={mainDetails.vrn}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="row btnGroup">
              <div className=" d-flex justify-content-center ">
                  <button className="btn_edit col-6" onClick={() => toggleMainPopup(mainDetails)}>
                    Edit
                  </button>
                  <Mainpopup
                    id="mainDetailspopup"
                    open={mainIsOpen}
                    onClose={setmainIsClose}
                    editSupplierMainData={editSupplierMainDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Details Accordion */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Contact Details
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#viewDetailsAccordion"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-12 formGroup">
                  <label htmlFor="tb_emailAddress" className="">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="tb_emailAddress"
                    name="tb_emailAddress"
                    value={contactDetails.email_address  || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-8 formGroup">
                  <label htmlFor="tb_physicalAddress" className="">
                    Physical Address
                  </label>
                  <input
                    type="text"
                    id="tb_physicalAddress"
                    name="tb_physicalAddress"
                    value={contactDetails.physical_address || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-4 formGroup">
                  <label htmlFor="tb_postBox" className="">
                    Post Box
                  </label>
                  <input
                    type="text"
                    id="tb_postBox"
                    name="tb_postBox"
                    value={contactDetails.post_box || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_primaryNumber" className="">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="tb_primaryNumber"
                    name="tb_primaryNumber"
                    value={contactDetails.primary_number || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_secondaryContact" className="">
                    Telephone Number
                  </label>
                  <input
                    type="text"
                    id="tb_secondaryContact"
                    name="tb_secondaryContact"
                    value={contactDetails.secondary_number || ""}
                    className="inputBox form-control  text-dark"
                    readOnly
                  />
                </div>
              </div>
              <div className="row btnGroup ">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn_edit col-6"
                    onClick={() => toggleContactPopup(contactDetails)}
                  >
                    Edit
                  </button>
                  <Contactpopup
                    id="Contactpopup"
                    open={ContactIsOpen}
                    onClose={setContactIsClose}
                    value={contactDetails.supplierId}
                    editSupplierMainData={editSupplierMainDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Bank Details
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#viewDetailsAccordion"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={bankAccordionColumns}
                    data={bankDetails}
                    responsive
                    id="bankAccordionTable"
                  ></DataTable>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn_edit col-6"
                      onClick={toggleBankPopup}
                    >
                      Edit
                    </button>
                  </div>
                  <Bankpopup
                    id="bankpopup"
                    open={bankIsOpen}
                    onClose={setbankIsClose}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Vouchers
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#viewDetailsAccordion"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={vouchersAccordionColumns}
                    // data={voucherDetails}
                    pagination
                    responsive
                    id="VoucherAccordionTable"
                  ></DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              History
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse accordion"
            aria-labelledby="headingSix"
            data-bs-parent="#viewDetailsAccordion"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={historyAccordionColumns}
                    data={historyDetails}
                    pagination
                    responsive
                    id="historyAccordionTable"
                  ></DataTable>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="linkContainer col-3 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
        <Link
          to="/Homepage/AddPatient"
          element={<AddPatient />}
          className="quickLink mb-2"
        >
          Example Link One
        </Link>
      </div> */}
    </div>
  );
};

export default ViewSuppliers;
