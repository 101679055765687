import React from "react";
import Eye from "../../../Assets/Eye.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Edit.css";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../src/optoCustom.js";
import { useNavigate } from "react-router-dom";
const EditStaffDetails = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [formData, setData] = useState([]);
  const [staffDetails, setStaffDetails] = useState({
    staffName: "",
    email: "",
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     axios
  //       .get("https://jsonplaceholder.typicode.com/users")
  //       .then((res) => setData(res.data))
  //       .catch((err) => console.log(err));
  //   };
  //   fetchData();
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStaffDetails({ ...staffDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: "post",
        url: API_BASE_URL + "GODMODE/editStaffDetailsHandler.php",
        // data: params,
        data: {
          tb_staffName: formData.tb_staffName,
          tb_emailaddress: formData.tb_emailaddress,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then(function (response) {
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/GodPage/View`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Column 2",
      selector: (row) => row.address.street,
    },
    {
      name: "Column 3",
      selector: (row) => row.phone,
    },
    {
      name: "Column 4",
      selector: (row) => row.website,
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <img src={Eye} className="inventory" alt="inventory" />
        </button>
      ),
    },
  ];

  // custom styles for result react-data-table-component
const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer />
      <Popup open={open} closeOnDocumentClick onClose={onClose}>
        <div className="editPopup" style={{ height: "600px" }}>
          <div className="row mt-1 popup">
            <div className="col-12 mt-1">
              <label htmlFor="tb_staffName" className="">
                Staff Name
              </label>
              <input
                type="text"
                id="tb_staffName"
                name="staffName"
                className="inputBox form-control formControl text-dark"
                value={staffDetails.staffName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-12 mt-1">
              <label htmlFor="tb_emailaddress" className="">
                Email Address
              </label>
              <input
                type="text"
                id="tb_emailaddress"
                name="tb_emailaddress"
                className="inputBox form-control formControl text-dark"
                value={staffDetails.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <button className="btnCTA btnAddSave col-6" onClick={handleSubmit}>
            Save
          </button>
          <DataTable
            className="resultData"
            columns={columns}
            data={formData}
            pagination
            responsive
            id="myTable"
            customStyles={tableStyles}
          ></DataTable>
        </div>
      </Popup>
    </div>
  );
};

export default EditStaffDetails;
