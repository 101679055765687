import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
// import { useNavigate } from "react-router-dom";
const EditSuppliersMainDetails = ({ open, editSupplierMainData , onClose }) => {

  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_supplierId:'',
    tb_supplierName: '',
    tb_shortName: '',
    tb_supplierType: '',
    tb_tin: '',
    tb_vrn: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

   // Initialize form data if editSupplierMainData is available
   useEffect(() => {
    console.log('Form With FormData : ', formData);
    if (editSupplierMainData) {
      setFormData({
        tb_supplierId: editSupplierMainData.supplier_id,
        tb_supplierName: editSupplierMainData.supplier_name,
        tb_shortName: editSupplierMainData.supplier_short_name,
        tb_supplierType: editSupplierMainData.supplier_type,
        tb_tin: editSupplierMainData.tin,
        tb_vrn: editSupplierMainData.vrn,
      });
    }
  }, [editSupplierMainData]);

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/SUPPLIER/editSupplierMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_supplierName': formData.tb_supplierName,
          'tb_shortName': formData.tb_shortName,
          'tb_supplierType': formData.tb_supplierType,
          'tb_tin': formData.tb_tin,
          'tb_vrn': formData.tb_vrn,
          'tb_supplierId':formData.tb_supplierId,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          onClose();
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
         
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:",  error.response.data.OPTO[0].OPTO_CODE);
  }
  };
  
  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick={false} onClose={onClose}>
        <div className="editPopup" >
           {/* Cross button to close the popup */}
           <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
        <div className="row mt-1">
                <div className="col-md-12 mt-1">
                  <label htmlFor="tb_supplierName" className="">
                    Supplier Name
                  </label>
                  <input
                    type="text"
                    id="tb_supplierName"
                    name="tb_supplierName"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter supplier name'
                    value={formData.tb_supplierName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_shortName" className="">
                    Short Name
                  </label>
                  <input
                    type="text"
                    id="tb_shortName"
                    name="tb_shortName"
                    className="inputBox form-control formControl  text-dark"
                    placeholder='Enter short name'
                    value={formData.tb_shortName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 pe-2">
                <label className="form-check-label" htmlFor="tb_supplierType">
                  Supplier Type
                </label>
                <div className="dropdownGroup">
                  <select
                    className="form-select inputBox form-control formControl text-dark"
                    name="tb_supplierType"
                    id="tb_supplierType"
                    value={formData.tb_supplierType || ''} // Ensure it is never undefined
                    onChange={handleChange} // Handle the change when the user selects an option
                  >
                    <option>Local</option> {/* Option for Male */}
                    <option>Foreign</option> {/* Option for Female */}
                  </select>
                </div>
              </div>
                <div className="col-md-6">
                  <label htmlFor="tb_tin" className="">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    className="inputBox form-control formControl  text-dark"
                    value={formData.tb_tin}
                    placeholder='Enter tin'
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_vrn" className="">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    className="inputBox form-control formControl  text-dark"
                    value={formData.tb_vrn}
                    placeholder='Enter vrn'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <br/>
              <div className="row btnGroup">
              <div className="col-md-6 ">
                <button
                  type="reset"
                  className="btnCTA btnReset"
                  onClick={() => window.location.reload()}
                >
                  Reset
                </button>
              </div>
              <div className="col-md-6 ">
                <button type="submit" className="btnCTA btnAddSave" onClick={handleSubmit} onClose={onClose}>
                  Save
                </button>
              </div>
            </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditSuppliersMainDetails;
