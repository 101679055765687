import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_BASE_URL } from "../../../../optoCustom";
import { ToastContainer, toast } from "react-toastify";
import { faTrashCan, faPlus, faLock } from "@fortawesome/free-solid-svg-icons";

function AddProductReceipts() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    db_supplierName: "",
    db_stockType: "",
    db_stockSecondaryType: "",
    db_brand: "",
    tb_discount: "0",
    tb_tax: "0",
    tb_netAmount: "0",
  });
  const [supplierByOptions, setSupplierOptions] = useState([]);

  const [orderDetails, setorderDetails] = useState([
    {
      db_itemName: "",
      tb_metalCode: "",
      db_color: "",
      tb_pricePerPiece: "",
      tb_quantity: "",
      tb_grossAmount: "",
    },
  ]);

  const handleRowInputChange = (index, field, value) => {
    const updatedData = [...orderDetails];
    updatedData[index][field] = value;
    // console.log(value);
    setorderDetails(updatedData);

    if (field === "tb_pricePerPiece" || field === "tb_quantity") {
      const pricePerPiece = updatedData[index]["tb_pricePerPiece"] || 0;
      const quantity = updatedData[index]["tb_quantity"] || 0;
      updatedData[index]["tb_grossAmount"] = (
        pricePerPiece * quantity
      ).toString();
    }

    setorderDetails(updatedData);
  };

  useEffect(() => {
    console.log(formData);
    console.log(orderDetails);
  }, [formData, orderDetails]);

  // useEffect(() => {
  //   let totalGrossAmount = 0;
  //   const discount = parseInt(formData.tb_discount) || 0;
  //   let taxAmount = 0;
  //   let netAmount = 0;

  //   orderDetails.forEach((row) => {
  //     totalGrossAmount += parseInt(row.tb_grossAmount);
  //   });

  //   taxAmount = (totalGrossAmount - discount) * 0.18;
  //   netAmount = totalGrossAmount - discount + taxAmount;

  //   if (isNaN(taxAmount) || isNaN(netAmount)) {
  //     console.log("not a number");
  //     setFormData({
  //       ...formData,
  //       tb_tax: "0",
  //       tb_netAmount: "0",
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       tb_tax: taxAmount.toString(),
  //       tb_netAmount: netAmount.toString(),
  //     });
  //   }
  // }, [
  //   JSON.stringify(
  //     orderDetails.map(({ tb_pricePerPiece, tb_quantity }) => ({
  //       tb_pricePerPiece,
  //       tb_quantity,
  //     }))
  //   ),
  //   formData.tb_discount,
  //   formData.tb_tax,
  // ]);

  useEffect(() => {
    setNetTax();
  }, [formData.tb_discount]);

  const handleAddNewRow = () => {
    setorderDetails([
      ...orderDetails,
      {
        db_itemName: "",
        tb_metalCode: "",
        db_color: "",
        tb_pricePerPiece: "",
        tb_quantity: "",
        tb_grossAmount: "",
      },
    ]);
  };

  const setNetTax = () => {
    let totalGrossAmount = 0;
    const discount = parseInt(formData.tb_discount) || 0;
    let taxAmount = 0;
    let netAmount = 0;

    orderDetails.forEach((row) => {
      totalGrossAmount += parseInt(row.tb_grossAmount);
    });

    taxAmount = (totalGrossAmount - discount) * 0.18;
    netAmount = totalGrossAmount - discount + taxAmount;

    if (isNaN(taxAmount) || isNaN(netAmount)) {
      console.log("not a number");
      setFormData({
        ...formData,
        tb_tax: "0",
        tb_netAmount: "0",
      });
    } else {
      setFormData({
        ...formData,
        tb_tax: taxAmount.toString(),
        tb_netAmount: netAmount.toString(),
      });
    }
  };

  const handleLockAndCalculate = () => {
    if (
      orderDetails.some((orderRow) =>
        Object.values(orderRow).some(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === 0 ||
            value === "0"
        )
      )
    ) {
      console.log("tried");
      toast.error(
        "Some fields in the order summary are empty. Unable to Lock Table"
      );
    } else {
      setNetTax();
    }
  };

  const columns = [
    {
        name: "Stock Name",
        selector: (row, index) => (
          <input
            type="text"
            className="form-control"
            value={row.tb_quantity}
            name="tb_taxAmount[]"
            onChange={(e) =>
              handleRowInputChange(index, "tb_taxAmount", e.target.value)
            }
            style={{ fontSize: "14px", textAlign: "right" }}
          />
        ),
      },
    {
      name: "Gross Amount",
      selector: (row, index) => (
        <input
          type="number"
          className="form-control"
          value={row.tb_metalCode}
          name="tb_grossAmount[]"
          onChange={(e) =>
            handleRowInputChange(index, "tb_grossAmount", e.target.value)
          }
          style={{ fontSize: "14px" }}
        />
      ),
    },
    {
        name: "Quantity",
        selector: (row, index) => (
          <input
            type="number"
            className="form-control"
            value={row.tb_quantity}
            name="tb_taxAmount[]"
            onChange={(e) =>
              handleRowInputChange(index, "tb_taxAmount", e.target.value)
            }
            style={{ fontSize: "14px", textAlign: "right" }}
          />
        ),
      },
    {
      name: "Discount",
      selector: (row, index) => (
        <input
          type="number"
          className="form-control"
          value={row.db_color}
          name="db_discount[]"
          onChange={(e) =>
            handleRowInputChange(index, "db_discount", e.target.value)
          }
          style={{ fontSize: "14px" }}
        />
      ),
    },
    {
      name: "Net Amount",
      selector: (row, index) => (
        <input
          type="number"
          className="form-control text-right"
          value={row.tb_pricePerPiece}
          name="tb_netAmount[]"
          onChange={(e) =>
            handleRowInputChange(index, "tb_netmount", e.target.value)
          }
          style={{ fontSize: "14px", textAlign: "right" }}
        />
      ),
    },
    {
      name: "",
      cell: (row, index) => (
        <button
          type="button"
          className="btnDELETE"
          style={{ border: "none", background: "none" }}
          onClick={() => handleDeleteRow(index)}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "16px",
              height: "auto",
            }}
          />
        </button>
      ),
      width: "50px",
    },
  ];

  // Delete row handler
  const handleDeleteRow = (index) => {
    if (orderDetails.length === 1) {
      toast.error("Order Cannot be empty");
    } else {
      const updatedData = [...orderDetails];
      updatedData.splice(index, 1);
      setorderDetails(updatedData);
    }
  };

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchSupplierNameOptions = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "COMMON/getSupplierList.php"
  
        ); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.supplier_id, // Adjust based on your API response structure
          label: option.supplier_name, // Adjust based on your API response structure
        }));
        setSupplierOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSupplierNameOptions();
  }, []);

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      orderDetails.some((orderRow) =>
        Object.values(orderRow).some(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === 0 ||
            value === "0"
        )
      )
    ) {
      console.log("tried");
      toast.error(
        "Some fields in the order summary are empty. Unable to save order"
      );
    } else {
      try {
        console.log(formData.tb_clientName);
        axios({
          method: "post",
          url: API_BASE_URL + "INVENTORY/addInventoryHandler.php",
          // data: params,
          data: {
            db_supplierName: formData.db_supplierName,
            db_stockType: formData.db_stockType,
            db_stockSecondaryType: formData.db_stockSecondaryType,
            db_brand: formData.db_brand,
            tb_discount: formData.tb_discount,
            tb_tax: formData.tb_tax,
            tb_netAmount: formData.tb_netAmount,
            order_summary: orderDetails,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setFormData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
    }
  };

  const handleReset = () => {
    setFormData({
      db_supplierName: "",
      db_stockType: "",
      db_stockSecondaryType: "",
      db_brand: "",
      tb_discount: "0",
      tb_tax: "0",
      tb_netAmount: "0",
    });
    setorderDetails([
      {
        db_itemName: "",
        tb_metalCode: "",
        db_color: "",
        tb_pricePerPiece: "",
        tb_quantity: "",
        tb_grossAmount: "",
      },
    ]);
  };
  return (
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
          <div className="col-md-4  formGroup">
              <label htmlFor="tb_nameOnReceipt" className="">
               Name On Receipt
              </label>
              <input
                type="text"
                id="tb_nameOnReceipt"
                name="tb_nameOnReceipt"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4  formGroup">
              <label htmlFor="tb_receiptNumber" className="">
               Receipt Number 
              </label>
              <input
                type="text"
                id="tb_receiptNumber"
                name="tb_receiptNumber"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-md-4">
              <label htmlFor="singleSelect">Client Name</label>
              <Select
                options={supplierByOptions}
                key={"supplier_name"}
                placeholder="Select Client Name"
                name="db_supplierName"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-4">
              <label htmlFor="singleSelect">Currancy Name</label>
              <Select
                options={supplierByOptions}
                key={"supplier_name"}
                placeholder="Select Currancy Name"
                name="db_supplierName"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="col-md-4  formGroup">
              <label htmlFor="tb_modeOfPayment" className="">
               Mode of Payment
              </label>
              <input
                type="text"
                id="tb_modeOfPayment"
                name="tb_modeOfPayment"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-md-4 formGroup">
              <label htmlFor="tb_paymentDate" className="">
                Payment date
              </label>
              <input
                className="form-control formControl text-center"
                type="date"
                id="tb_paymentDate"
                name="tb_paymentDate"
                max={today}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_transactionID" className="">
               Transaction ID
              </label>
              <input
                type="text"
                id="tb_transactionID"
                name="tb_transactionID"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-md-6">
              <label htmlFor="singleSelect">Our Bank Account</label>
              <Select
                options={supplierByOptions}
                key={"supplier_name"}
                placeholder="Select Bank Account"
                name="db_supplierName"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                onChange={handleSingleSelect}
              />
            </div>
          </div>

          <hr />
          <div className="d-flex flex-row align-items-end justify-content-between">
            <div className="formSubHeading">Order Summary</div>
            <div className="btnRow" style={{ right: "true" }}>
              <button
                type="button"
                className="btnCTA btnAddSave ms-2 px-3"
                onClick={handleLockAndCalculate}
                style={{
                  width: "auto",
                  borderRadius: "10px",
                  height: "30px",
                  fontSize: "14px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
                }}
              >
                <FontAwesomeIcon
                  className="actionIcon me-1"
                  icon={faLock}
                  style={{
                    color: "#ffffff",
                    width: "12px",
                    height: "auto",
                  }}
                />
                Lock and Calculate
              </button>
              <button
                type="button"
                className="btnCTA btnFilter ms-2 px-3"
                onClick={handleAddNewRow}
                style={{
                  width: "auto",
                  borderRadius: "10px",
                  height: "30px",
                  fontSize: "14px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
                }}
              >
                <FontAwesomeIcon
                  className="actionIcon me-1"
                  icon={faPlus}
                  style={{
                    color: "#ffffff",
                    width: "12px",
                    height: "auto",
                  }}
                />
                Add Row
              </button>
            </div>
          </div>
          <div className="mb-3">
            <DataTable
              className="resultData"
              columns={columns}
              data={orderDetails}
              pagination
              responsive
              id="myTable"
              customStyles={tableStyles}
              paginationPerPage={30}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
            />
          </div>
          <div className="row">
            <div className="col-md-3  formGroup">
              <label htmlFor="tb_discount" className="">
                Total Discount
              </label>
              <input
                type="text"
                id="tb_discount"
                name="tb_discount"
                value={formData.tb_discount || 0}
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-3  formGroup">
              <label htmlFor="tb_tax" className="">
                Total Tax 
              </label>
              <input
                type="text"
                id="tb_tax"
                name="tb_tax"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_tax}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-3  formGroup">
              <label htmlFor="tb_netAmount" className="">
                Total Net Amount
              </label>
              <input
                type="text"
                id="tb_netAmount"
                name="tb_netAmount"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_netAmount}
                // onChange={handleChange}
                readOnly
              />
            </div>
            <div className="col-md-3  formGroup">
              <label htmlFor="tb_grossAmount" className="">
                Total Gross Amount
              </label>
              <input
                type="text"
                id="tb_grossAmount"
                name="tb_grossAmount"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                value={formData.tb_grossAmount}
                // onChange={handleChange}
                readOnly
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <Link to={"/home-page/searchreceipts"}>
                <button type="button" className="btnCTA btnCancel">
                  Cancel
                </button>
              </Link>
            </div>
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA  btnAddSave">
                Save Receipt
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddProductReceipts;
