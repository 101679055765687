import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../../optoCustom.js";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

const SearchStaff = () => {
  const [formData, setFormData] = useState([]);
  const [staffData, setStaffData] = useState([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    try {
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/STAFF/viewStaffListHandler.php",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setStaffData(response.data.OPTO[0].OPTO_MESSAGE.staff_details);
            console.log(response.data.OPTO[0].OPTO_MESSAGE.staff_details);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleReset = () => {
    setFormData({
      tb_staffName: "",
      db_departmentName: "",
      db_designationName: "",
    });
    setStaffData([]);
  };

  const handleExport = () => {
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(staffData);

    // Create a workbook containing the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "StaffSearchData");

    // Generate an XLSX file and download it
    XLSX.writeFile(wb, "staff_search_data.xlsx");
  };

  const conditionalRowStyles = [
    {
      when: row => row.active_status === "I",
      style: {
        backgroundColor: "#AC2323",
        color: "white", // Optional: Ensure text is readable
      },
    },
  ];

  const columns = [
    {
      name: "Staff Name",
      selector: (row) => row.staff_name,
      sortable: true,
      width: "30%"
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
      width: "30%"
    },
    {
      name: "Designation",
      selector: (row) => row.designation_name,
      sortable: true,
      width: "30%"
    },

    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/home-page/viewstaff/${row.staff_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "70px"
    },
  ];

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <CSVLink data={staffData} filename="staff_data.csv">
        <button
          type="button"
          className="btnCTA btnFilter me-2 px-3"
          style={{
            width: "auto",
            borderRadius: "5px",
            height: "30px",
            fontSize: "14px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
            position: "relative",
            overflow: "hidden", // Make the button relative for absolute positioning of the triangle
          }}
        >

          CSV

        </button>
      </CSVLink>
      <button
        type="button"
        className="btnCTA btnAddSave me-2 px-3"
        onClick={handleExport}
        style={{
          width: "auto",
          borderRadius: "5px",
          height: "30px",
          fontSize: "14px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)", // Soft shadow
        }}
      >
        {/* <FontAwesomeIcon
          className="actionIcon me-1"
          icon={faPlus}
          style={{
            color: "#ffffff",
            width: "12px",
            height: "auto",
          }}
        /> */}
        Excel
      </button>
      <ToastContainer />
      <DataTable
        className="resultData"
        columns={columns}
        data={staffData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
        conditionalRowStyles={conditionalRowStyles}
      ></DataTable>
      <div className="inputContent"></div>
      <div className="row btnGroup">
        <div className="col-md-4"></div>
        <div className="col-4">
          <Link to={"/home-page/addstaff"}>
            <button type="button" className="btnCTA btnAddSave">
              Add Staff
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchStaff;
