import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../../optoCustom.js";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const SearchReceipts = () => {
  const [formData, setFormData] = useState({
    tb_clientName: "",
    tb_receiptNumber: "",
    tb_referenceType: "",
    tb_currency: "",
    tb_modeOfPayment: "",
    tb_fromDate:dayjs().format("YYYY-MM-DD"),
    tb_toDate: dayjs().format("YYYY-MM-DD"),
    tb_transactionId: "",
    tb_bankAccount: "",
  });
  const [ourBankAccountsList, setOurBankAccountsList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [modeOfPaymentList, setmodeOfPaymentList] = useState([]);


  useEffect(() => {

    // Fetch "Created by" options from API
    const fetchOurBankAccountOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: API_BASE_URL + 'COMMON/getOurBankAccountList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.account_id,
          label: option.account_number,
        }));
        setOurBankAccountsList(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchOurBankAccountOptions();
  }, []);


  useEffect(() => {

    // Fetch "Created by" options from API
    const fetchmodeOfPaymentOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: API_BASE_URL + 'COMMON/getModeOfPaymentList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.mode_of_payment_id,
          label: option.mode_of_payment_name,// Adjust based on your API response structure
        }));
        setmodeOfPaymentList(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchmodeOfPaymentOptions();
  }, []);

  useEffect(() => {

    // Fetch "Created by" options from API
    const fetchCurrencyOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: API_BASE_URL + 'COMMON/getCurrencyList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.currency_id,
        label: option.currency_name,
        }));
        setCurrencyList(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCurrencyOptions();
  }, []);

  const [receiptData, setreceiptData] = useState([]);
  useEffect(() => {
    console.log(formData)
  }, [formData])

  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
      // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
      setFormData({
        ...formData,
        [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
      });
    } else {
      // Handle regular inputs (like text, number, etc.)
      setFormData({
        ...formData,
        [e.target.name]: e.target.value, // Update with the regular input value
      });
    }
  };
  
  //handles value change of single select and adds them to formData
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  const options_xxxx = [
    {
      value: "D",
      label: "Disgnosis",
    },
    {
      value: "S",
      label: "Stock",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "FINANCE/RECEIPTS/filterReceiptHandler.php",
        data: {
          tb_clientName: formData.tb_clientName,
          tb_receiptNumber: formData.tb_receiptNumber,
          tb_referenceType: formData.tb_referenceType,
          tb_currency: formData.tb_currency,
          tb_modeOfPayment: formData.tb_modeOfPayment,
          tb_fromDate: formData.tb_fromDate,
          tb_toDate: formData.tb_toDate,
          tb_transactionId: formData.tb_transactionId,
          tb_bankAccount: formData.tb_bankAccount,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setreceiptData(response.data.OPTO[0].OPTO_MESSAGE);
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.OPTO) {
            // Extract the "OPTO" array
            const opto = error.response.data.OPTO;
            toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE, { position: "top-center" })
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleReset = () => {
    setFormData({
      tb_clientName: "",
      tb_receiptNumber: "",
      tb_referenceType: "",
      tb_currency: "",
      tb_modeOfPayment: "",
      tb_toDate: "",
      tb_fromDate: "",
      tb_transactionId: "",
    });
    setreceiptData([]);
  };

  const columns = [
    {
      name: "Receipt Number",
      selector: (row) => row.receipt_number,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row.client_name,
    },
    {
      name: "Total Amount",
      selector: (row) => row.total_net_amount,
    },
    {
      name: "Payment Date",
      selector: (row) => row.payment_date,
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/home-page/viewreceipts/${row.receipt_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
      width: "70px",
    },
  ];

  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
  };

  return (
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3 formGroup">
              <label htmlFor="tb_receiptNumber" className="">
                Receipt Number
              </label>
              <input
                type="text"
                id="tb_receiptNumber"
                name="tb_receiptNumber"
                className="inputBox form-control formControl text-dark"
                maxLength="12"
                value={formData.tb_receiptNumber}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-3">
              <label htmlFor="singleSelect">Reference Type</label>
              <Select
                options={options_xxxx}
                key={"client_id"}
                placeholder="Reference Type"
                name="tb_referenceType"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                isClearable
                onChange={handleSingleSelect}
              />
            </div>
            <div className="col-md-6 formGroup">
              <label htmlFor="tb_clientName" className="">
                Client Name
              </label>
              <input
                type="text"
                id="tb_clientName"
                name="tb_clientName"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_clientName}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-md-6">
              <label htmlFor="db_currencyName">Currency</label>
              <Select
                options={currencyList}
                defaultValue={{ value: "1|TZS", label: "Tanzanian Shilling" }}
                key={"currency_id"}
                placeholder="Select Currency"
                name="db_currencyName"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-6">
              <label htmlFor="db_modeOfPayment">Mode Of Payment</label>
              <Select
                options={modeOfPaymentList}
                key={"supplier_name"}
                placeholder="Select Mode of Payment"
                name="db_modeOfPayment"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            <div className="col-6 formGroup">
              <label htmlFor="tb_transactionId" className="">
                Transaction Id
              </label>
              <input
                type="text"
                id="tb_transactionId"
                name="tb_transactionId"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_transactionId}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-md-6">
              <label htmlFor="tb_ourBankAccount">Our Bank Account</label>
              <Select
                options={ourBankAccountsList}
                key={"account_id"}
                placeholder="Select Bank Account"
                name="tb_ourBankAccount"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #d2d2d2",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                onChange={handleSingleSelect}
              />
            </div>
            
              <div className="row col-12 justify-content-center align-content-center">
                <label className="col-12 text-center">Receipt Payment Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="col-4 formGroup text-center">
                  <DatePicker
                    value={dayjs(formData.tb_fromDate)}
                    className=""
                    id="tb_fromDate"
                    maxDate={dayjs()}
                    onChange={(newValue) =>
                      handleChange({ target: { name: "tb_fromDate" } }, newValue)
                    } // Pass value to handleChange
                    sx={{
                      width: "100%",
                      "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                      "& .MuiOutlinedInput-root": {
                        "&:hover > fieldset": {
                          border: "1px solid #b0b0b0 !important",
                        },
                        height: "35px",
                        border: "1px solid #b0b0b0 !important",
                        borderRadius: "0px",
                      },
                    }}
                    format="DD/MMM/YYYY"
                    renderInput={(params) => <input name="tb_fromDate" {...params} />}
                  />
                </div>
                </LocalizationProvider>
                And
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="col-4 formGroup text-center">
                  <DatePicker
                    value={dayjs(formData.tb_toDate)}
                    className=""
                    id="tb_toDate"
                    maxDate={dayjs()}
                    onChange={(newValue) =>
                      handleChange({ target: { name: "tb_toDate" } }, newValue)
                    } // Pass value to handleChange
                    sx={{
                      width: "100%",
                      "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                      "& .MuiOutlinedInput-root": {
                        "&:hover > fieldset": {
                          border: "1px solid #b0b0b0 !important",
                        },
                        height: "35px",
                        border: "1px solid #b0b0b0 !important",
                        borderRadius: "0px",
                      },
                    }}
                    format="DD/MMM/YYYY"
                    renderInput={(params) => <input name="tb_toDate" {...params} />}
                  />
                </div>
                </LocalizationProvider>
              </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-3">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-3">
              <button type="submit" className="btnCTA  btnFilter">
                Filter
              </button>
            </div>
            <div className="col-md-3">
              <Link to={"/home-page/adddiagnosisreceipts"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add Diagnosis Receipts
                </button>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to={"/home-page/addproductreceipts"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add Product Receipts
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      {/* DataTable to display client data */}
      <DataTable
        className="resultData"
        columns={columns}
        data={receiptData} // Data received from API
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      />
    </div>
  );
};

export default SearchReceipts;
