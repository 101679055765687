import React, { useState, useEffect } from "react";
import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const EditStaffMainDetails = ({ open ,editStaffMainData , onClose }) => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_staffId: "",
    tb_staffName: "",
    tb_staffType: "",
    tb_contactNumber: "",
    tb_tin: "",
    tb_vrn: "",
    tb_dob: "",
    tb_joiningDate: "",
    tb_lastDate:"",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_departmentId:"",
    tb_departmentName: "",
    tb_designationId:"",
    tb_designationName: "",
    tb_digitalSignature: "",
  });

  useEffect(() => {
    console.log('Form With FormData : ', formData);
    if (editStaffMainData) {
      setFormData({
        tb_staffId: editStaffMainData.staff_id,
        tb_departmentId : editStaffMainData.department_id,
        tb_designationId : editStaffMainData.designation_id,
        tb_staffType: editStaffMainData.staff_type,
        tb_staffName: editStaffMainData.staff_name,
        tb_contactNumber: editStaffMainData.contact_number,
        tb_emailAddress: editStaffMainData.email_address,
        tb_physicalAddress: editStaffMainData.physical_address,
        tb_departmentName: editStaffMainData.department_name,
        tb_dob: editStaffMainData.dob,
        tb_tin: editStaffMainData.tin,
        tb_vrn: editStaffMainData.vrn,
        tb_designationName: editStaffMainData.designation_name,
        tb_joiningDate: editStaffMainData.joining_date,
        tb_lastDate: editStaffMainData.last_date,
        tb_digitalSignature: editStaffMainData.digital_signature,
      });
    }
  }, [editStaffMainData]);
  // Handle input change
  const handleChange = (e, value) => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    if (value) {
    // Handle DatePicker (since `onChange` for DatePicker provides `value` as a second argument)
    setFormData({
    ...formData,
    [e.target.name]: formattedDate, // For DatePicker, update with the value passed from DatePicker
    });
    } else {
    // Handle regular inputs (like text, number, etc.)
    setFormData({
    ...formData,
    [e.target.name]: e.target.value, // Update with the regular input value
    });
    }
    };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/STAFF/editStaffMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_staffId': formData.tb_staffId,
          'tb_designationId' : formData.tb_designationId,
          'tb_departmentId': formData.tb_departmentId,
          'tb_staffName': formData.tb_staffName,
          'tb_contactNumber': formData.tb_contactNumber,
          'tb_staffType': formData.tb_staffType,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_joiningDate': formData.tb_joiningDate,
          'tb_lastDate': formData.tb_lastDate,
          'tb_departmentName': formData.tb_departmentName,
          'tb_designationName': formData.tb_designationName,
          'tb_dob': formData.tb_dob,
          'tb_tin': formData.tb_tin,
          'tb_vrn': formData.tb_vrn,
          'tb_digitalSignature': formData.tb_digitalSignature,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          // Extract the OPTO_MESSAGE array and set it to the state
          setFormData(response.data.OPTO[0].OPTO_MESSAGE);
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          onClose();
          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.OPTO) {
          // Extract the "OPTO" array
          const opto = error.response.data.OPTO;
          toast.error(opto[0].OPTO_CODE + ":" + opto[0].OPTO_MESSAGE ,  { position: "top-center" })
        }
      });
  } catch (error) {
    console.error("Error:", error);
  }
  };

  return (
    <>
    <ToastContainer/>
       <div>
      <Popup open={open} closeOnDocumentClick={false} onClose={onClose}>
        <div className="editPopup" style={{height: "600px"}}>
           {/* Cross button to close the popup */}
           <button 
              className="closePopup" 
              onClick={onClose} // Close popup without reloading
              style={{ position: "absolute", right: "5px", top: "1px", border: "none", background: "transparent", fontSize: "30px" }}
            >
              &times;
            </button>
          <br/>
        <div className="row ">
        <div className="col-md-12  formGroup">
            <label htmlFor="tb_staffName" className="">
              Staff Name
            </label>
            <input
              type="text"
              id="tb_staffName"
              name="tb_staffName"
              className="inputBox form-control formControl text-dark"
              placeholder="Enter staff name"
              value={formData.tb_staffName}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 pe-2">
                <label className="form-check-label" htmlFor="tb_staffType">
                  Staff Type
                </label>
                <div className="dropdownGroup">
                  <select
                    className="form-select inputBox form-control formControl text-dark"
                    name="tb_staffType"
                    id="tb_staffType"
                    value={formData.tb_staffType || ''} // Ensure it is never undefined
                    onChange={handleChange} // Handle the change when the user selects an option
                  >
                    <option value="N">Staff</option> {/* Option for Male */}
                    <option value="A">Admin</option> {/* Option for Female */}
                  </select>
                </div>
              </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_contactNumber" className="">
             Contact Number
            </label>
            <input
              type="number"
              id="tb_contactNumber"
              name="tb_contactNumber"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter contact number"
              value={formData.tb_contactNumber}
              onChange={handleChange}
            />
          </div>
          <div className="col-8  formGroup">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              placeholder="Enter email address"
              value={formData.tb_emailAddress}
              onChange={handleChange}
            />
          </div>
          {/* Datetimepicker */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="col-4 formGroup">
              <label htmlFor="tb_dob" className="">
                Date of Birth
              </label>
              <DatePicker
                value={dayjs(formData.tb_dob)}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_dob" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_dob" {...params} />}
              />
            </div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="col-6 formGroup">
              <label htmlFor="tb_joiningDate" className="">
                Joining Date
              </label>
              <DatePicker
                value={dayjs(formData.tb_joiningDate)}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_joiningDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_joiningDate" {...params} />}
              />
            </div>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="col-6 formGroup">
              <label htmlFor="tb_lastDate" className="">
                Last Date
              </label>
              <DatePicker
                value={dayjs(formData.tb_lastDate)}
                className=""
                id="tb_dob"
                maxDate={dayjs()}
                onChange={(newValue) =>
                  handleChange({ target: { name: "tb_lastDate" } }, newValue)
                } // Pass value to handleChange
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    "&:hover > fieldset": {
                      border: "1px solid #b0b0b0 !important",
                    },
                    height: "35px",
                    border: "1px solid #b0b0b0 !important",
                    borderRadius: "0px",
                  },
                }}
                format="DD/MMM/YYYY"
                renderInput={(params) => <input name="tb_lastDate" {...params} />}
              />
            </div>
            </LocalizationProvider>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_departmentName" className="">
              Department
            </label>
            <input
              type="text"
              id="tb_departmentName"
              name="tb_departmentName"
              className="inputBox form-control formControl text-dark"
              value={formData.tb_departmentName}
              placeholder="Enter department"
              onChange={handleChange}
            />
          </div>
        <div className="col-md-6  formGroup">
            <label htmlFor="tb_designation" className="">
              Designation
            </label>
            <input
              type="text"
              id="tb_designationName"
              name="tb_designationName"
              className="inputBox form-control formControl text-dark"
              placeholder="Enter designation"
              value={formData.tb_designationName}
              onChange={handleChange}
            />
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              placeholder="Enter physical address"
              value={formData.tb_physicalAddress}
              cols="100"
              rows={5}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
                  <label htmlFor="tb_tin" className="">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    className="inputBox form-control formControl  text-dark"
                    value={formData.tb_tin}
                    placeholder="Enter tin"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-6">
                  <label htmlFor="tb_vrn" className="">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    className="inputBox form-control formControl  text-dark"
                    value={formData.tb_vrn}
                    placeholder="Enter vrn"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-12">
                  <label htmlFor="tb_signature" className="">
                    Digital Signature
                  </label>
                  <input
                    type="text"
                    id="tb_digitalSignature"
                    name="tb_digitalSignature"
                    className="inputBox form-control formControl  text-dark"
                    value={formData.tb_digitalSignature}
                    placeholder="Enter digital signture"
                    onChange={handleChange}
                  />
            </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA btnAddSave" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
        </div>
        </Popup>
        </div>
    </>
  );
};

export default EditStaffMainDetails;
